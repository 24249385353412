header.main {
    .message {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding: 26px 36px 32px;
        @include desktop() {
            padding: 24px 35px 23px;
        }

        &:not(:first-of-type) {
            border-top: 1px solid $light-gray;
        }

        &:last-of-type {
            @include desktop(max) {
                border-bottom: 1px solid $light-gray;
            }
        }

        .new-notification {
            top: 34px;
            left: 18px;
            @include desktop() {
                top: 32px;
                left: 15px;
            }
        }

        & > p {
            margin-bottom: 20px;
            @include desktop() {
                margin-bottom: 21px;
            }
        }

        &-inbox {
            position: absolute;
            background-color: $cream;
            width: 100%;
            display: none;
            flex-direction: column;
            @include desktop(max) {
                top: 0;
                height: 100vh;
                padding-top: 23px;
            }
            @include desktop() {
                width: 463px;
                right: 33px;
                min-height: 178px;

                &::before, &::after {
                    content: '';
                    position: absolute;
                    width: 3px;
                    height: 20px;
                    top: -8px;
                }

                &::before {
                    border-left: 4px solid $cream;
                    left: 55.7%;
                    transform: rotate(150deg);
                }

                &::after {
                    border-right: 4px solid $cream;
                    right: calc(44% + 2px);
                    transform: rotate(20deg);
                }
            }

            & > button:first-of-type {
                margin-left: 17px;
                margin-bottom: 2px;
                @include desktop() {
                    display: none;
                }

                & > img {
                    height: 13px;
                }
            }

            p {
                font-family: "MaisonNeue-Book", sans-serif;
                color: $gray;
                font-size: 14px;
                line-height: 22px;
                @include desktop() {
                    font-size: 16px;
                    line-height: 25px;
                }
            }

            & > p {
                text-align: center;
                margin-top: 28px;
            }

            &.active {
                display: flex;
            }

            &_head {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid $light-gray;
                padding: 15px 18px;
                @include desktop() {
                    padding: 24px 35px 23px 26px;
                }

                button {
                    font-family: "MaisonNeue-Book", sans-serif;
                    color: $gray;
                    font-size: 14px;
                    line-height: 22px;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 25px;
                    }

                    &:disabled {
                        border: none !important;
                        background-color: transparent !important;
                        color: $dark-cream !important;
                    }
                }
            }

            &_body {
                height: 100vh;
                overflow: auto;
                @include desktop(){
                    height: 346px;
                    padding-top: 9px;
                }
                &::-webkit-scrollbar {
                    height: 0;
                    width: 5px;

                    &-track {
                        background-color: $main-bg;
                        border-radius: 100px;
                    }

                    &-thumb {
                        background-color: $dark-gray;
                        border-radius: 100px;
                    }
                }
            }
        }
    }
}