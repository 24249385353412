html {
    scroll-behavior: smooth;
}

body {
    font-family: $font-family-primary;
    font-weight: 400;
    @extend .body-scroll;
    
    &:not(.dis-scroll) {
        overflow: hidden visible !important;
    }
    
    #webWidget {
        z-index: 9999999999!important;
    }
    
    .acsb-trigger,
    .acsb-trigger.acsb-position-x-right.acsb-mobile {
        @include desktop(max) {
            inset: unset !important;
            bottom: 72px !important;
            right: 19px !important;
            margin: auto 0 0 !important;
        }
    }

    &.dis-scroll {
        overflow: hidden;

        @include desktop(max) {
            height: calc(var(--vh) - 1px);
            position: relative;
            touch-action: none;
            -ms-touch-action: none;
        }

        @include desktop() {
            padding-right: 9px;
        }

        header {
            @include desktop() {
                padding-right: 42px !important;
            }
        }

        .popup-content-wrapper {
            @include desktop() {
                padding-right: 9px !important;
            }

            &.fund-wrapper,
            &.contribution,
            &.cashfund,
            &.billing-form {
                @include desktop() {
                    padding-right: 34px !important;
                }

                &.account-info {
                    @include desktop() {
                        padding-right: 9px !important;
                    }
                }
            }
        }

        .account-popup-wrapper {
            @include desktop() {
                padding-right: 129px !important;
            }
        }

        .live-chat-room--container {
            display: none;
        }
    }
}

input, textarea {
    font-family: $font-family-primary;

    @include placeholder {
        font-family: $font-family-primary;
    }
}

.capitalize {
    text-transform: capitalize;
}

.app {
    padding-top: 59px;
    @include desktop() {
        padding-top: 72px;
        min-height: 100vh;
    }
    @include lg() {
        padding-top: 83px;
    }

    &.create-fund {
        @include desktop() {
            min-height: unset;
        }

        .loader-wrapper {
            position: unset !important;
            height: 100vh !important;
            transform: unset !important;
        }
    }

    &.findregistry,
    &.onboarding,
    &.regular,
    &[class^='onboarding'],
    &[class*='onboarding'],
    &.signup {
        padding-top: 0;
    }
}

.container {
    max-width: 1000px;
    margin: auto;

    @include desktop() {
        max-width: 1203px;
    }
}

.v-hidden {
    visibility: hidden;
}

.d-none {
    display: none !important;
}

.show-mob {
    @include desktop() {
        display: none;
    }
}

.show-desk {
    display: none;
    @include desktop() {
        display: block;
    }
}

.processing {
    cursor: auto !important;

    span {
        color: $white !important;
        margin-left: 2px;
        margin-bottom: 0 !important;
        -webkit-animation-name: blink;
        animation-name: blink;
        -webkit-animation-duration: 1.4s;
        animation-duration: 1.4s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;

        &:nth-child(2) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }

        &:last-child {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }
    }
}

.error-msg {
    background-color: $orange-grove;
    width: 100%;
    padding: 5px;
    margin: auto auto 1px;
    font-family: 'MaisonNeue-Book', sans-serif;
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: $dark-gray;

    &:last-of-type {
        margin: auto auto 34px;
    }
}

.hide-scroll {
    &::-webkit-scrollbar {
        height: 0;
        width: 0;

        &-track {
            background-color: transparent;
        }

        &-thumb {
            background-color: transparent;
        }
    }
}

.body-scroll {
    &::-webkit-scrollbar {
        height: 0;
        width: 9px;

        &-track {
            background-color: $main-bg;
            border-radius: 100px;
        }

        &-thumb {
            background-color: $dark-gray;
            border-radius: 100px;
        }
    }
}

.active__state {
    border-bottom: 1px solid $dark-gray !important;
    color: $dark-gray !important;
    padding-bottom: 4px;
}

.loading-text {
    p {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'MaisonNeue-Extended', sans-serif !important;
        font-size: 14px !important;
        line-height: normal !important;
        letter-spacing: 1.4px !important;
        color: $honey !important;
        text-transform: uppercase !important;
    }

    span {
        margin-left: 2px;
        margin-bottom: 0 !important;
        font-size: 16px;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;

        &:nth-child(2) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }

        &:last-child {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }
    }
}

@-webkit-keyframes blink {
    0% {
        opacity: 0.2;
    }

    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }

    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

.grecaptcha-badge {
    z-index: 1
}

.new-notification {
    display: inline-block;
    border-radius: 50%;
    background-color: $orange-grove;
    width: 7px;
    height: 7px;
    position: absolute;
    @include desktop() {
        width: 8px;
        height: 8px;
    }
}

.desk-max {
    @include desktop(){
        max-width: 478px;
    }
}

.new-label {
    position: relative;

    &::after {
        content: 'NEW!';
        font-family: 'MaisonNeue-Extended-Book', sans-serif;
        font-size: 8px;
        letter-spacing: 0.8px;
        color: $dark-gray;
        background-color: rgba(250, 255, 0, 0.65);
        border-radius: 16px;
        position: absolute;
        padding: 1px 4px;
        top: -9px;
        right: -32px;

        @include desktop() {
            right: -28px;
            top: -10px;
        }
    }
}

.handwritten-text {
    font-family: $font-handwriting;
    font-weight: normal;
}