.tooltip-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }

    &.before-bank-linked-tooltip {
        right: 0 !important;

        &.view {
            .tooltip {
                &::before {
                    right: 280px !important;
                }
            }
        }

        &.share {
            &-fail {
                @include desktop(max) {
                    right: unset !important;
                    left: 0 !important;
                }

                .tooltip {
                    @include desktop() {
                        width: 234px !important;
                        right: 86px !important;
                    }
                    @include desktop(max) {
                        width: 261px !important;
                        right: unset !important;
                        left: 35px;
                        &::before {
                            right: 219px !important;
                        }
                        p {
                            text-align: center !important;
                        }
                    }
                }
            }

            .tooltip {
                &::before {
                    right: 95px !important;
                    @include mobile() {
                        right: 250px !important;
                    }
                    @include phone() {
                        right: 310px !important;
                    }

                    @include tablet() {
                        right: 190px !important;
                    }
                    @include desktop() {
                        right: 65px !important;
                    }
                }
            }
        }

        .tooltip {
            //background-color: $honey !important;
            width: 325px !important;
            //right: 0 !important;
            top: 90px !important;
            padding: 13px !important;
            @include tablet() {
                right: 350px !important;
            }
            @include desktop() {
                width: 330px !important;
                top: 47px !important;
                right: 254px !important;
                padding: 15px !important;
            }

            &::before {
                //border-right: 8px solid $honey;
            }

            p {
                //color: $dark-gray !important;
                //font-size: 14px !important;
                //line-height: 1.57 !important;
                //@include desktop() {
                //    font-size: 16px;
                //    line-height: 1.56;
                //}
            }
        }
    }

    .tooltip {
        position: absolute !important;
        top: 30px;
        right: -17px;
        background: $dark-gray !important;
        padding: 8px 8px 9px 9px !important;
        width: 234px;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s linear;
        @include desktop() {
            padding: 16px 16px 16px 27px !important;
            top: -3px;
            right: -246px;
        }


        &::before {
            content: '';
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 8px solid $dark-gray;
            position: absolute;
            transform: rotate(90deg);
            left: 203px;
            top: -9px;
            @include desktop() {
                transform: unset;
                left: -7px;
                top: 4px;
            }
        }

        &-left {
            width: 337px;
            top: 29px;
            right: -32px;
            @include desktop(max) {
                width: 325px;
                top: 27px;
                right: -14px;
            }

            &::before {
                transform: rotate(90deg);
                right: 36px;
                left: unset;
                top: -11px;
                @include desktop(max) {
                    top: -9px;
                    right: 18px;
                }
            }
        }

        p {
            font-family: 'MaisonNeue-Book', sans-serif !important;
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 25px !important;
            letter-spacing: normal !important;
            text-align: left !important;
            color: $white !important;
            @include desktop(max) {
                font-size: 14px !important;
                margin-right: 0 !important;
                line-height: 23px !important;
            }
        }
    }
}