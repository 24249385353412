$headingFont: 'OrpheusPro';
$bodyFont: 'MaisonNeue-Book';

.h1,
.h2 {
    font-family: $headingFont;
    color: $dark-gray;
}

.h1 {
    font-size: 54px;
    line-height: 61px;
}

.h2 {
    font-size: 30px;
    line-height: 38px;
}

.wrapper {
    .container {
        max-width: 1230px;
    }
}

.hero {
    @include desktop() {
        height: 100vh;
    }

    &__content {
        padding: 33px 25px 56px;
        text-align: center;

        @include desktop() {
            padding: 36px 25px 56px;
            max-width: 546px;
            text-align: left;
        }

        &-wrap {
            @include desktop() {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
            }
        }
    }

    &__subtitle {
        font-family: $bodyFont;

        @media screen and (max-width: 1023.98px) {
            font-size: 14px;
            line-height: 22px;
        }
    }

    &__title {
        @extend .h1;
        margin-top: 15px;

        @media screen and (max-width: 1023.98px) {
            font-size: 36px;
            line-height: 42px;
            margin-top: 4px;
        }
    }

    &__link {
        margin-top: 44px;
        width: 100%;
        @media screen and (max-width: 1023.98px) {
            margin-left: auto;
            margin-right: auto;
            margin-top: 32px;
        }
        @include desktop() {
            width: 340px;
        }
    }

    &__bg {
        width: 100%;
        height: 100%;
        object-fit: cover;

        //picture,
        //img {
        //    width: 100%;
        //    height: 100%;
        //    -o-object-fit: cover;
        //    object-fit: cover;
        //}

        & > span {
            width: 100%!important;
            height: 100% !important;
            display: block!important;
            & > span {
                img {
                    width: 100%!important;
                    height: 100%!important;
                    object-fit: cover!important;
                }
            }

            & > img {
                object-fit: cover;
            }

        }
    }
}

.section-services {
    padding: 96px 0;

    @media screen and(max-width: 1023.98px) {
        padding: 54px 0 58px;
    }

    .container {
        padding: 0 25px;

        @include desktop {
            max-width: 1280px;
        }
    }
}

.service {
    $service: #{&};

    &:not(:last-child) {
        margin-bottom: 100px;

        @include desktop {
            margin-bottom: 96px;
        }
    }

    &--reverse {

        #{$service}__column:nth-child(1) {
            order: 2;
        }

        #{$service}__column:nth-child(2) {
            order: 1;
        }

        #{$service}__content {
            margin-left: auto;

            @media screen and(max-width: 1023.98px) {
                padding-top: 18px;
            }
        }

        #{$service}__desc {
            @media screen and(max-width: 1023.98px) {
                margin-top: 14px;
            }
        }
    }

    &__content {
        @media screen and(max-width: 1023.98px) {
            padding-top: 14px;
        }

        @include desktop() {
            max-width: 455px;
        }
    }

    &__grid {
        display: grid;

        @include desktop() {
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            grid-gap: 30px;
        }

        @include rwd(1200) {
            grid-template-columns: minmax(auto, 641px) 1fr;
            align-items: center;
            grid-gap: 0;
        }
    }

    &__column {
        &:last-child {
            grid-row: 1;

            @include desktop() {
                grid-row: inherit;
            }
        }
    }

    &__title {
        @extend .h2;

        @media screen and(max-width: 1023.98px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    &__desc {
        margin-top: 14px;
        font-family: $bodyFont;
        font-size: 14px;
        line-height: 22px;
        color: $dark-gray;

        @include desktop() {
            margin-top: 22px;
            font-size: 16px;
            line-height: 25px;
        }
    }

    &__link {
        display: inline-block;
        margin-top: 30px;

    }

    &__gallery {
        display: grid;
        grid-template-columns: 27% 33% auto;

        @include desktop() {
            grid-template-columns: 172px auto auto 1fr;
            grid-template-rows: 178px auto 60px;
        }

        &-img1 {
            grid-column: 1/3;
            grid-row: 1/3;
        }

        &-img2 {
            grid-column: 2/-1;
            grid-row: 2/-1;

            @media screen and (max-width: 1023.98px) {
                margin-top: 34%;
            }
        }
    }
}

.journals {
    position: relative;
    padding: 60px 25px 103px;
    background-color: $cream;

    @include desktop() {
        padding: 83px 20px 96px;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        @media screen and(max-width: 1023.98px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    &__grid {
        display: grid;
        margin-top: 22px;
        grid-gap: 54px;

        @include desktop() {
            margin-top: 29px;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 58px;
        }
    }

    &__link {
        @media screen and(max-width: 1023.98px) {
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translate(-50%);
        }
    }
}

.card {
    &__image {
        position: relative;
        overflow: hidden;

        img {
            transition: all 0.4s ease;
        }
    }

    &__content {
        padding: 10px 0 0;

        @include desktop {
            padding: 14px 0 0;
        }
    }

    &__subtitle {
        font-family: 'MaisonNeue-Extended-Book';
        font-size: 12px;
        line-height: 18px;
        color: $dark-gray;
        letter-spacing: 1.2px;
        text-transform: uppercase;

        @include desktop() {
            font-size: 14px;
            letter-spacing: 1.4px;
        }
    }

    &__desc {

        @include desktop() {
            margin-top: 6px;
        }

        a {
            font-family: $bodyFont;
            font-size: 13px;
            line-height: 25px;
            letter-spacing: 0.5px;
            color: $gray;

            @include desktop {
                font-size: 16px;
                letter-spacing: -0.3px;
            }
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($cream, 0.75);
        font-family: $bodyFont;
        color: $dark-gray;
        letter-spacing: 1.4px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease;
    }

    &:hover {
        .card__image {
            img {
                transform: scale(1.1) rotate(1deg);
            }
        }

        .card__overlay {
            opacity: 1;
            visibility: visible;
        }
    }

    &.card-loader {
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ccc;
    }
}

.support {
    overflow: hidden;
    padding-bottom: 55px;

    @include desktop {
        padding-bottom: 0;
    }

    &__head {
        padding: 0 25px 84px;
        text-align: center;

        @include desktop {
            padding-bottom: 62px;
        }
    }

    &__desc {
        font-family: $bodyFont;
        font-size: 13px;
        line-height: 25px;
        letter-spacing: .5px;
        margin-top: 9px;

        @include desktop {
            font-size: 16px;
            margin-top: 18px;
            letter-spacing: 0;
        }
    }

    &__title {
        @media screen and (max-width: 1023.98px) {
            font-size: 36px;
            line-height: 42px;
        }
    }

    &__link {
        display: inline-flex;

        @media screen and(max-width: 1023.98px) {
            margin: 13px auto 0;
            width: 100%;
            max-width: 260px;
        }

        @include desktop() {
            margin-top: 30px;
        }
    }

    &-carousel {
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        margin-left: -25px;
        margin-right: -25px;

        @include desktop() {
            margin-left: 0;
            margin-right: 0;
        }

        .swiper-container {
            @include desktop() {
                padding: 0 150px;
            }
        }

        .swiper-wrapper {
            -webkit-transition-timing-function: linear !important;
            -o-transition-timing-function: linear !important;
            transition-timing-function: linear !important;
        }

        .swiper-slide {
            width: auto;
        }
    }

    &-item {
        padding: 21px 0 26px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include desktop {
            padding: 38px 0;
        }

        img {
            width: 89px !important;
            height: 89px !important;

            @include tablet {
                width: 135px !important;
                height: 135px !important;
            }
        }

        h2 {
            padding-left: 24px;
            color: $honey;
            @media screen and (max-width: 1023.98px) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }
}

.communities {
    padding: 62px 0 22px;
    background-color: $main-bg;

    @include desktop() {
        padding: 90px 0;
    }

    &__head {
        text-align: center;
        padding-bottom: 65px;

        @media screen and (max-width: 1023.98px) {
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 36px;
        }
    }

    &__title {
        @media screen and(max-width: 1023.98px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    &__carousel {

        .swiper-button-prev,
        .swiper-button-next {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background-color: $white;

            &::after {
                font-size: 14px;
                color: $dark-gray;
            }

            @media screen and(max-width: 1023.98px) {
                display: none;
            }
        }
    }
}

.community {
    padding: 0 25px;

    @include desktop() {
        padding: 0 136px;
    }

    &__grid {
        @include desktop() {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 50px;
            align-items: center;
        }
    }

    &__image {
        padding: 16px 13px 4px;
        background-color: $white;
        border-radius: 10px;
        max-width: 400px;
        margin: auto;

        @include desktop() {
            padding: 16px 13px;
            max-width: 400px;
        }

        img {
            display: block;
        }
    }

    &__caption {
        padding: 20px 12px 12px;
        font-family: $bodyFont;
        text-align: center;

        @include desktop {
            padding: 23px 12px 12px;
        }

        h5 {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            font-family: "MaisonNeue-Extended-Book";

            @include desktop {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 1.4px;
            }
        }

        p {
            font-size: 10px;
            line-height: 12px;
            margin-top: 4px;

            @include desktop {
                font-size: 12px;
                line-height: 19px;
                margin-top: 8px;

            }
        }
    }

    &__body {
        font-family: $bodyFont;
        border-radius: 10px;
    }

    &__row {
        @include desktop() {
            display: grid;
            grid-template-columns: 110px 1fr;
        }

        @media screen and(max-width: 1023.98px) {
            padding: 36px 0 30px;
            text-align: center;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $light-gray;

            @include desktop() {
                padding-bottom: 42px;
                margin-bottom: 28px;
            }
        }

        h2 {
            color: $honey;

            @media screen and(max-width: 1023.98px) {
                font: 24px;
                line-height: 30px;
            }
        }

        p {
            margin-top: 18px;
            font-size: 14px;
            line-height: 22px;

            @include desktop {
                margin-top: 4px;
                font-size: 16px;
                line-height: 25px;
            }
        }

        a {
            display: inline-flex;
            margin-top: 44px;

            @include desktop {
                margin-top: 32px;
            }
        }
    }
}

.how-it-work {
    padding: 8px 25px 68px;

    @include desktop() {
        padding: 82px 25px;
    }

    &__head {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;

        h2 {
            @media screen and(max-width: 1023.98px) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    &__grid {
        display: flex;
        overflow: hidden;
        overflow-x: auto;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;

        @include desktop() {
            display: grid;
            grid-gap: 12px;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__item {
        position: relative;
        flex-shrink: 0;
        width: 100%;
    }

    &__figure {
            @media screen and (max-width: 1023.98px) {
                //width: 100%;
                //height: 376px;
                //-o-object-fit: cover;
                //object-fit: cover;

                & > span {
                    width: 100%!important;
                    height: 376px!important;
                    display: block!important;
                    & > span {
                        img {
                            width: 100%!important;
                        }
                    }

                    & > img {
                        object-fit: cover;
                    }
                }
            }
    }

    &__caption {
        margin-top: 20px;
        font-family: $bodyFont;
        display: flex;
        align-items: center;
        padding-left: 62px;
        position: relative;
        min-height: 40px;
        display: flex;
        align-items: center;

        @include desktop {
            margin-top: 14px;
            padding-left: 52px;
        }
    }

    &__number {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        color: $dark-gray;
        border: 1px solid $honey;
        border-radius: 50%;
    }

    &__text {
        font-size: 14px;
        line-height: 1.375;

        @include desktop() {
            font-size: inherit;
        }
    }

    &__scrollbar {
        $self: #{&};
        position: relative;
        margin-top: 40px;
        width: 100%;
        height: 1px;
        background-color: $light-gray;

        &--step {
            &-1 {
                #{$self}-inner {
                    width: 33.333%;
                }
            }

            &-2 {
                #{$self}-inner {
                    width: 66.666%;
                }
            }

            &-3 {
                #{$self}-inner {
                    width: 100%;
                }
            }
        }

        &-inner {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background-color: $dark-gray;
        }
    }
}


.featured_section {
    padding: 68px 0 48px;
    background-color: $main-bg;

    @include desktop() {
        padding: 60px 0 58px;
    }

    h3 {
        font-family: 'MaisonNeue-Extended-Book', sans-serif;
        color: $dark-gray;
        text-align: center;
        font-size: 12px;
        letter-spacing: 1.2px;
        margin-bottom: 40px;

        @include desktop() {
            font-size: 14px;
            letter-spacing: 1.4px;
            margin-bottom: 45px;
        }
    }

    &-logos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1230px;

        @include desktop(max) {
            flex-direction: column;

            & > span:not(:last-child) {
                margin-bottom: 25px !important;
            }
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            padding: 0 25px;
        }

        img {
            mix-blend-mode: multiply;
        }
    }
}