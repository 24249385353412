footer {
    border-top: $border;

    .container {
        display: grid;
        grid-template-columns: 100%;
        padding: 60px 25px 30px;
        @include desktop() {
            grid-template-columns: 50% 50%;
            padding: 85px 25px 45px;
            max-width: 1283px !important;
        }

        @include lg() {
            grid-template-columns: 52% 48%;
        }

        h2 {
            font-family: 'OrpheusPro', sans-serif;
            font-size: 24px;
            line-height: 1.25;
            letter-spacing: normal;
            color: $dark-gray;
            margin-bottom: 30px;
            @include desktop() {
                font-size: 30px;
                line-height: 1.27;
                margin-bottom: 25px;
            }

            span {
                font-style: italic;
            }
        }

        .subscribe {
            max-width: 325px;
            width: 100%;
            order: 2;
            @include lg() {
                max-width: 395px;
                order: 1;
            }

            img {
                height: 15px;
                -o-object-fit: contain;
                object-fit: contain;
                cursor: pointer;
            }

            .btn-link {
                display: none;
            }

            .inp-primary {
                input {
                    &:not([type='radio']):not([type='checkbox']) {
                        border-radius: 0;
                        -webkit-appearance: none;
                    }
                }
            }
        }

        .footer-links {
            display: flex;
            flex-direction: column;
            order: 1;
            @include desktop() {
                flex-direction: row;
                order: 2;
                padding-top: 15px;
            }

            .first-col {
                display: flex;
                justify-content: space-between;
                @include desktop() {
                    width: 47%;
                }

                ul {
                    width: 50%;
                    @include desktop() {
                        width: auto;
                    }

                    &:first-child {
                        @include desktop() {
                            margin-right: 146px;
                        }
                    }
                }
            }

            .second-col {
                margin-bottom: 60px;
                @include desktop() {
                    margin-bottom: 0;
                }

                ul {
                    display: flex;
                    justify-content: space-between;
                    @include desktop(max) {
                        flex-direction: column;
                        margin-top: 28px;
                    }
                    @include desktop() {
                        display: block;
                        margin-left: 146px;
                    }

                    li {
                        width: 50%;
                        @include desktop() {
                            width: 100%;
                        }

                        &.year {
                            font-family: 'MaisonNeue-Extended-Book', sans-serif;
                            font-size: 12px;
                            line-height: normal;
                            letter-spacing: 1.2px;
                            color: $gray;
                            text-transform: uppercase;
                            @include desktop() {
                                font-size: 14px;
                                letter-spacing: 1.4px;
                            }
                        }
                    }
                }
            }

            ul {
                li {
                    margin-bottom: 25px;

                    &.site-credit {
                        display: none;
                        @include desktop() {
                            display: block;
                        }
                    }

                    a {
                        font-family: 'MaisonNeue-Extended-Book', sans-serif;
                        font-size: 12px;
                        line-height: normal;
                        letter-spacing: 1.2px;
                        color: $gray;
                        padding-bottom: 2px;
                        text-transform: uppercase;
                        border-bottom: 1px solid transparent;
                        @include desktop() {
                            font-size: 14px;
                            letter-spacing: 1.4px;
                        }

                        &:hover {
                            border-bottom: 1px solid $dark-gray;
                        }
                    }
                }
            }
        }
    }
}
