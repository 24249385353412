.account {
    background-color: $main-bg;
}

.account-wrapper {
    padding-bottom: 50px;
    @include xm() {
        padding: 0 33px 110px;
    }
    @include lg() {
        padding: 0 0 110px 0;
    }

    .account-header {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: $border;
        padding: 0;
        margin: 30px 25px 50px;
        @include desktop() {
            margin: 45px 0 60px;
            justify-content: space-between;
            padding: 0;
        }

        & > div {
            display: flex;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;

                &-track {
                    background-color: transparent;
                }

                &-thumb {
                    background-color: transparent;
                }
            }
        }

        button,
        a {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 14px;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: normal;
            color: $gray;
            padding-bottom: 8px;
            border-bottom: 1px solid transparent;
            white-space: nowrap;
            @include desktop() {
                font-size: 16px;
                line-height: 1.56;
            }

            &:hover {
                border-bottom: 1px solid $honey;
                color: $honey;
            }

            &.active {
                border-bottom: 1px solid $honey;
                color: $honey;
            }
        }

        a {
            margin-right: 30px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .account-content {
        background-color: $white;
        padding: 28px;
        margin: 0 25px;
        min-height: 590px;
        @include desktop() {
            min-height: unset;
            padding: 42px;
            margin: 0;
            border-radius: 10px;
        }
    }

    .content-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        padding: 0 28px 0 0;
        @include desktop() {
            margin-bottom: 120px;
            padding: 0;
        }

        h2 {
            font-family: 'MaisonNeue-Extended-Book', sans-serif;
            font-size: 12px;
            line-height: normal;
            letter-spacing: 1.2px;
            color: $dark-gray;
            text-transform: uppercase;
            @include desktop() {
                font-size: 14px;
                letter-spacing: 1.4px;
            }
        }
    }

    .my-info {
        @include desktop() {
            padding-bottom: 120px;
        }

        .inp-primary input {
            margin-bottom: 35px;

            &:not([type='radio']):not([type='checkbox']) {
                border-radius: 0;
                -webkit-appearance: none;
            }

            @include desktop() {
                margin-bottom: 55px;
            }
        }
    }

    .content-body {
        .connect-new-bank {
            display: flex;
            justify-content: center;
            font-family: MaisonNeue-Extended-Book, sans-serif;
            & > div {
                margin-left: 8px;
            }
        }
        form, .button-container {
            text-align: center;

            .inp-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                @include desktop() {
                    width: 100%;
                    margin: auto;
                    flex-direction: row;
                }
                @include lg(){
                    width: 945px;
                }

                .inp-primary {
                    &:first-child {
                        @include desktop() {
                            margin-right: 63px;
                        }
                    }
                }

                &:last-of-type {
                    @include desktop() {
                        margin-bottom: 50px;
                    }
                }
            }

            .show-mob {
                margin-bottom: 30px;
            }

            .btn-stroke {
                margin: auto;
            }
        }
    }

    .change-password {
        padding: 28px 28px 100px;
        @include desktop() {
            padding: 42px 42px 75px;
        }

        .content-header {
            margin-bottom: 105px;
            @include desktop() {
                margin-bottom: 65px;
            }
        }

        .inp-primary {
            margin: auto;
            width: 100%;
            max-width: 270px;
            @include desktop() {
                max-width: 440px;
            }

            input {
                margin-bottom: 35px;

                &:not([type='radio']):not([type='checkbox']) {
                    border-radius: 0;
                    -webkit-appearance: none;
                }

                @include desktop() {
                    margin-bottom: 55px;
                }
            }

            &:last-of-type {
                margin-bottom: 60px;
                @include desktop() {
                    margin-bottom: 25px;
                }
            }
        }

        .btn-link {
            margin-top: 30px;
            text-transform: uppercase;
        }
    }

    .bank-account {
        @include desktop() {
            padding: 42px 42px 140px;
        }

        .content-header {
            margin-bottom: 160px;
            @include desktop() {
                margin-bottom: 120px;
            }
        }

        .content-body > div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            @include desktop() {
                margin-bottom: 35px;
            }

            h1 {
                font-family: 'OrpheusPro', sans-serif;
                font-size: 24px;
                line-height: 1.25;
                letter-spacing: normal;
                color: $gray;
                //margin-bottom: 20px;
                @include desktop() {
                    font-size: 30px;
                    line-height: 1.27;
                }
            }

            p {
                text-align: center;
                //margin-bottom: 80px;
            }

            & > span {
                width: 24px;
                -o-object-fit: contain;
                object-fit: contain;
                margin-right: 7px!important;
            }
        }

        p {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: center;
            color: $dark-gray;
            @include desktop() {
                font-size: 16px;
                line-height: 1.56;
            }
        }

        .btn-stroke {
            margin: 35px auto 0;
            @include desktop() {
                margin: 47px auto 0;
            }
        }
    }
}
.account-popup-content {
    .content-logo {
        span {
            img {
                object-fit: contain;
            }
        }
    }
}
