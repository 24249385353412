.newsletter {
    &-email-modal {
        width: 100%;
        max-width: 87%;
        position: relative;
        z-index: 5;
        margin: 0 auto;
        background-color: $white;
        display: grid;
        color: $gray;

        @include desktop() {
            max-width: 894px;
            grid-template-columns: 1fr 1fr;
        }

        &-container {
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .close {
            z-index: 2;
        }

        .modal-img * {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
        }

        .modal-content {
            text-align: center;
            padding: 35px 20px 37px;

            @include desktop() {
                max-width: 362px;
                margin: 0 auto;
                padding: 119px 0;
            }

            form {
                margin-bottom: 15px;

                h2 {
                    display: none;
                }
            }

            & > button {
                display: block;
                margin: -35px auto 0;
                transform: translateX(40px);

                @include desktop(max) {
                    margin-top: -31px;
                }
            }

            h3 {
                font-family: $font-family-secondary;
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 20px;

                @include desktop() {
                    font-size: 30px;
                    line-height: 38px;
                    padding: 0 10px;
                    margin-bottom: 27px;
                }

                br {
                    @include xs() {
                        display: none;
                    }
                }
            }

            p {
                line-height: 25px;
                letter-spacing: 0.3px;
                margin-bottom: 36px;

                @include desktop(max) {
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 22px;
                }
            }

            .inp-primary {
                input {
                    margin-bottom: 15px;
                    border-radius: 0;
                    -webkit-appearance: none;

                    @include desktop() {
                        margin-bottom: 23px;
                    }
                }

                & > span {
                    right: 8px !important;
                }

                img {
                    width: 7px !important;
                    height: 9px !important;
                }
            }
        }
    }
}