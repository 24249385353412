.app.about {
    padding: 0;
}

.about {
    padding: 58px 0 26px;
    font-family: $bodyFont;
    line-height: 25px;

    @include desktop {
        padding: 96px 0 48px;
    }

    h1,
    h2,
    h3 {
        text-align: center;
    }

    h1 {
        margin-bottom: 52px;

        @media screen and(max-width: 991.98px) {
            font-size: 36px;
            line-height: 42px;
            margin-bottom: 36px;
        }
    }

    h2 {
        margin-bottom: 32px;

        @media screen and (max-width: 991.98px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    p {
        font-size: 14px;
        line-height: 22px;

        @include desktop {
            font-size: 16px;
            line-height: 25px;
            // letter-spacing: 0.2px;
        }
    }

    p~p {
        margin-top: 22px;
        @include desktop {
            margin-top: 25px;
        }
    }

    &__row {
        &:not(&--image):not(&--card) {
            padding: 40px 25px 54px;

            @include desktop {
                padding: 75px 0 79px;
            }
        }
        .container{
            @include between(md, lg){
                padding: 0 25px;
            }
        }

        &--card {
            @media screen and(max-width: 991.98px) {
                padding: 0 25px 30px;
            }
        }
    }

    &-top {
        &__content {
            padding: 28px 25px 42px;

            @include desktop {
                padding: 51px 0 77px;
            }
        }

        &__img {
            display: block;

            & > span {
                width: 100%!important;
                display: block!important;
                & > span {
                    img {
                        width: 100%!important;
                    }
                }

            }
        }
    }

    &__maxcontent {
        margin-left: auto;
        margin-right: auto;
        max-width: 684px !important;
    }

    &-card {
        &__grid {
            display: grid;
            grid-gap: 35px;

            @include desktop {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 12px;
            }
        }

        & > span {
            width: 100%!important;
            display: block!important;
            & > span {
                img {
                    width: 100%!important;
                }
            }

        }

        &__body {
            padding-top: 21px;

            @include desktop {
                padding-top: 34px;
            }

            h6 {
                font-size: 11px;
                font-family: 'MaisonNeue-Extended-Book';
                letter-spacing: 1.8px;
                margin-bottom: 8px;

                @include desktop {
                    font-size: 14px;
                    margin-bottom: 13px;
                    letter-spacing: 1.4px;
                }
            }

            p {
                @include desktop {
                    max-width: 335px;
                }
            }
        }
    }
}

.founder {
    padding: 56px 25px 56px;
    background-color: $main-bg;

    @include desktop {
        padding: 76px 0 104px;
    }

    h2 {
        margin-bottom: 25px;

        @include desktop {
            margin-bottom: 41px;
        }
    }

    &__container {
        max-width: 684px;
        margin-left: auto;
        margin-right: auto;
    }

    .founder__container > span {
        margin-top: 50px!important;

        & + p {
            margin-top: -2px;

            @media screen and(max-width: 991.98px) {
                margin-top: 1px;
                font-size: 15px;
                margin-left: 3px;
                letter-spacing: 0.4px;
            }
        }
    }

    img {
        margin-top: 50px;
        width: 164px;

        @include desktop {
            width: auto;
        }

        &+p {
            margin-top: -2px;

            @media screen and(max-width: 991.98px) {
                margin-top: 1px;
                font-size: 15px;
                margin-left: 3px;
                letter-spacing: 0.4px;
            }
        }
    }
}
