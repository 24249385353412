.signin {
    .signin-reset-wrapper {
        @include desktop() {
            padding-top: 0;
        }
    }
}

.signin-reset-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @include desktop() {
        padding-top: 50px;
    }

    .signin-reset-content {
        max-width: 325px;
        width: 100%;
        text-align: center;
        @include desktop() {
            max-width: 440px;
        }
    }

    h1 {
        font-family: 'OrpheusPro', sans-serif;
        font-size: 36px;
        line-height: 1.17;
        letter-spacing: normal;
        color: $dark-gray;
        text-align: center;
        margin-bottom: 7px;
        @include desktop() {
            font-size: 54px;
            line-height: 1.13;
            margin-bottom: 10px;
        }
    }

    p,
    .info-text a {
        font-family: 'MaisonNeue-Book', sans-serif;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: $dark-gray;
        @include desktop() {
            font-size: 16px;
            line-height: 1.56;
        }
    }

    .cancel {
        text-transform: uppercase;
    }

    .info-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 87%;
        max-width: 325px;
        margin: 64px auto 74px;
        @include desktop() {
            display: block;
            width: unset;
            max-width: unset;
            align-items: unset;
            flex-direction: unset;
        }


        a {
            display: inline-block;
            @include desktop() {
                display: inline;
                margin-left: 5px;
            }

            &:hover {
                color: $honey;
            }
        }
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 325px;
        margin: 65px auto 5px;
        @include desktop() {
            max-width: 440px;
            margin: 65px auto 40px;
        }

        .inp-primary {
            input {
                &:not([type='radio']):not([type='checkbox']) {
                    border-radius: 0;
                    -webkit-appearance: none;
                }
            }
        }

        .reset-pass {
            width: 100%;
            text-align: right;
            margin-top: -40px;
            margin-bottom: 57px;
            position: relative;
            z-index: 2;
            @include desktop() {
                //margin-bottom: 0;
            }

            .btn-link {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 16px;
                line-height: 1.56;
                letter-spacing: normal;
            }
        }

        .signin,
        .reset {
            width: 150px;
            height: 50px;
            margin: 15px 0;
        }

        .reset {
            @include desktop() {
                margin: 0;
            }
        }

        .signin {
            @include desktop() {
                margin: 60px 0 0;
            }
        }
    }
}
