.registry-toggle {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    .tooltip-wrap {
        top: -142px !important;
        right: -88px !important;
        @include desktop() {
            right: -65px !important;
            top: 0 !important;
        }

        .tooltip {
            &::before {
                @include desktop(max) {
                    right: 190px !important;
                }
            }
        }

        &.tooltip-active {
            display: flex !important;
        }
    }

    & > p {
        object-fit: unset !important;
        font-family: 'MaisonNeue-Book', sans-serif;
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;

        @include desktop() {
            line-height: 20px;
            margin-left: 10px;
            margin-top: 5px;
        }

        @include desktop(max) {
            margin-right: 9px;
        }

        &.noActive {
            opacity: .5;
        }
    }

    label {
        width: 100%;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        @include desktop() {
            width: fit-content;
            margin-left: auto;
        }

        &::before {
            content: '';
            display: inline-block;
            width: 61px;
            height: 24px;
            border-radius: 100px;
            background-color: $timeless-beauty;
            transition: background-color .4s ease;
        }

        &::after {
            content: '';
            width: 20px;
            height: 20px;
            background-color: $white;
            border-radius: 100%;
            position: absolute;
            top: 2px;
            right: 38px;
            transition: right .4s ease;
        }

        &.noActive {
            cursor: no-drop;
        }

        p {
            object-fit: unset !important;
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 14px;

            &:first-child {
                line-height: 22px;
                white-space: nowrap;
                @include desktop() {
                    line-height: 20px;
                    position: relative;
                    left: -9px;
                }
                @include desktop(max) {
                    margin-right: 9px;
                }
            }

            &:nth-child(2) {
                color: $white;
                line-height: 25px;
                position: absolute;
                right: 12px;
                transition: right .3s ease;
            }
        }
    }

    input {
        display: none;

        &:checked + p + label {
            p:nth-child(2) {
                right: 29px;
            }

            &::before {
                background-color: $sea-monster;
            }

            &::after {
                right: 3px;
            }
        }
    }
}