.directory {

    &-wrapper {
        color: $dark-gray;
        background-color: $cream;
        margin-top: -59px;
        padding-top: 59px;

        @include desktop() {
            margin-top: -83px;
            padding-top: 165px;
        }
    }

    &__filters {
        padding: 35px 25px 102px;

        @include desktop() {
            max-width: 1246px;
            margin: 0 auto;
            padding: 19px 25px 102px;
        }

        .popup-content-wrapper {
            @include desktop(max) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &-head {
        margin-bottom: 32px;

        @include desktop() {
            margin-bottom: 60px;
        }

        h1 {
            font-family: $font-family-secondary;
            font-size: 36px;
            line-height: 42px;
            margin-bottom: 17px;

            @include desktop() {
                font-size: 54px;
                line-height: 61px;
                margin-bottom: 27px;
            }
        }

        h2 {
            line-height: 20px;
            letter-spacing: 0.3px;

            @include desktop(max) {
                font-size: 14px;
            }
        }
    }

    &-filter {
        border-radius: 10px;
        background-color: $white;
        padding: 18px 19px;

        @include desktop() {
            padding: 15px 14px 22px;
            height: fit-content;
        }

        @include desktop(max) {
            margin-bottom: 69px;

            &:not(.open) {
                .filters {
                    display: none;
                }

                & > h3 button {
                    right: -1px;
                    top: -1px;
                    padding: 5px;

                    &::before {
                        content: '';
                        width: 17px;
                        height: 17px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-image: url('data:image/svg+xml,%3Csvg width=\'15\' height=\'15\' viewBox=\'0 0 15 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M14.5 7.75H1M7.75 1v13.5\' stroke=\'%23464646\' stroke-linecap=\'round\'/%3E%3C/svg%3E');
                    }
                }

                & > button {
                    display: none;
                }
            }

            &.open {
                border-radius: 0;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                overflow: auto;
                z-index: 5;
                padding: 21px 25px 150px;

                & > h3 {
                    margin-bottom: 37px;

                    button {
                        color: $gray;

                        &::before {
                            content: 'APPLY';
                            font-family: 'MaisonNeue-Extended-Book', sans-serif;
                            font-size: 12px;
                            color: $gray;
                            letter-spacing: 1.2px;
                            border-bottom: 1px solid $gray;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        .filters {
            @include desktop(max) {
                border-bottom: $border;

                &:not(.active) {
                    .filters-list,
                    & > button {
                        display: none;
                    }
                }

                &.active > p::after {
                    transform: translateY(-50%) rotate(180deg);
                }
            }

            @include desktop() {
                padding-left: 4px;
            }

            &:not(:last-child) {
                margin-bottom: 16px;

                @include desktop() {
                    margin-bottom: 29px;
                }
            }

            &-list {
                @include desktop(max) {
                    padding-bottom: 12px;
                }
            }

            & > p {
                font-family: 'MaisonNeue-Extended-Book', sans-serif;
                font-size: 12px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                margin-bottom: 16px;

                @include desktop() {
                    font-size: 14px;
                    letter-spacing: 1.4px;
                    margin-bottom: 14px;
                }

                @include desktop(max) {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        width: 12px;
                        height: 7px;
                        background-size: contain;
                        background-position: center;
                        background-image: url('data:image/svg+xml,%3Csvg width=\'12\' height=\'7\' viewBox=\'0 0 12 7\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M10.88 1 5.94 5.94 1 1\' stroke=\'%23464646\' stroke-linecap=\'round\'/%3E%3C/svg%3E');
                    }
                }
            }

            & > button {
                padding: 0;
                border: none;
                background-color: transparent;
                font-family: $font-family-primary;
                text-decoration: underline;
                margin-top: 5px;
                letter-spacing: 0.3px;
                font-size: 14px;

                @include desktop() {
                    font-size: 16px;
                }

                @include desktop(max) {
                    margin-bottom: 15px;
                }
            }

            .checkbox-wrapper {
                width: unset;
                height: unset;
                font-family: $font-family-primary;
                font-size: 14px;
                line-height: 22px;

                @include desktop() {
                    font-size: 16px;
                    line-height: 25px;
                }

                label {
                    letter-spacing: 0.3px;
                    position: relative;
                    padding-left: 20px;

                    @include desktop() {
                        line-height: 21px;
                        padding-left: 18px;
                    }

                    &::before {
                        width: 8px;
                        height: 8px;
                        background-size: 8px;
                        position: absolute;
                        top: 6px;
                        left: -1px;

                        @include desktop(max) {
                            top: 5px;
                        }
                    }
                }
            }
        }

        & > h3 {
            font-family: $font-family-secondary;
            font-size: 24px;
            position: relative;

            @include desktop(max) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            @include desktop() {
                font-size: 30px;
                line-height: 38px;
                padding-left: 4px;
                margin-bottom: 22px;
            }

            button {
                @include desktop(max) {
                    position: relative;
                    cursor: pointer;
                    padding: 0;
                    background-color: transparent;
                    border: none;

                    &::before {
                        display: inline-block;
                    }
                }

                @include desktop() {
                    display: none;
                }
            }
        }

        & > button {
            margin-top: 6px;
            margin-left: 5px;
            text-transform: uppercase;
        }

        &-container {

            @include desktop(max) {
                display: flex;
                flex-direction: column;
            }

            @include desktop() {
                display: grid;
                grid-gap: 11px;
                grid-template-columns: 0.32fr 1fr;
            }

            //@media (min-width: 1024px) and (max-width: 1029px) {
            //    grid-template-columns: 1.32fr 1fr !important;
            //}
        }

        &-content {
            display: flex;
            flex-direction: column;
        }

        &-cards {
            position: relative;
        }

        &-results {
            display: grid;
            grid-gap: 19px 11px;

            @include tablet() {
                grid-gap: 9px 11px;
                grid-template-columns: repeat(2, 1fr);
            }

            @include between(md, lg) {
                grid-gap: 9px 8px;
                grid-template-columns: repeat(3, 32.5%);
            }

            @include lg() {
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: .6fr;
            }

            .card-item {
                position: relative;
                width: 100%;
                height: 100%;
                background-color: $white;
                border-radius: 10px;

                &.by-lhm::after{
                    content: '';
                    position: absolute;
                    width: 9px;
                    height: 9px;
                    right: -6px;
                    top: 37px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-image: url('data:image/svg+xml,%3Csvg width=%2212%22 height=%2212%22 viewBox=%220 0 12 12%22 fill=%22none%22 xmlns=%22http://www.w3.org/2000/svg%22%3E%3Crect x=%226%22 width=%228.48537%22 height=%228.48528%22 transform=%22rotate(45 6 0)%22 fill=%22%23CCBA9C%22/%3E%3C/svg%3E');

                    @include desktop(max) {
                        top: 32px;
                    }
                }

                &-label {
                    font-family: 'MaisonNeue-Extended-Book', sans-serif;
                    font-size: 12px;
                    letter-spacing: 1.2px;
                    text-transform: uppercase;
                    background-color: $illusive-dream;
                    width: fit-content;
                    position: absolute;
                    right: -6px;
                    top: 10px;
                    padding: 7.5px 4px;

                    @include desktop() {
                        top: 7px;
                        font-size: 14px;
                        letter-spacing: 1.4px;
                        padding: 10px 8px;
                    }

                    &:first-letter {
                        font-size: 11.7px;

                        @include desktop(max) {
                            font-size: 11px;
                        }
                    }
                }

                &-wrapper {
                    height: 100%;
                }

                &-container {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-color: $white;
                    border-radius: 10px;
                    padding: 10px 15px 88px;
                    z-index: 1;

                    @include desktop() {
                        min-height: 380px;
                        padding: 10px 17px;
                    }


                    & > span {
                        position: absolute;
                        font-family: $font-family-primary;
                        font-size: 10px;
                        line-height: 12px;
                        right: 14px;
                        bottom: 12px;
                        color: $dark-cream;
                        letter-spacing: 0.2px;

                        @include desktop() {
                            font-size: 12px;
                            line-height: 19px;
                            right: 19px;
                            letter-spacing: 0.3px;
                        }
                    }

                    .head {
                        display: grid;
                        grid-template-columns: 50px 1fr;
                        align-items: start;
                        padding-top: 35px;
                        margin-bottom: 18px;

                        @include desktop() {
                            padding-top: 30px;
                            margin-bottom: 14px;
                        }

                        h3 {
                            font-family: $font-family-secondary;
                            font-size: 24px;
                            line-height: 30px;
                            margin-left: 15px;
                            align-self: center;

                            @include desktop() {
                                font-size: 22px;
                                line-height: 28px;
                                margin-left: 10px;
                            }
                        }

                        span, img {
                            width: 50px !important;
                            height: 50px !important;
                            border-radius: 50% !important;
                            object-fit: cover !important;
                        }
                    }
                }

                &-content {
                    p, span {
                        font-family: $font-family-primary;
                        font-size: 14px;
                        line-height: 22px;
                        letter-spacing: 0.3px;

                        @include desktop() {
                            font-size: 16px;
                            line-height: 25px;
                            letter-spacing: 0.6px;
                        }

                        a {
                            word-break: break-all;
                            text-decoration: underline;
                        }
                    }

                    p {
                        color: $dark-cream;

                        @include desktop(max) {
                            margin-bottom: 2px;
                        }
                    }

                    div:not(:last-child) {
                        margin-bottom: 19px;

                        @include desktop() {
                            margin-bottom: 14px;
                        }
                    }
                }
            }
        }

        &-empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: $dark-gray;
            padding-top: 128px;

            h3 {
                font-family: $font-family-secondary;
                font-size: 24px;
                margin-bottom: 17px;

                @include desktop() {
                    font-size: 30px;
                }
            }

            p {
                font-family: $font-family-primary;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.3px;

                @include desktop() {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        }
    }

    &-sort {
        position: absolute;
        right: 0;
        top: -46px;
        display: flex;
        align-items: flex-start;

        @include desktop(max) {
            font-size: 14px;
            right: 5px;
            top: -40px;
        }

        .inp-primary {
            min-width: 135px;

            @include desktop() {
                min-width: 145px;
            }

            select {
                @include desktop(max) {
                    font-size: 14px;
                }
            }

            & > span {
                @include desktop(max) {
                    top: 3px !important;
                }
            }

            .label {
                margin-right: unset !important;
            }
        }

        & > p {
            white-space: nowrap;
            margin-top: 5px;
            margin-right: 5px;

            @include desktop(max) {
                margin-top: 6px;
            }
        }

        li {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

    &-city {
        &-modal {
            width: 100%;
            max-width: 87%;
            position: relative;
            z-index: 5;
            margin: 0 auto;
            background-color: $white;
            padding: 95px 0;

            @include desktop() {
                max-width: 894px;
                padding: 116px 0;
            }

            .close {
                @include desktop(max) {
                    top: 20px !important;
                    right: 20px !important;
                }
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @include desktop() {
                max-width: 764px;
                margin: 0 auto;
            }

            @include desktop(max) {
                padding: 0 22px;
            }

            & > div > div {
                margin-bottom: 26px;

                & > form {
                    .cog-form__content .cog-header {
                        display: none !important;
                    }

                    .cog-body {
                        margin-top: 0 !important;
                    }

                    .cog-col--24 {
                        margin-bottom: 0 !important;

                        .cog-content {
                            padding-top: 0 !important;
                            margin-bottom: 0 !important;

                            h3 {
                                font-family: $font-family-secondary !important;
                                font-size: 24px !important;
                                font-weight: 400 !important;
                                line-height: 30px !important;
                                margin-bottom: 18px !important;
                                text-align: center !important;
                                color: $gray !important;

                                @include desktop() {
                                    font-size: 30px !important;
                                    line-height: 38px !important;
                                    margin-bottom: 22px !important;
                                }
                            }

                            p {
                                font-family: $font-family-primary !important;
                                letter-spacing: 0.2px !important;
                                line-height: 22px !important;
                                margin-top: 0 !important;
                                margin-bottom: 25px !important;
                                color: $gray !important;
                                text-align: center !important;

                                @include desktop(max) {
                                    font-size: 14px !important;
                                }

                                @include desktop() {
                                    font-size: 16px !important;
                                    line-height: 24px !important;
                                    margin-bottom: 48px !important;
                                }
                            }
                        }
                    }

                    .cog-field--1 {
                        margin-top: 0 !important;

                        .cog-section__inner {
                            padding: 0 !important;

                            h2, label {
                                display: none !important;
                            }
                        }

                        .cog-input {
                            max-width: 345px !important;
                        }

                        .cog-row {
                            align-items: flex-end !important;
                        }
                    }

                    .cog-picker {
                        @include desktop(max) {
                            input {
                                font-size: 16px !important;
                                padding: 0 0 6px !important;
                            }

                            select {
                                border: none !important;
                                font-size: 16px !important;
                                border-bottom: 1px solid $black !important;
                                padding: 0 0 6px !important;

                                + .el-input__prefix {
                                    top: -8px !important;
                                }
                            }
                        }
                    }

                    .cog-input {
                        padding: 0 !important;
                        margin: 0 auto !important;

                        .el-input {
                            position: relative !important;

                            @include desktop(max) {
                                margin-top: 15px !important;
                            }

                            input {
                                padding-left: 0 !important;
                                padding-right: 0 !important;
                                width: 100% !important;
                                border: unset !important;
                                border-bottom: 1px solid #464646 !important;
                                font-family: 'MaisonNeue-Book', sans-serif !important;
                                font-size: 16px !important;
                                line-height: 1.57 !important;
                                letter-spacing: normal !important;
                                background-color: transparent !important;

                                @include desktop(max) {
                                    padding-top: 0 !important;
                                    padding-bottom: 6px !important;
                                }

                                &:focus {
                                    box-shadow: unset !important;
                                }
                            }
                        }
                    }

                    .cog-page__navigation {
                        margin: 0 auto !important;
                        justify-content: center !important;
                        padding-top: 26px !important;

                        @include desktop() {
                            padding-top: 47px !important;
                        }

                        button {
                            width: 178px !important;
                            font-size: 12px !important;
                            letter-spacing: 1.2px !important;
                            display: flex !important;
                            justify-content: center !important;
                            align-items: center !important;
                            height: 50px !important;
                            background-color: $black !important;
                            border-radius: 10px !important;
                            border: solid 1px $black !important;
                            font-family: 'MaisonNeue-Extended-Book', sans-serif !important;
                            line-height: normal !important;
                            color: $white !important;
                            text-transform: uppercase !important;
                            cursor: pointer !important;

                            @include desktop() {
                                width: 219px !important;
                                font-size: 14px !important;
                                letter-spacing: 1.4px !important;
                            }

                            &:not(:disabled):hover:not(.btn-loading),
                            &.hover {
                                background-color: $black !important;
                                color: $white !important;
                            }

                            &:focus {
                                outline: none !important;
                                box-shadow: unset !important;
                            }
                        }
                    }

                    .cog-field.is-error {
                        background-color: transparent !important;

                        &::after {
                            outline: unset !important;
                            background-color: transparent !important;
                        }

                        .cog-error-message {
                            background-color: #ffb18f !important;
                            width: 100% ;
                            padding: 5px !important;
                            margin: 5px auto 1px !important;
                            font-family: 'MaisonNeue-Book', sans-serif !important;
                            font-size: 16px !important;
                            line-height: 1.56;
                            letter-spacing: normal;
                            text-align: center;
                            color: #464646 !important;
                            max-width: 345px !important;

                            @include desktop(max) {
                                font-size: 14px !important;
                            }
                        }
                    }

                    .cog-abuse, .cog-branding {
                        display: none !important;
                    }

                    .cog-confirmation-message .cog-confirmation__message {
                        font-family: $font-family-primary !important;
                        font-size: 16px !important;
                        text-align: center !important;
                        color: $dark-gray !important;
                    }
                }
            }
        }
    }

    &-inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0 auto;

        @include desktop() {
            max-width: 345px;
        }

        .inp-primary {
            @include desktop() {
                margin-bottom: 4px;
            }

            input {
                @include desktop(max) {
                    margin-bottom: 38px;
                }
            }
        }

        .btn-fill {
            margin-bottom: 27px;

            @include desktop() {
                margin-bottom: 26px;
            }
        }
    }

    &__interest {
        background-color: $main-bg;

        &-container {
            display: grid;
            padding: 58px 25px 20px;

            @include desktop(max) {
                grid-gap: 53px;
            }

            @include desktop() {
                max-width: 1277px;
                margin: 0 auto;
                grid-template-columns: repeat(2, 1fr);
                padding: 100px 25px 102px;
            }

            h2 {
                font-family: $font-family-secondary;
                font-size: 36px;
                line-height: 42px;
                margin-bottom: 18px;

                @include desktop() {
                    font-size: 54px;
                    line-height: 61px;
                    margin-bottom: 25px;
                }
            }

            & > div {
                &:first-child {
                    @include desktop() {
                        padding-top: 18px;
                        max-width: 612px;
                    }
                }


                &:last-child {
                    width: 100%;

                    @include desktop() {
                        display: flex;
                        margin-left: auto;
                        max-width: 86%;
                    }

                    * {
                        width: 100% !important;
                    }
                }
            }

            p {
                line-height: 22px;
                margin-bottom: 20px;

                @include desktop(max) {
                    font-size: 14px;
                    letter-spacing: 0.3px;
                }

                @include desktop() {
                    max-width: 515px;
                    line-height: 25px;
                    margin-bottom: 34px;
                }
            }
        }
    }
}


.el-select-dropdown__wrap {
    .el-select-dropdown__item {
        font-family: $font-family-primary !important;
        font-size: 16px !important;

        &:hover,
        &.selected {
            font-weight: 400 !important;
            color: $honey !important;
        }
    }
}