.added-registry {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 61px;
    background: $honey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 18px;
    @include desktop() {
        padding: 0 23px;
    }
    @include lg(){
        padding: 0 30px;
    }
    & > div {
        display: flex;
        margin-right: 10px;
        span {
            width: 20px!important;
            height: 20px!important;
            margin-right: 10px!important;

            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
    span {
        width: 29px!important;
        height: 29px!important;

        img {
            width: 29px;
            height: 29px;
            -o-object-fit: contain;
            object-fit: contain;
            margin-top: 2px;
        }
    }
    p {
        font-family: 'MaisonNeue-Book', sans-serif;
        font-size: 10px;
        line-height: 1.2;
        letter-spacing: normal;
        color: $gray;
        @include desktop() {
            font-size: 16px;
            line-height: 1.81;
        }
    }
}
