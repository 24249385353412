.alert {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    font-family: 'MaisonNeue-Book', sans-serif;
    border-radius: 10px;
    margin-bottom: 10px;

    &.alert-info {
        background-color: $soar;
        img {
            padding-right: 10px!important;
        }
    }
}