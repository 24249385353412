.onboarding {
    .onboarding-wrapper .logo {
        margin-bottom: 0;
        @include desktop() {
            margin-bottom: 55px;
        }
    }
}

.onboarding,
.onboarding1,
.onboarding2,
.onboarding3,
.onboarding4,
.onboarding5 {
    main {
        display: none;
    }
}

.onboarding-wrapper {
    background: white;
    height: 100vh;
    @extend .hide-scroll;
    @include desktop() {
        background: $cream;
        padding-bottom: 120px;
        overflow: auto;
    }
    @include lg(){
        padding-bottom: unset;
    }

    .logo {
        margin: auto;
        padding: 25px 30px;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include desktop() {
            padding: 30px;
            background: transparent;
            width: 330px;
            margin-bottom: 55px;
        }

        &.cream {
            background: $cream;
        }

        img {
            width: 161px;
            margin: auto;
            @include desktop() {
                width: 326px;
            }
        }

        & > div {
            display: flex;

            img {
                width: 161px !important;
                @include desktop() {
                    width: unset !important;
                }
            }
        }
    }

    .onboarding-container {
        margin: auto;
        @include xm() {
            max-width: 900px;
        }
        @include desktop() {
            max-width: 1236px;
        }

        .onboarding-header {
            background: white;
            margin: auto;
            position: relative;
            display: flex;
            justify-content: center;
            padding: 0 45px;

            @include desktop() {
                justify-content: space-between;
                padding: 32px 32px 0;
            }

            p:first-child {
                margin-right: 10px;
            }

            img {
                cursor: pointer;
                -o-object-fit: contain;
                object-fit: contain;
            }

            & > img {
                width: 15px;
                height: 15px;
                position: absolute;
                @include desktop() {
                    display: none;
                }

                &:first-of-type {
                    left: 25px;
                }

                &:last-of-type {
                    right: 25px;
                }
            }

            .title {
                font-family: 'MaisonNeue-Extended-Book', sans-serif;
                font-size: 12px;
                font-size: 12px;
                line-height: normal;
                letter-spacing: 1.2px;
                color: $dark-gray;
                @include desktop() {
                    font-size: 14px;
                    letter-spacing: 1.4px;
                }
            }

            .pageination {
                display: flex;
                align-items: center;
                grid-gap: 3px;
                @include desktop() {
                    padding-right: 10px;
                    grid-gap: 5px;
                }

                & > div {
                    position: absolute !important;

                    &:first-child {
                        left: 25px;
                    }

                    &:last-child {
                        right: 25px;
                    }

                    @include xm() {
                        position: relative !important;
                        left: unset !important;
                        right: unset !important;
                    }
                }

                span {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 12px;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $dark-gray;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 1.56;
                    }
                }

                img {
                    height: 14px;
                    width: 14px;
                    display: block;
                    cursor: pointer;
                    position: absolute;
                    @include desktop() {
                        position: unset;
                    }

                    &:first-of-type {
                        left: 25px;
                    }

                    &:last-of-type {
                        right: 25px;
                    }
                }
            }
        }

        .onboarding-footer {
            margin: auto;
            background: $light-gray;
            height: 6px;

            div {
                background: $honey;
                height: 100%;
            }
        }

        .onboarding-content {
            position: relative;
            margin: auto;
            background: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 25px;
            height: calc(95vh - 155px);
            @include desktop() {
                height: calc(100vh - 155px);
                min-height: 608px;
            }
            @include lg() {
                height: 100%;
            }

            h1 {
                font-family: 'OrpheusPro', sans-serif;
                font-size: 24px;
                line-height: 1.25;
                letter-spacing: normal;
                color: $gray;
                text-align: center;
                margin-bottom: 30px;
                @include desktop() {
                    font-size: 30px;
                    line-height: 1.27;
                    margin-bottom: 40px;
                }
            }

            &.topics {
                padding: 100px 15px 25px;
                justify-content: unset;
                @include desktop() {
                    padding: 25px;
                    justify-content: center;
                }

                h1 {
                    margin-bottom: 3px;
                    @include desktop() {
                        margin-bottom: 40px;
                    }
                }
            }

            &.help {
                padding: 80px 25px 25px;
                height: unset;
                @include desktop() {
                    padding: 25px;
                    height: 608px;
                }

                h1 {
                    margin-bottom: 43px;
                    @include desktop() {
                        margin-bottom: 40px;
                    }
                }

                .about-wrapper {
                    .about {
                        @include desktop() {
                            padding: 65px 0 50px;
                        }
                    }

                    .btn-fill {
                        width: 100%;
                        @include desktop() {
                            width: 259px;
                            margin: auto;
                        }
                    }
                }
            }

            .checkbox-container {
                margin-top: 37px;
                @include desktop() {
                    margin-top: unset;
                }
            }

            .next {
                position: absolute;
                bottom: 0;
                margin-bottom: 15px;
                @include desktop() {
                    bottom: 30px;
                    margin-bottom: 0;
                }
                @include lg() {
                    bottom: 70px;
                }

                &:disabled {
                    border: unset;
                    color: $light-gray;
                }
            }
        }
    }

    .cover {
        max-width: 1236px;
        margin: auto;
        background: white;
        display: flex;
        flex-direction: column;
        @include desktop() {
            flex-direction: row;
        }

        & > div {
            order: 2;
            padding: 30px 25px;
            @include desktop() {
                width: 50%;
                order: 1;
                padding: 50px;
            }
            @include lg() {
                padding: 120px 80px;
            }
        }

        & > span {
            order: 1;
            -o-object-fit: cover;
            object-fit: cover;
            @include desktop() {
                width: 50% !important;
                order: 2;
            }
        }

        h1 {
            font-family: 'OrpheusPro', sans-serif;
            font-size: 36px;
            line-height: 1.17;
            letter-spacing: normal;
            color: $dark-gray;
            margin-bottom: 12px;
            @include desktop() {
                font-size: 54px;
                line-height: 1.13;
                margin-bottom: 19px;
            }
        }

        p {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: normal;
            color: $dark-gray;
            margin-bottom: 35px;
            @include desktop() {
                font-size: 16px;
                line-height: 1.56;
                margin-bottom: 50px;
            }
        }

        .title {
            display: none;
            @include desktop() {
                display: block;
                font-family: 'MaisonNeue-Extended-Book', sans-serif;
                font-size: 12px;
                line-height: normal;
                letter-spacing: 1.2px;
                color: $dark-gray;
                text-transform: uppercase;
                margin-bottom: 30px;
            }
        }

        .btn-fill {
            width: 178px;
            @include desktop() {
                width: 186px;
            }
        }
    }

    .topics-wrapper {
        margin-bottom: 20px;
        @include desktop() {
            margin-bottom: 0;
        }

        h1 {
            margin-bottom: 5px;
        }

        h3 {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 10px;
            line-height: 1.9;
            letter-spacing: normal;
            text-align: center;
            color: $dark-gray;
            margin-bottom: 31px;
            @include desktop() {
                font-size: 16px;
                line-height: 1.56;
                margin-bottom: 40px;
            }
        }

        .topics-container {
            @include tablet() {
                height: 50vh;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            @include desktop() {
                height: unset;
                display: block;
                align-items: unset;
                justify-content: unset;
            }

            .topics {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                height: calc(100vh - 397px);
                overflow: auto;
                @extend .hide-scroll;

                @include tablet() {
                    height: calc(100vh - 812px);
                }

                @include desktop() {
                    height: 347px;
                    width: 800px;
                }

                @include lg() {
                    width: 995px;
                    height: unset;
                }

                div {
                    margin: 6px;
                    @include desktop() {
                        margin: 12px;
                    }
                }

                & > div {
                    input {
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;

                        &:checked + label {
                            background-color: $dark-gray;
                            color: $white;
                        }
                    }

                    label {
                        width: -webkit-max-content;
                        width: -moz-max-content;
                        width: max-content;
                        height: auto;
                        margin: auto;
                        border-radius: 100px;
                        padding: 5px 14px;
                        text-transform: capitalize;
                        font-size: 14px;
                        line-height: 1.57;
                        letter-spacing: normal;
                        font-family: "MaisonNeue-Book", sans-serif;
                        @include desktop() {
                            font-size: 16px;
                            line-height: 1.56;
                            padding: 9px 25px;
                        }
                    }
                }
            }
        }
    }
}

.setting-up-wrapper {
    background-color: $cream;
    height: 100vh;

    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding-top: 204px;
        @include desktop() {
            padding-top: 255px;
        }

        & > div {
            height: calc(100vh - 204px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include desktop() {
                height: calc(100vh - 255px);
            }

            h2 {
                font-family: 'OrpheusPro', sans-serif;
                font-size: 36px;
                line-height: 1.17;
                color: $gray;
                @include desktop() {
                    font-size: 54px;
                    line-height: 1.13;
                }
            }

            .marquee-container {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;

                p {
                    font-family: "MaisonNeue-Extended-Light", sans-serif;
                    font-size: 46px;
                    color: $honey;
                    text-transform: uppercase;
                    margin-bottom: 35px;
                    letter-spacing: 2px;
                    @include desktop() {
                        margin-bottom: 40px;
                        font-size: 57px;

                    }

                    &:first-child,
                    &:nth-child(3) {
                        -webkit-animation: left-to-right 100s linear infinite;
                        animation: left-to-right 100s linear infinite;
                    }

                    &:nth-child(2),
                    &:nth-child(4) {
                        -webkit-animation: right-to-left 50s linear infinite;
                        animation: right-to-left 50s linear infinite;
                    }
                }
            }
        }
    }
}

@-webkit-keyframes right-to-left {
    100% {
        transform: translate(-100%, 0);
    }
}

@keyframes right-to-left {
    100% {
        transform: translate(-100%, 0);
    }
}

@-webkit-keyframes left-to-right {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes left-to-right {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
