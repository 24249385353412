.search-form{
    width: 100%;
    position: relative;
    z-index: 1;
    @include desktop(){
        max-width: 483px;
    }
    & > img{
        position: absolute;
        width: 15px;
        top: 17px;
        left: 18px;
    }
    input{
        width: 100%;
        height: 50px;
        font-family: "MaisonNeue-Book", sans-serif;
        font-size: 16px;
        line-height: 25px;
        border-radius: 10px;
        padding: 18px 45px 18px;
        border: 1px solid $gray;
    }
    & > button{
        position: absolute;
        cursor: pointer;
        background-color: transparent;
        border: none;
        right: 17px;
        top: 21px;
        width: 8px;
        height: 8px;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
        & > img {
            width: 8px;
            position: absolute;
            left: 3px;
            top: -1px;
        }
    }
    .search-container{
        border: 1px solid $dark-gray;
        border-radius: 0 0 10px 10px;
        position: absolute;
        width: 100%;
        z-index: -1;
        top: 42px;
        background-color: $white;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
        overflow: hidden;
        &::before{
            content: '';
            height: 100vh;
            width: 100%;
            background-color: $white;
            position: absolute;
            z-index: -2;
            opacity: 0;
            visibility: hidden;
        }
        .auto-fill{
            &:first-child{
                padding-top: 24px;
            }
            width: 100%;
            display: flex;
            align-items: center;
            font-family: "MaisonNeue-Extended-Book", sans-serif;
            font-size: 12px;
            line-height: 15px;
            color: $dark-gray;
            text-transform: uppercase;
            background-color: transparent;
            border: none;
            border-bottom: $border;
            padding: 19px 17px;
            @include desktop(){
                font-size: 14px;
                line-height: 17px;
            }
            img{
                width: 39px;
                height: 39px;
                object-fit: contain;
                margin-right: 16px;
            }
            &:last-child{
                border-bottom: unset;
            }
            &:hover{
                background-color: $main-bg;
            }
        }
    }
    &.active-search{
        & > button, .search-container{
            opacity: 1;
            visibility: visible;
        }
        .search-container{
            &::before{
                opacity: 0.6;
                visibility: visible;
            }
        }
    }
}

