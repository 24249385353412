.get-started {
    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 60px 0;

        @include desktop() {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
        }
    }

    img {
        display: block;
        width: 100%;
        height: 360px;
        object-fit: cover;

        @include desktop() {
            height: 486px;
        }
    }

    &__caption {
        padding: 19px 0 0;

        @include desktop() {
            padding: 15px 0 0;
        }
    }

    &__title {
        @media screen and (max-width: 991.92px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    &__desc {
        margin-top: 8px;
        font-family: $bodyFont;
        font-size: 12px;
        line-height: 20px;

        @media screen and (max-width: 991.92px) {
            margin-top: 15px;
            font-size: 14px;
            line-height: 22px;
        }
        @include desktop(){
            min-height: 40px;
        }
    }

    .btn-link {
        display: inline-block;
        margin-top: 20px;

        @include desktop() {
            margin-top: 23px;
        }
    }
}

section.get-started {
    padding: 21px 25px;

    @include desktop() {
        padding: 25px;
    }

    .container {
        max-width: 1236px;
    }
}