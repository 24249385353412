.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 87px;

  @include desktop(max) {
    position: relative;
  }

  @include desktop() {
    margin-top: 62px;
  }

  & > button {
    width: 26px;

    @include desktop(max) {
      position: absolute;
    }

    &:first-child {
      @include desktop(max) {
        left: 0;
      }

      & > span {
        transform: scaleX(-1) !important;
      }
    }

    &:last-child {
      @include desktop(max) {
        right: 0;
      }
    }
  }

  .pagination-content {
    display: flex;
    align-items: center;

    @include desktop(max) {
      margin-left: 65px;
      margin-right: 65px;
    }

    @include desktop() {
      margin-left: 50px;
      margin-right: 50px;
    }

    li {
      font-family: $font-family-primary;
      font-size: 16px;
      color: $dark-gray;

      &:not(:last-child) {
        margin-right: 14px;
      }
    }

    .pagination-item-page {
      cursor: pointer;
      letter-spacing: 0.8px;
      padding: 0 4px 5px;
      border-bottom: 1px solid transparent;

      &:not(.active) {
        color: $dark-cream;
      }

      &.active {
        border-color: $dark-gray;
      }
    }

    .pagination-item-dots {
      font-size: 13px;
      margin-right: 18px;
    }
  }
}