.account-content {
    &.bank-account-info {
        padding: 28px 19px;
        height: 590px;
        @include desktop() {
            padding: 42px;
            height: 559px;
        }

        .content-header {
            @include desktop() {
                margin-bottom: 26px;
            }
        }

        h2 {
            padding-left: 9px;
            @include desktop() {
                padding-left: unset;
            }
        }

        .bank-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .bank-subtype {
                text-transform: capitalize;
            }

            .bank-img {
                position: relative;
                margin-bottom: 24px;
                width: 103px;
                height: 98px;

                & > span {
                    &:first-child {
                        width: 103px;
                        height: 98px;
                    }

                    &:nth-child(2) {
                        position: absolute!important;
                        width: 42px!important;
                        left: 29px!important;
                        top: 25px!important;
                    }
                }
            }

            & > p {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                line-height: 22px;
                color: $gray;
                margin-bottom: 39px;
                @include desktop() {
                    font-size: 16px;
                    line-height: 26px;
                }

                small {
                    color: $dark-cream;
                }
            }

            .status-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                border-top: $border;
                border-bottom: $border;
                padding: 28px 0;
                margin-bottom: 95px;
                @include desktop() {
                    max-width: 483px;
                    padding: 22px 0 28px;
                }

                .status {
                    p {
                        font-family: 'MaisonNeue-Book', sans-serif;

                        &:first-child {
                            color: $dark-cream;
                            font-size: 10px;
                            line-height: 19px;
                            @include desktop() {
                                font-size: 12px;
                            }
                        }

                        &:nth-child(2) {
                            color: $dark-gray;
                            font-size: 14px;
                            line-height: 22px;
                            @include desktop() {
                                font-size: 16px;
                                line-height: 25px;
                            }
                        }
                    }
                }

                & > img {
                    width: 16px;
                }
            }
        }
    }

    &.email-pref {
        height: 590px;
        @include desktop() {
            height: unset;
        }

        .content-body {
            form,
            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;

                & > div {
                    @include desktop() {
                        padding: 0 273px;
                    }

                    .email-preference {
                        display: flex;

                        &:first-child {
                            margin-bottom: 40px;
                        }

                        &:nth-child(2) {
                            margin-bottom: 68px;
                            @include desktop() {
                                margin-bottom: 123px;
                            }
                        }

                        .checkbox-wrapper {
                            margin-right: 10px;
                            margin-top: 10px;
                            @include desktop() {
                                height: unset;
                                width: unset;
                                margin-right: 13px;
                                margin-top: 0;
                            }

                            & > label {
                                top: -6px;

                                @include desktop() {
                                    top: 5px;
                                }
                            }
                        }

                        .preference-info {
                            p {
                                font-family: 'MaisonNeue-Book', sans-serif;
                                color: $dark-gray;
                                text-align: left;
                                @include desktop() {
                                    min-width: 430px;
                                }

                                &:first-child {
                                    font-size: 14px;
                                    line-height: 22px;
                                    margin-bottom: 4px;
                                    @include desktop() {
                                        font-size: 16px;
                                        line-height: 25px;
                                        margin-bottom: 7px;
                                    }
                                }

                                &:nth-child(2) {
                                    font-size: 10px;
                                    line-height: 19px;
                                    @include desktop() {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.registry-url {
        height: 559px;

        .content-header {
            margin-bottom: 85px;
        }

        .content-body {
            .registry-url-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                @include desktop() {
                    width: 440px;
                    margin: auto;
                }

                & > form {
                    position: relative;
                    width: 100%;

                    .inp-primary {
                        input {
                            position: relative;
                            z-index: 5;
                            padding-right: 105px;
                            @include desktop() {
                                padding-right: 120px;
                            }
                        }

                        label {
                            z-index: 1;
                        }
                    }

                    .btn-stroke {
                        @include desktop() {
                            margin: unset;
                        }
                    }

                    .input-elements {
                        display: flex;
                        position: absolute;
                        top: 1px;
                        right: 0;
                        z-index: 1;
                        @include desktop() {
                            top: 3px;
                        }

                        p {
                            font-family: 'MaisonNeue-Book', sans-serif;
                            font-size: 10px;
                            line-height: 19px;
                            color: $dark-cream;
                            margin-right: 10px;
                            @include desktop() {
                                font-size: 12px;
                            }
                        }

                        button {
                            background-color: transparent;
                            padding: 0;
                            border: none;
                            cursor: pointer;

                            img {
                                width: 11px;
                            }
                        }
                    }
                }

                .url {
                    margin-top: 84px;
                    position: relative;
                    left: -6px;
                    @include desktop() {
                        position: unset;
                    }

                    p {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 10px;
                        line-height: 1.9;
                        letter-spacing: normal;
                        color: $dark-cream;
                        margin-bottom: 5px;
                        @include desktop() {
                            font-size: 12px;
                            line-height: 1.58;
                            margin-bottom: 11px;
                        }

                        &:nth-child(2) {
                            margin-bottom: 16px;
                        }
                    }

                    & > div {
                        display: flex;
                        align-items: center;
                        margin-bottom: 3px;
                        @include desktop() {
                            margin-bottom: 16px;
                        }

                        img {
                            width: 10px;
                            height: 15px;
                            margin-right: 12px;
                        }

                        a {
                            font-family: 'MaisonNeue-Book', sans-serif;
                            font-size: 14px;
                            line-height: 22px;
                            color: $dark-gray;
                            word-break: break-word;
                            @include desktop() {
                                font-size: 16px;
                                line-height: 25px;
                            }

                            small {
                                font-family: 'MaisonNeue-Book', sans-serif;
                                font-weight: bold;
                            }
                        }
                    }

                    .manage-reg {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 14px;
                        line-height: 22px;
                        color: $dark-gray;
                        text-decoration: underline;
                        @include desktop() {
                            font-size: 16px;
                            line-height: 25px;
                        }

                        &:hover {
                            color: $honey;
                        }
                    }
                }
            }
        }
    }

    &.cash-fund {
        height: 559px;

        .content-header {
            margin-bottom: 51px;
        }

        .content-body {
            form,
            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;

                .info-container {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    text-align: center;

                    p {
                        &:first-child {
                            font-size: 14px;
                            line-height: 22px;
                            color: $dark-gray;
                            margin-bottom: 14px;
                            padding: 0 19px;
                            @include desktop() {
                                font-size: 16px;
                                line-height: 25px;
                                margin-bottom: 27px;
                                padding: 0;
                            }
                        }

                        &:nth-child(2) {
                            margin-bottom: 40px;
                            @include desktop() {
                                margin-bottom: 36px;
                            }

                            a {
                                text-decoration: underline;
                                font-size: 12px;
                                line-height: 19px;
                                color: $dark-gray;
                            }
                        }
                    }
                }

                .cash-fund-container {
                    width: 100%;
                    max-width: 459px;
                    padding: 0 28px;
                    margin-bottom: 90px;
                    @include desktop() {
                        padding: 0;
                        margin-bottom: 58px;
                    }

                    .cash-fund-fee {
                        display: flex;

                        &:first-child {
                            padding-bottom: 34px;
                        }

                        &:nth-child(2) {
                            padding-top: 34px;
                            border-top: $border;
                        }

                        .checkbox-wrapper {
                            margin-right: 18px;

                            @include desktop() {
                                height: unset;
                                width: unset;
                                margin-right: 13px;

                                & > label {
                                    top: 2px;
                                }
                            }
                        }

                        .preference-info {
                            p {
                                color: $dark-gray;
                                text-align: left;

                                &:first-child {
                                    font-family: 'MaisonNeue-Extended-Book', sans-serif;
                                    font-size: 12px;
                                    line-height: normal;
                                    letter-spacing: 1.2px;
                                    margin-bottom: 4px;
                                    @include desktop() {
                                        font-size: 14px;
                                        letter-spacing: 1.4px;
                                    }
                                }

                                &:nth-child(2) {
                                    font-family: 'MaisonNeue-Book', sans-serif;
                                    font-size: 10px;
                                    line-height: 19px;
                                    @include desktop() {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}