header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $main-bg;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    z-index: 5;

    .support-directory &,
    .journal & {
        &:not(.state-open) {
            border-bottom: none !important;
            background-color: transparent !important;
        }
    }

    @include desktop() {
        position: absolute;
        display: block;
        min-height: 83px;
        padding: 23px 33px;
        justify-content: space-between;
        background-color: transparent;
    }

    &.main {
        background-color: $white;
        border-bottom: $border;

        .logo {
            * {
                object-fit: contain !important;
            }

            & > img {
                @include desktop(max){
                    position: relative;
                    top: 3px;
                }
            }
        }
    }

    &.transparent {
        background-color: transparent;
        border-bottom: unset;
    }

    &.bg-cream {
        background-color: $cream;
        border-bottom: unset;
    }

    &.fixed {
        position: fixed;
    }

    .close-menu,
    .back-menu,
    .header-search-content {
        display: none;
    }

    .mobile-inbox {
        position: absolute;
        right: 20px;
        top: 25px;

        img {
            width: 20px;
            height: 15px;
        }

        @include desktop() {
            display: none;
        }

        .new-notification {
            top: -2px;
            right: -2px;
        }
    }

    &.guest {
        border-bottom: unset;
        padding: 30px 32px;

        @include between(md, lg) {
            padding: 16px 20px;
        }

        @include lg() {
            padding: 16px 33px;
        }

        &.state-open {
            background-color: $white;
            border-bottom: $border;
            @include desktop() {
                border-bottom: unset;
                background-color: transparent;
            }

            nav {
                @include media-breakpoint-down(desktop) {
                    padding: 40px 0;
                }
            }
        }

        .logo {
            @include rwd-down(1024){
                max-width: 170px;
                position: absolute;
                top: 21px;
            }

            @include desktop() {
                max-width: 250px;
            }

            @include rwd(1400) {
                max-width: 325px;
            }

            & > img {
                @include desktop(max){
                    position: relative;
                    top: 5px;
                }
            }
        }
    }

    &.state-back {
        .back-menu {
            display: block;
            @include desktop() {
                display: none;
            }
        }

        .close-menu,
        .open-menu {
            display: none;
        }

        .header-search-content {
            display: block;
        }
    }

    &.state-open {
        padding: 0;

        .logo-wrapper {
            position: absolute;
            top: 20px;

            @include lg() {
                position: static;
            }
        }

        &.guest {
            nav ul li:last-child {
                @include media-breakpoint-down(desktop) {
                    margin-bottom: 35px;
                }
            }
        }

        .open-menu {
            display: none;
        }

        .close-menu {
            @include media-breakpoint-down(desktop) {
                display: block;
            }
        }

        nav {
            flex-direction: column;
            justify-content: unset;
            align-items: center;
            text-align: center;
            width: 100%;
            border-top: $border;
            padding: 40px 0 10px;
            margin-top: 58px;

            ul {
                display: block;

                li {
                    margin-bottom: 35px;
                    text-align: center;
                    padding: 0;
                    cursor: pointer;
                }
            }
        }
    }

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
        @include desktop() {
            flex-direction: row;
            min-height: 36px;
        }

        .left-side,
        .right-side {
            @include desktop(max) {
                display: none;
            }

            @include desktop() {
                display: flex;
                align-items: center;
            }

            //@media (min-width: 1024px) and (max-width: 1399.8px) {
            //    align-items: flex-end;
            //}

            @include lg() {
                width: 40%;
            }

            &:last-of-type {
                @include desktop() {
                    justify-content: flex-end;
                    align-items: center;
                }

                li {
                    @include lg() {
                        margin-right: 45px;
                    }

                    &.header-inbox {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include desktop(max) {
                            display: none;
                        }

                        .new-notification {
                            left: 15px;
                            top: 0;
                        }

                        & > span:not(.new-notification) {
                            margin: 2px 5px 2px 0!important;
                            width: 20px!important;
                            @include desktop() {
                                margin: 2px 10px 0 0!important;
                            }
                        }
                    }
                }
            }

            .sign-in-link {
                @include desktop() {
                    margin-right: 23px !important;
                }

                .btn-transparent {
                    @include between(md, lg) {
                        width: 11vw;
                    }
                }
            }

            .sign-up-link {
                @include between(md, lg) {
                    a {
                        width: 12vw;
                    }
                }

                &:hover {
                    border-bottom: transparent;
                }
            }

            li {
                cursor: pointer;

                @include between(md, lg) {
                    margin-right: 20px;
                }

                @include desktop() {
                    padding-bottom: 4px;
                    border-bottom: 1px solid transparent;

                    &:hover {
                        border-color: $dark-gray;
                    }
                }

                @include lg() {
                    margin-right: 28px;
                }

                a,
                p {
                    font-family: 'MaisonNeue-ExtendedBook', sans-serif;
                    font-size: 12px;
                    line-height: normal;
                    letter-spacing: 1.2px;
                    text-align: right;
                    color: $dark-gray;
                    text-transform: uppercase;
                    white-space: nowrap;

                    @include desktop() {
                        font-size: 14px;
                        letter-spacing: 1.4px;
                    }

                    @media (min-width: 1024px) and (max-width: 1399.8px) {
                        font-size: 1vw;
                    }
                }

                @include desktop(max) {
                    a:not(.btn-transparent), p {
                        border-bottom: none!important;
                    }
                }

                a.btn-transparent.active__state {
                    padding-bottom: 0 !important;
                }
            }

            &:last-of-type li:last-of-type {
                margin-right: 0;
            }
        }

        .left-side .header-dropdown {
            border-bottom: none !important;
            cursor: unset !important;

            @include desktop() {
                padding-bottom: 0;
            }

            & > p {
                position: relative;
                padding-right: 13px;
                border-bottom: 1px solid transparent;
                cursor: pointer;

                @include desktop() {
                    padding-bottom: 4px;
                }

                @include desktop(max) {
                    width: fit-content;
                    margin: 0 auto;
                }

                &::after {
                    content: '';
                    width: 7px;
                    height: 5px;
                    position: absolute;
                    top: 7px;
                    right: 0;
                    background: {
                        image: url('data:image/svg+xml,%3Csvg width=\'6\' height=\'4\' viewBox=\'0 0 6 4\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M1 1L3 3L5 1\' stroke=\'%23464646\'/%3E%3C/svg%3E');
                        position: center;
                        size: 100% 100%;
                        repeat: no-repeat;
                    }

                    @include desktop(max) {
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    @media (min-width: 1024px) and (max-width: 1399.8px) {
                        top: 0.3vw;
                    }
                }

                &:hover {
                    border-color: $dark-gray;
                }
            }

            & > div {
                display: none;
                flex-direction: column;
                align-items: center;
                padding-top: 19px;

                @include desktop() {
                    position: absolute;
                    align-items: flex-start;
                    padding-top: 18px;
                }

                a {
                    letter-spacing: 1.6px;
                    border-bottom: 1px solid transparent;

                    @include desktop() {
                        padding-bottom: 4px;
                    }

                    &:not(:last-child) {
                        margin-bottom: 13px;

                        @include desktop(max) {
                            margin-bottom: 10px;
                        }
                    }

                    &:hover {
                        border-color: $dark-gray;
                    }
                }
            }

            &.active {
                & > p::after {
                    transform: rotate(180deg);

                    @include desktop(max) {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }

                & > div {
                    display: flex;
                }
            }
        }

        .logo-wrapper {
            width: 100%;
            max-width: 170px;
            cursor: pointer;
            @include desktop() {
                max-width: 250px;
            }
            @include lg() {
                max-width: 325px;
            }

            img {
                width: 100%;
                max-width: 170px;
                -o-object-fit: contain;
                object-fit: contain;
                @include desktop() {
                    max-width: 250px;
                }
                @include lg() {
                    max-width: 325px;
                }
            }
        }
    }

    .header-search {
        & > span {
            margin-right: 2px!important;
            @include desktop() {
                margin-right: 5px!important;
                position: relative!important;
            }
        }

        &.active {
            padding-bottom: 4px;
            border-bottom: 1px solid $dark-gray;

        }

        &:hover {
            border-bottom: 1px solid $dark-gray;

        }
    }

    .header-account > div,
    .header-search {
        display: flex;
        align-items: center;
        justify-content: center;
        @include desktop() {
            justify-content: unset;
        }

        img {
            width: 14px;
            cursor: pointer;
        }

        a {
            padding: 0 5px;
        }
    }

    .header-account {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid transparent;
        @include desktop() {
            padding-bottom: 0 !important;
            flex-direction: row;
            position: relative;
        }

        &:hover {
            padding-bottom: 0;
            text-decoration: unset !important;
            border-bottom: 1px solid transparent !important;
        }

        .user-name {
            display: flex;
            align-items: center;

            p {
                border-bottom: 1px solid transparent;
                padding-bottom: 3px;
                transition: border-color .3s ease;

                &:hover {
                    border-color: $dark-gray;
                    color: $dark-gray;
                }
            }

            & > span {
                max-width: 20px;
                width: 12px;
                margin-bottom: 5px!important;

                &:nth-child(1) {
                    margin-right: 6px!important;
                    @include desktop() {
                        margin-right: 7px!important;
                    }
                }

                &:nth-child(3) {
                    margin-left: 6px!important;
                    @include desktop() {
                        margin-left: 7px!important;
                    }
                }
            }
        }

        .dropdown-wrapper {
            width: 123px;
            height: 0;
            text-align: center;
            transition: height 0.3s ease;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            @include desktop() {
                position: absolute;
                text-align: left;
                top: 25px;
                right: -3px;
                background-color: $white;
                z-index: 2;
                align-items: unset;
            }

            &.show {
                height: 80px;
                margin-top: 10px;
                @include desktop() {
                    height: auto;
                    border: $border;
                    padding: 12.5px;
                    margin-top: unset;
                }
            }

            .dropdown-link {
                padding: 0;
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                text-transform: unset;
                color: $dark-gray;
                line-height: 2.07;
                letter-spacing: unset;
                margin-bottom: 5px;
                text-align: center;
                @include desktop() {
                    font-size: 16px;
                    line-height: 1.56;
                    margin-bottom: 0;
                    text-align: left;
                }
            }
        }

        .arrow-btn {
            transition: transform 0.3s ease;
            display: inline-block;

            &.rotate {
                transform: rotate(-180deg);
            }
        }
    }

    .header-search-content {
        position: absolute;
        top: 58px;
        left: 0;
        width: 100%;

        @include desktop() {
            top: 74px;
        }

        @include lg() {
            top: 82px;
        }

        .search-type {
            padding: 24px 25px;
            height: 62px;
            border-top: $border;
            border-bottom: $border;
            background-color: $white;
            display: flex;
            align-items: center;
            @include desktop() {
                padding: 33px 39px 33px 33px;
                height: 83px;
            }

            img:first-child {
                width: 15px;
            }

            .inp-primary {
                input {
                    padding-left: 19px;
                    border-bottom: unset;
                    margin: 0;
                    background-color: transparent;

                    @include placeholder {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 14px;
                        line-height: 1.57;
                        letter-spacing: normal;
                        color: $dark-cream;

                        @include desktop() {
                            font-size: 16px;
                            line-height: 1.56;
                        }
                    }
                }

                & > div {
                    position: unset !important;

                    img {
                        right: unset !important;
                        left: 25px !important;
                        top: 2px !important;
                        display: block !important;
                        width: 17px !important;
                        height: 17px !important;
                        min-width: unset !important;
                        min-height: unset !important;
                        @include desktop() {
                            top: 5px !important;
                            width: 15px !important;
                            height: 15px !important;
                        }
                    }
                }
            }

            .btn-regular {
                font-size: 12px;
                letter-spacing: 1.2px;
                @include desktop() {
                    font-size: 14px;
                    letter-spacing: 1.4px;
                }
            }
        }

        .search-result {
            position: absolute;
            top: 58px;
            left: 0;
            width: 100%;
            padding: 30px 0 19px;
            border-top: $border;
            border-bottom: $border;
            background-color: $white;
            @include desktop() {
                top: 77px;
                padding: 25px 0 35px;
                border-top: unset;
            }

            &-header {
                display: flex;
                padding: 0 25px;

                button {
                    cursor: pointer;
                    font-size: 12px;
                    font-family: 'MaisonNeue-ExtendedBook', sans-serif;
                    line-height: normal;
                    margin-right: 44px;
                    letter-spacing: 1.2px;
                    text-align: left;
                    color: $dark-gray;
                    border: none;
                    border-bottom: 1px solid transparent;
                    box-sizing: border-box;
                    @include desktop() {
                        font-size: 14px;
                        margin-right: 33px;
                        letter-spacing: 1.4px;
                    }
                }
            }

            &-content {
                padding: 25px 25px 8px;
                font-family: 'MaisonNeue-Book', sans-serif;
                @include desktop() {
                    padding: 25px;
                }

                p {
                    cursor: pointer;
                    font-size: 14px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    text-align: left;
                    color: $dark-gray;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 1.56;
                        margin-bottom: 2px;
                    }
                }

                ul {
                    max-height: 80px;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 4px;

                         &-thumb {
                             background-color: $dark-cream;
                             border-radius: 2px;
                         }
                    }

                    @include desktop() {
                        max-height: 160px;
                    }

                    li {
                        text-align: left;

                        a {
                            font-family: 'MaisonNeue-Book', sans-serif;
                            font-size: 14px;
                            line-height: 1.57;
                            letter-spacing: normal;
                            text-transform: capitalize;
                            text-align: left;
                            color: $dark-gray;
                            @include desktop() {
                                font-size: 16px;
                                line-height: 1.56;
                            }
                        }
                    }
                }
            }
        }
    }

    .close-menu {
        position: absolute;
        left: 30px;
        top: 22px;
        img {
            width: 10px;
            height: 10px;
        }
    }

    .back-menu {
        position: absolute;
        top: 25px;
        left: 25px;
        img {
            width: 12px;
            height: 12px;
        }
    }

    .open-menu {
        position: absolute;
        top: 25px;
        left: 25px;
        @include desktop(max){
            top: 18px;
        }
        img {
            width: 20px;
            height: 20px;
        }
    }

    .open-menu img,
    .back-menu img,
    .close-menu img {
        -o-object-fit: contain;
        object-fit: contain;
        cursor: pointer;
        @include desktop() {
            display: none!important;
        }
    }
}