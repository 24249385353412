.registery {
    header {
        background-color: $white;
        border-bottom: $border;
    }
}

.registery-wrapper {
    .register-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 15px;
        position: relative;
        @include desktop() {
            flex-direction: row;
            margin-top: 22px;
            border-bottom: $border;
        }

        .left-side,
        .right-side {
            display: flex;

            button, a {
                border: unset;
                padding: 4px 4px 4px 0;
                margin-right: 20px;
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                line-height: 1.57;
                letter-spacing: normal;
                @include desktop() {
                    margin-right: 32px;
                    margin-bottom: 0;
                    padding: 9px 0 3px;
                    font-size: 16px;
                    line-height: 1.56;
                }
            }

            .btn-link {
                transition: .3s ease;
                border-bottom: 1px solid transparent;

                &:hover,
                &.active {
                    color: $honey;
                    border-color: $honey;
                }
            }

            .btn-wrapper {
                position: relative;
                margin-left: 10px;

                span {
                    position: absolute !important;
                    left: -25px !important;
                    top: 6px !important;
                    @include desktop() {
                        top: 12px !important;
                    }

                    img {
                        position: absolute;
                        left: -25px;
                        top: 6px;
                        @include desktop() {
                            top: 12px;
                        }
                    }
                }

                img {
                    position: absolute;
                    left: -25px;
                    top: 6px;
                    @include desktop() {
                        top: 12px;
                    }
                }
            }

            span {
                height: 15px !important;
                width: 15px !important;
                -o-object-fit: contain !important;
                object-fit: contain !important;

                img {
                    height: 15px;
                    width: 15px;
                    -o-object-fit: contain;
                    object-fit: contain;
                }
            }

            img {
                height: 15px;
                width: 15px;
                -o-object-fit: contain;
                object-fit: contain;
            }
        }

        .right-side {
            position: relative;
            left: 12px;
            @include desktop() {
                width: 100%;
                justify-content: flex-end;
                left: unset;
                right: 30px;
            }

            article {
                display: flex;
                @include desktop() {
                    display: grid;
                    grid-template-columns: 67% 38%;
                }

                .label-wrapper {
                    display: flex;
                    width: 100%;
                    @include desktop(max) {
                        flex-direction: column;
                        width: fit-content;
                        position: absolute;
                        top: 28px;
                        left: 18px;
                        z-index: 2;
                        background-color: $white;
                    }

                    &.hide {
                        @include desktop(max) {
                            display: none;
                        }
                    }
                }

                #mobile-switch {
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    margin-right: 9px;
                    @include desktop() {
                        display: none;
                    }

                    span {
                        width: 100% !important;
                        height: 100% !important;
                        object-fit: cover !important;
                    }
                }

                .btn-wrapper {
                    span {
                        margin-right: 5px !important;
                        margin-top: 3px !important;
                        @include desktop() {
                            margin-top: 2px !important;
                        }

                        img {
                            margin-right: 5px;
                            margin-top: 3px;
                            @include desktop() {
                                margin-top: 2px;
                            }

                            &.disabled {
                                opacity: 0.5;
                            }
                        }
                    }

                    .btn-link.disabled {
                        color: $gray !important;
                        border: none !important;
                        background-color: transparent !important;
                    }

                    button.disabled {
                        color: $gray;
                        opacity: 0.5;

                        &:hover {
                            border-bottom: 1px solid transparent;
                            color: $gray;
                            cursor: context-menu;
                        }
                    }

                    &:nth-child(1) {
                        margin-right: 7px;
                        @include desktop() {
                            margin-right: 0;
                        }
                    }

                    &:nth-child(2) {
                        .btn-link {
                            margin-right: 0;
                        }
                    }
                }
            }

            .view-share-registry {
                display: block;
                margin-right: 15px;
                @include desktop() {
                    display: none;
                }
            }

            .visibility {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                @include desktop() {
                    position: unset;
                }

                & > .btn-link {
                    img {
                        transform: rotate(180deg);
                    }
                }

                & > div {
                    button {
                        &:hover {
                            border-bottom: 1px solid transparent;
                            color: $dark-gray;
                        }
                    }
                }

                &.open {
                    & > .btn-link {
                        img {
                            transform: rotate(0);
                        }
                    }
                }

                &.not-visible {
                    & > .btn-link {
                        background-color: $timeless-beauty;
                    }

                    &.open {
                        .visibility-dropdown {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                &.open {
                    .visibility-dropdown {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .not-visibility-dropdown {
                    background-color: $dark-gray;
                    padding: 20px 15px;
                    left: 30px;
                    top: 40px;
                    width: 261px;
                    @include desktop() {
                        padding: 18px;
                        right: 0;
                        left: unset;
                    }

                    &.visible {
                        opacity: 1;
                        visibility: visible;
                    }

                    p {
                        position: relative;
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 14px;
                        line-height: 1.57;
                        letter-spacing: normal;
                        color: $white;
                        text-align: center;
                        @include desktop() {
                            font-size: 16px;
                            line-height: 1.56;
                            text-align: left;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: -30px;
                            left: 20px;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                            border-bottom: 10px solid $dark-gray;
                            @include desktop() {
                                left: unset;
                                right: 0;
                                top: -23px;
                            }
                        }
                    }
                }

                .visibility-dropdown {
                    background-color: $white;
                    border: $border;
                    padding: 20px 18px 7px;
                    border-radius: 10px;
                    top: 10px;
                    width: 100%;
                    @include desktop() {
                        width: auto;
                        padding: 7px 20px;
                        right: 0;
                        border-radius: unset;
                        width: 150px;
                    }

                    .btn-link {
                        padding: 3px;
                    }
                }

                .not-visibility-dropdown,
                .visibility-dropdown {
                    position: absolute;
                    z-index: 2;
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.2s opacity linear, 0.2s visibility linear;
                    @include xm() {
                        top: 47px;
                    }
                }

                & > .btn-link {
                    background: $sea-monster;
                    border-radius: 100px;
                    height: 26px;
                    color: $white;
                    font-size: 14px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    display: flex;
                    align-items: center;
                    grid-gap: 7px;
                    z-index: 3;
                    min-width: 125px;
                    justify-content: space-between;
                    padding: 5px 15px;
                    @include desktop() {
                        padding: 5px 13px;
                        font-size: 16px;
                        line-height: 1.56;
                        min-width: unset;
                    }

                    img {
                        width: 11px;
                        height: 11px;
                        -o-object-fit: contain;
                        object-fit: contain;
                    }
                }

                button {
                    margin-right: 0;
                    padding-right: 5px;
                    display: block;
                }
            }
        }

        .left-side {
            border-bottom: $border;
            margin-bottom: 20px;
            justify-content: space-between;
            @include desktop() {
                justify-content: unset;
                border: unset;
                margin-bottom: 0;
            }

            .btn-link {
                &:last-of-type {
                    margin-right: 0;
                    @include desktop() {
                        margin-right: 32px;
                    }
                }
            }
        }

        .tooltip-wrap {
            position: absolute;
            right: 0;
            display: none;

            .tooltip {
                right: 42px;
                top: 173px;
                z-index: 5;
                visibility: visible;
                opacity: 1;
                @include desktop() {
                    top: 46px;
                    right: 150px;
                }

                &::before {
                    content: '';
                    left: unset;
                    right: 227px;
                    top: -10px;
                    transform: rotate(90deg);
                    @include desktop() {
                        right: 30px;
                    }
                }
            }

            &.not-visible {
                display: block;
                @include desktop() {
                    right: -149px; //Unset if "Visible/Not Visible" button comes back
                }
            }

            &.visible {
                display: block;
                @include desktop() {
                    right: -124px; //Unset if "Visible/Not Visible" button comes back
                }

                .tooltip {
                    right: -8px;
                    top: 15px;
                    width: 200px;
                    @include desktop() {
                        right: 125px;
                        top: 13px;
                    }

                    &::before {
                        right: 95px;
                        @include desktop() {
                            right: 35px;
                        }
                    }
                }
            }
        }
    }

    section.registered-value-wrapper {
        background-color: $main-bg;
        border-radius: 10px;
        margin: 15px 0 25px;
        padding: 9px 0 2px;
        position: relative;

        @include desktop() {
            padding: 44px 0 23px;
            margin: 35px 0 37px;
        }

        .before-bank-linked {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 24px 20px;
            @include desktop() {
                padding: 18px;
            }

            & > div {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 8px;
                @include desktop() {
                    margin-bottom: 16px;
                }

                & > p {
                    font-family: 'OrpheusPro', sans-serif;
                    font-size: 24px;
                    line-height: 1.25;
                    letter-spacing: normal;
                    color: $gray;
                    margin-left: 7px;
                    @include desktop() {
                        font-size: 30px;
                        line-height: 1.27;
                    }

                    span {
                        @include desktop(max) {
                            display: none;
                        }
                    }
                }

                .bank-img {
                    width: 20px;
                }

                .btn-stroke, .btn-fill {
                    width: 209px;
                    @include desktop() {
                        width: 229px;
                        margin-left: 17px;
                    }
                }
            }

            & > p {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 12px;
                line-height: 1.58;
                letter-spacing: normal;
                color: $gray;
                max-width: 490px;
                margin-bottom: 18px;
                text-align: center;
                @include desktop() {
                    font-size: 16px;
                    line-height: 1.56;
                    margin-bottom: 24px;
                }
            }

            .connect-new-bank {
                display: flex;
                font-family: MaisonNeue-Extended-Book, sans-serif;
                & > div {
                    margin-left: 8px;
                }
            }
        }

        .added-registry {
            position: relative;
            top: -8px;
            height: 53px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            display: flex;
            @include desktop() {
                position: absolute;
                top: -35px;
                height: 44px;
            }
        }

        .top-section {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-bottom: 10px;

            @include desktop() {
                position: relative;
                align-items: flex-start;
                flex-direction: row;
                margin: 0 auto 20px;
                width: 400px;
            }

            p {
                order: 2;
                @include desktop() {
                    order: 1;
                }
            }

            span {
                order: 1;
                padding-top: 13px;
                @include desktop() {
                    padding-top: 0;
                    margin-bottom: 10px;
                    order: 2;
                    position: relative;
                    top: -18px;
                    right: 0;
                }
            }
        }

        .bottom-section {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        .top-section,
        .bottom-section {
            img {
                width: 17px;
                height: 17px;
                -o-object-fit: contain;
                object-fit: contain;
                margin-left: 7px;
            }

            .tooltip-wrap {
                span {
                    width: 17px !important;
                    height: 17px !important;
                    -o-object-fit: contain;
                    object-fit: contain;
                    margin-left: 7px !important;
                    top: auto !important;
                }
            }

            p {
                font-family: 'MaisonNeue-Extended-Light', sans-serif;
                font-size: 50px;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: $honey;
                @include desktop() {
                    font-size: 85px;
                    line-height: 0.59;
                }
            }

            span {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                line-height: 1.57;
                letter-spacing: normal;
                color: $dark-gray;
                display: flex;
                align-items: center;
                @include desktop() {
                    font-size: 16px;
                    line-height: 1.56;
                }

                span {
                    color: $honey;
                    margin: 0 5px;
                }
            }
        }
    }

    section.registered-funds-wrapper {

        .registered-funds-header {
            margin: 20px 0 25px;
            @include desktop() {
                margin: 32px 0;
            }

            .service-funds {
                border-top: $border;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding-top: 28px;
                margin: 0 18px;
                @include desktop() {
                    flex-direction: row;
                    padding-top: 34px;
                    margin: 0 35px;
                }
            }

            .tooltip-wrap {
                & > span {
                    margin-left: 9px !important;
                }
            }

            img {
                width: 17px;
                height: 17px;
                -o-object-fit: contain;
                object-fit: contain;
                margin-left: 9px;
            }

            p,
            span {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                line-height: 1.57;
                letter-spacing: normal;
                color: $gray;
                @include desktop() {
                    font-size: 16px;
                    line-height: 1.56;
                }
            }

            span {
                color: $middlestone;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                margin-bottom: 16px;
                @include desktop() {
                    align-items: center;
                    justify-content: unset;
                    margin-bottom: 0;
                }

                span {
                    margin-bottom: 0;
                }
            }

            .btn-stroke,
            .btn-fill {
                width: 209px;
            }
        }

        .registered-funds-content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 47px;
            padding: 0 18px;

            &.registered-funds-content-list {
                @include desktop() {
                    margin-left: 33px;
                    margin-right: 33px;
                    justify-content: unset;
                }
            }

            @include desktop() {
                display: grid;
                margin-bottom: 80px;
                padding: 0;
                grid-template-columns: repeat(auto-fit, 435px);
                grid-gap: 36px;
            }
            @include desktop(max) {
                flex-direction: column;
            }

            @media (min-width: 1024px) and (max-width: 1439.8px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width: 1550px) {
                grid-template-columns: repeat(4, 1fr);
            }

            & > div, & > a {
                @include desktop(max) {
                    &:not(:last-child) {
                        margin-bottom: 18px;
                    }
                }
            }

            & > div {
                &.fund-col {
                    background: $main-bg;
                    text-align: center;
                    border-radius: 10px;
                    width: 100%;
                    position: relative;
                    overflow: hidden;
                    min-height: 334px;
                    @include desktop() {
                        width: 435px;
                        min-height: 568px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                    }

                    @media (min-width: 1024px) and (max-width: 1279.8px) {
                        min-height: 579px;
                    }

                    @media (min-width: 1024px) and (max-width: 1439.8px) {
                        width: 100%;
                    }

                    @media (min-width: 1550px) {
                        width: 100%;
                    }

                    .my-registry-img {
                        width: 100% !important;
                        height: 100% !important;
                        overflow: hidden;
                        @include desktop() {
                            max-height: 253px;
                        }

                        span {
                            width: 100% !important;
                            @include desktop() {
                                min-height: 257px;
                            }
                        }

                        img {
                            height: 100% !important;
                            width: 100% !important;
                            object-fit: cover;
                            -o-object-fit: cover;
                        }

                        &.color-img {
                            width: 100%;
                            min-height: 197px;
                            background-color: $cream;
                            @include desktop() {
                                min-height: 253px;
                            }
                        }
                    }

                    .my-registry-info {
                        padding: 16px 10px 0;

                        @include desktop() {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            padding: 31px 36px;
                        }

                        @media (min-width: 1024px) and (max-width: 1439.8px) {
                            padding-left: 12px !important;
                            padding-right: 12px !important;
                        }

                        @include rwd(1550) {
                            padding: 31px 30px;
                        }

                        & > h2 {
                            @include desktop(max) {
                                margin-bottom: 10px;
                            }
                        }

                        h3 {
                            font-family: 'MaisonNeue-Extended-Book', sans-serif;
                            font-size: 20px;
                            color: $middlestone;
                            margin-bottom: 25px;
                            @include desktop() {
                                font-size: 26px;
                            }
                        }

                        & > .btn-none {
                            display: none;
                        }

                        .btn-wrapper {
                            margin-bottom: 14px;
                            @include desktop() {
                                margin-bottom: 0;
                                margin-top: 19px;
                            }
                            .quantity{
                                @include desktop(){
                                    margin-right: 25px;
                                }
                            }
                        }

                        .service-item-cost {
                            font-family: "MaisonNeue-Extended-Book", sans-serif;
                            color: $middlestone;
                            font-size: 26px;
                            margin-bottom: 20px;
                            @include desktop(max) {
                                font-size: 20px;
                                margin-bottom: 26px;
                            }
                        }

                        .my-register-description-wrapper {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            margin-bottom: 22px;
                            @include desktop() {
                                justify-content: center;
                                margin-bottom: 25px;
                            }
                            @include desktop(max) {
                                padding: 0 14px;
                            }

                            button {
                                color: $dark-gray;
                                text-decoration: underline;
                                padding-left: 3px;
                                font-family: "MaisonNeue-Book", sans-serif;
                                font-size: 14px;
                                line-height: 22px;
                                @include desktop() {
                                    font-size: 16px;
                                    line-height: 25px;
                                    margin-top: -2px;
                                    margin-bottom: 3px;
                                    white-space: pre;
                                }

                                &.registry-more {
                                    margin-bottom: 0 !important;
                                }
                            }

                            & > div {
                                display: flex;
                            }

                            &.preview-description {
                                display: flex;
                                flex-direction: column;

                                p {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 4;
                                    -webkit-box-orient: vertical;

                                    @include desktop() {
                                        -webkit-line-clamp: 2;
                                    }
                                }
                            }
                        }

                        .info {
                            @include desktop() {
                                margin-bottom: 26px;
                            }
                        }
                    }

                    & > button.btn-none {
                        display: none;
                    }

                    .new-label-wrapper {
                        position: absolute;
                        right: 32px;
                        top: 26px;
                        z-index: 1;
                        @include desktop(max) {
                            right: 23px;
                            top: 18px;
                        }

                        .tooltip-left {
                            @media(min-width: 1024px) and (max-width: 1199.8px) {
                                width: 27vw;
                            }
                        }

                        .new-label {
                            display: flex;
                            align-items: center;

                            p {
                                font-size: 14px !important;
                                letter-spacing: 0 !important;
                                @include desktop() {
                                    font-size: 16px !important;
                                    margin-right: 10px !important;
                                }
                                @include desktop(max) {
                                    position: relative !important;
                                    top: 1px !important;
                                    line-height: 23px !important;
                                }
                            }
                        }
                    }

                    &.active {
                        position: relative;
                        @include desktop(max) {
                            min-height: 411px;
                            padding-top: 38px;
                        }

                        button.btn-none {
                            display: block;
                            width: 27px;
                            position: absolute;
                            top: 19px;
                            right: 19px;
                            @include desktop() {
                                top: 23px;
                                right: 26px;
                            }
                        }

                        .service-item-cost {
                            display: none;
                        }

                        .my-registry-img {
                            display: none;
                        }

                        .my-registry-info {
                            height: 100%;
                            @include desktop() {
                                min-height: 564px;
                                padding-top: 46px;
                            }

                            .btn-none {
                                display: block;
                            }
                        }

                        .my-register-description-wrapper {
                            .btn-none {
                                display: none;
                            }
                        }

                        .my-registry-description {
                            white-space: unset;
                            width: 100%;
                            text-overflow: unset;
                        }

                        .description {
                            overflow: auto !important;
                            height: 269px;
                            display: block !important;
                            @include desktop() {
                                height: 318px;
                            }
                            @media (min-width: 1024px) and (max-width: 1279.8px) {
                                height: 388px;
                            }

                            & > span {
                                overflow: initial;
                                text-overflow: initial;
                                display: initial;
                                -webkit-line-clamp: initial;
                                -webkit-box-orient: initial;
                            }

                            &::-webkit-scrollbar {
                                width: 4px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: $dark-cream;
                                border-radius: 2px;
                            }

                            button {
                                display: none;
                            }
                        }

                        .btn-wrapper, .info {
                            display: none;
                        }

                        .registry-preview-btn {
                            display: flex !important;

                            .btn-gold {
                                width: 186px !important;
                                position: static !important;
                                @include desktop(max) {
                                    width: 178px !important;
                                }
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 17px;
                        @include desktop() {
                            margin-bottom: 0;
                        }
                    }
                }

                h2 {
                    font-family: 'OrpheusPro', sans-serif;
                    font-size: 24px;
                    line-height: 1.25;
                    letter-spacing: normal;
                    color: $gray;
                    margin-bottom: 20px;
                    @include desktop() {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        font-size: 30px;
                        line-height: 1.27;
                        min-height: 98px;
                        margin-bottom: 0;
                    }
                }

                button:nth-child(3) {
                    display: none;
                }

                .description {
                    &.my-registry-description {
                        height: unset;
                        white-space: nowrap;
                        width: 15vw;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        @include desktop(max) {
                            width: 57vw;
                            margin: 0 auto;
                        }
                    }

                    & > button {
                        display: block;
                        margin: 0 auto;
                    }

                    white-space: pre-line;
                    font-family: "MaisonNeue-Book", sans-serif;
                    font-size: 14px;
                    line-height: 22px;
                    height: 85px;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 25px;
                        height: 54px;
                    }

                    button {
                        color: $dark-gray;
                        text-decoration: underline;
                        padding-left: 3px;
                        font-family: "MaisonNeue-Book", sans-serif;
                        font-size: 14px;
                        line-height: 22px;
                        @include desktop() {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }

                .info {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, 121px);
                    align-items: center;
                    justify-content: center;
                    @include desktop(max) {
                        grid-template-columns: repeat(auto-fit, 104px);
                    }

                    @include rwd(1550) {
                        grid-template-columns: repeat(auto-fit, 33%);
                    }

                    @media (min-width: 1024px) and (max-width: 1279.8px){
                        grid-template-columns: repeat(auto-fit, 33%);
                    }

                    p {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 14px;
                        line-height: 1.57;
                        letter-spacing: normal;
                        color: $dark-gray;
                        text-align: center;
                        position: relative;
                        margin-bottom: 0;
                        @include desktop() {
                            font-size: 16px;
                            line-height: 1.56;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            width: 1px;
                            height: 40px;
                            background-color: $light-gray;
                        }

                        &:last-of-type {
                            padding-left: 10px;
                            @include desktop() {
                                padding-left: 0;
                            }

                            &:after {
                                content: unset;
                            }
                        }
                    }
                }

                .btn-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 37px;
                    margin-top: 25px;
                    @include desktop() {
                        margin-bottom: 61px;
                        margin-top: 40px;
                    }

                    &.registry-preview-btn {
                        justify-content: space-around;
                        @include desktop(max) {
                            justify-content: space-between;
                            max-width: 319px;
                            margin-left: auto;
                            margin-right: auto;
                        }

                        & > button {
                            width: 178px;
                            @include desktop() {
                                width: 186px;
                            }
                            @media (min-width: 1024px) and (max-width: 1279.8px) {
                                width: 50%;
                            }
                        }

                        .quantity {
                            //display: flex;
                            //align-items: center;
                            //justify-content: space-between;
                            //background-color: $white;
                            //border: 1px solid $gray;
                            //border-radius: 10px;
                            //padding: 10px 8px;
                            //@include desktop() {
                            //    min-width: 131px;
                            //    //padding: 12px 9px;
                            //}
                            //@include desktop(max) {
                            //    width: 118px;
                            //}

                            position: relative;

                            input {
                                text-align: center;
                            }

                            button {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                z-index: 2;
                                left: 10px;

                                &:last-child {
                                    left: unset;
                                    right: 10px;
                                }
                            }
                        }

                        .inp-primary {
                            width: 131px;
                            border: 1px solid $gray;
                            border-radius: 10px;
                            background-color: $white;
                            @include desktop(max) {
                                width: 124px;
                                height: 50px;
                            }

                            input {
                                margin-bottom: 0;
                                border-bottom: none;
                                padding: 11px;
                            }
                        }

                        &.inputs {
                            @include desktop() {
                                margin-top: 52px;
                            }
                        }

                        p {
                            font-family: 'MaisonNeue-Book', sans-serif;
                            font-size: 16px;
                            color: $dark-gray;
                        }
                    }

                    .btn-link {
                        text-transform: uppercase;
                    }

                    .btn-transparent {
                        width: 178px;
                        text-transform: uppercase;
                        transition: unset;
                        @include desktop() {
                            width: 186px;
                        }

                        &:hover {
                            background-color: $black;
                            color: $white;
                        }
                    }
                }
            }

            .add {
                &-fund {
                    width: 100%;
                    min-height: 334px;
                    background-color: transparent;
                    border: 1px solid $light-gray;
                    text-align: center;
                    padding: 36px 13px 22px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    &:hover {
                        background-color: initial !important;
                    }

                    @include desktop() {
                        padding: 41px 27px;
                        width: 435px;
                        min-height: 564px;
                        height: 100%;
                    }

                    @include desktop(max) {
                        margin-bottom: 18px;
                    }

                    @media (min-width: 1024px) and (max-width: 1279.8px) {
                        min-height: 579px;
                    }

                    @media (min-width: 1024px) and (max-width: 1439.8px) {
                        width: 100%;
                    }
                    @media (min-width: 1550px) {
                        width: 100%;
                    }
                }

                &-icon {
                    display: inline-block;
                    width: 56px;
                    height: 56px;
                    border: 1px solid $light-gray;
                    border-radius: 50%;
                    position: relative;
                    margin-bottom: 24px;
                    @include desktop() {
                        width: 86px;
                        height: 86px;
                        margin-bottom: 40px;
                    }

                    &::before, &::after {
                        content: '';
                        width: 1px;
                        height: 28px;
                        background-color: $light-gray;
                        position: absolute;
                        top: 50%;
                        @include desktop() {
                            height: 43px;
                        }
                    }

                    &::before {
                        transform: translateY(-50%);
                    }

                    &::after {
                        transform: translateY(-50%) rotate(90deg);
                    }
                }

                &-text {
                    font-family: "MaisonNeue-Extended-Book", sans-serif;
                    font-size: 12px;
                    letter-spacing: 1.2px;
                    color: $light-gray;
                    position: relative;
                    @include desktop() {
                        font-size: 14px;
                        letter-spacing: 1.4px;
                    }

                    &:after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: $light-gray;
                        position: absolute;
                        left: 0;
                        bottom: -3px;
                        @include desktop() {
                            bottom: -4px;
                        }
                    }
                }
            }
        }
    }

    .register-header, section.registered-value-wrapper {
        margin-left: 18px;
        margin-right: 18px;
        @include desktop() {
            margin-left: 32px;
            margin-right: 32px;
        }
    }

    .contributors {
        .contributor-popup {
            border: none;
            border-radius: 0;

            .contributor-popup-content {
                & > div {
                    padding: 14px 25px;
                    @include desktop() {
                        padding: 0 50px;
                    }

                    a {
                        & > span {
                            width: 20px;
                            margin-right: 5px !important;
                        }
                    }
                }
            }

            .message-box {
                padding: 14px 10px;
                min-height: 120px;
                @include desktop() {
                    display: grid;
                    align-items: flex-start;
                    justify-content: unset;
                    padding: 35px 50px 30px;
                    min-height: 139px;
                }

                & > div {
                    height: unset;

                    span {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 14px;
                        line-height: 1.57;
                        letter-spacing: normal;
                        color: $dark-cream;
                        display: block;
                        @include desktop() {
                            font-size: 16px;
                            line-height: 1.56;
                        }

                        &:nth-child(2) {
                            color: $dark-gray;
                        }
                    }
                }
            }
        }
    }
}
