.blog-inner {
    &-post {
        background-color: #fafafa;
    }

    &-hero {
        position: relative;

        @include desktop(max) {
            display: grid;
        }

        @include desktop() {
            height: 745px;
        }

        .blog-hero {
            &-img {
                height: 100%;
                min-height: 100%;

                span, img {
                    width: 100% !important;
                    height: 100% !important;
                }

                img {
                    object-position: top;
                    object-fit: cover !important;
                }

                &.show-mob {
                    @include desktop(max) {
                        height: 425px;
                    }
                }
            }

            &-content {
                position: relative;
                padding: 25px;
                background-color: $white;
                min-height: 385px;

                @include desktop() {
                    width: 50%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 50px 55px 40px;
                    min-height: 485px;
                }

                & > div:first-child {
                    span {
                        font-family: $font-family-tertiary;
                        font-size: 16px;
                        line-height: 1.88;
                        letter-spacing: 1.6px;
                        text-align: left;
                        color: #909090;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        display: inline-block;
                    }

                    h1 {
                        font-family: $font-family-secondary;
                        font-weight: normal;
                        font-size: 32px;
                        line-height: 1.23;
                        letter-spacing: 1.3px;
                        color: $gray;
                        margin-bottom: 40px;

                        @include desktop() {
                            max-width: 495px;
                            margin-bottom: clamp(2vw, 125px, 5vw);
                            font-size: clamp(1.5vw, 52px, 3.2vw);
                        }
                    }
                }
            }

            &-footer {
                display: grid;
                color: $gray;
                font-size: 14px;
                letter-spacing: 0.35px;

                @include desktop() {
                    position: absolute;
                    width: 88%;
                    left: 40px;
                    bottom: 40px;
                    right: 40px;
                    align-items: end;
                    grid-template-columns: repeat(2, 1fr);

                }

                p {
                    @include desktop(max) {
                        margin-bottom: 6px;
                    }
                }

                .handwritten-text {
                    font-size: 45px;
                    line-height: 1.6;
                    letter-spacing: 0.9px;
                    color: $gray;

                    @include desktop() {
                        white-space: nowrap;
                    }
                }

                div {
                    justify-self: end;

                    p {
                        text-align: left;

                        @include desktop() {
                            text-align: right;
                        }
                    }
                }
            }

            &-socials {
                position: absolute;
                top: 25px;
                right: 25px;

                @include desktop() {
                    top: 40px;
                    right: 55px;
                }

                .social-trigger {
                    width: 27px;
                    height: 27px;
                    cursor: pointer;
                    border-radius: 50%;
                    background-color: $main-bg;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 1;

                    span {
                        display: inline-block;
                        margin-top: -7px;
                    }
                }

                .socials {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 25px;
                    padding: 25px 4px 6px 4px;
                    position: absolute;
                    margin-top: -25px;
                    background-color: $main-bg;

                    a {
                        margin: 8px 0;
                    }
                }
            }
        }
    }

    &-content {
        padding: 60px 0;

        @include desktop() {
            padding: 97px 0;
        }

        article {
            max-width: 970px;
            margin-inline: auto;

            p {
                font-size: 14px;
                //padding-top: 5px;
                line-height: 2;
                letter-spacing: normal;
                color: $gray;
                margin-bottom: 30px;

                a {
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .has-small-font-size {
                font-size: 13px;
                line-height: 1.71;
                margin-bottom: 30px;
                letter-spacing: 0;

                @include desktop() {
                    letter-spacing: 0.3px;
                }
            }

            h4 {
                font-family: $font-family-secondary;
                font-size: 26px;
                line-height: 1.37;
                letter-spacing: 0.95px;
                text-align: left;
                color: $gray;
                margin-bottom: 20px;

                @include desktop() {
                    font-size: 38px;
                }
            }


            h5 {
                font-family: $font-family-tertiary;
                font-size: 22px;
                font-weight: normal;
                line-height: 1.36;
                letter-spacing: 2.2px;
                text-align: left;
                color: $gray;
            }
        }
    }

    &-topic {
        font-size: 14px;
        line-height: 1.71;
        color: $gray;

        .single_column {
            max-width: 700px;
            margin-inline: auto;

            @include desktop(max) {
                padding: 0 25px;
            }

            &:has(> figure.wp-block-pullquote) {
                .wp-block-group__inner-container h2 {
                    font-family: $font-family-tertiary;
                    font-size: 16px;
                    letter-spacing: 0.2px;

                    @include desktop() {
                        width: 409px;
                        font-size: 18px;
                        color: $gray;
                    }
                }
            }

            h1 {
                font-size: 15px;
                line-height: 1.4;
                letter-spacing: 0.4px;

                @include desktop() {
                    font-size: 18.75px;
                }
            }

            a:not(:hover) {
                text-decoration: underline;
            }

            h5 {
                font-size: 22px;
                line-height: 1.36;
                letter-spacing: 2.2px;
                color: $gray;
                margin-bottom: 16px;
                text-transform: uppercase;
            }

            h4 {
                line-height: 1.37;
            }
        }

        .wp-block-group {
            max-width: 684px;
            margin-inline: auto;

            @include desktop(max) {
                padding: 0 30px;
            }
        }

        p {
            padding-top: 0 !important;
        }

        hr {
            margin: 43px 0;

            @include desktop() {
                margin: 60px -135px;
            }
        }
    }

    &-separator {
        width: 100%;
        margin: 60px 0;
        border: 1px solid #d7d7d7;
        max-width: inherit;
    }

    &-interview {
        max-width: 950px;
        margin-inline: auto;
        padding: 40px 27px 0;

        @include desktop() {
            padding: 78px 0 0;
        }

        ul {
            max-width: 640px;
            margin-inline: auto;

            li {
                margin-bottom: 10px;
                padding-bottom: 10px;

                & > div {
                    display: grid;
                    align-items: flex-start;
                    grid-template-columns: 100px 1fr;

                    &.question {
                        padding-bottom: 35px;
                    }

                    &.answer {
                        padding-bottom: 45px;

                        p {
                            margin-bottom: 0 !important;
                        }
                    }

                    h3 {
                        font-family: $font-family-secondary;
                        font-size: 26px;
                        color: $middlestone;
                        margin-right: 40px;
                        text-align: right;
                    }

                    p {
                        max-width: 500px;
                        line-height: 1.6;
                        margin-bottom: 30px;
                        font-size: 14px;
                        letter-spacing: 0;
                        color: $gray;

                        @include desktop() {
                            line-height: 2;
                            letter-spacing: 0.25px;
                        }
                    }
                }
            }
        }
    }

    &-info {
        padding-top: 30px;
        padding-bottom: 140px;
        border-top: 2px solid #d7d7d7;

        @include desktop() {
            padding-top: 50px;
        }

        & > div {
            max-width: 680px;
            margin-inline: auto;

            @include desktop(max) {
                padding: 0 30px;
            }
        }

        a:not(:hover) {
            text-decoration: underline;
        }

        p {
            margin-bottom: 10px !important;
        }
    }

    &-share {
        width: 280px;
        margin: 80px auto 10px;

        @include desktop(max) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        @include tablet() {
            margin: 20px 60px 0;
            width: unset;
        }

        @include desktop() {
            margin: 80px 110px 0;
        }

        p {
            margin-bottom: 18px;
            letter-spacing: 0.4px;
            line-height: 1.69;
        }

        div {
            display: flex;

            @include desktop(max) {
                margin-left: 25px;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 28px;
            }
        }
    }

    &-related {
        background-color: $cream;
        padding: 75px 25px 60px;

        @include desktop() {
            padding: 75px 0;
        }

        .related {
            &-stories {
                display: flex;
                align-items: center;
                margin-bottom: 65px;

                span {
                    font-family: $font-family-primary;
                    font-size: 14px;
                    line-height: 1.71;
                    color: $gray;
                    margin-right: 18px;
                    display: inline-block;
                    letter-spacing: normal;
                }
            }

            &-container {
                @include desktop() {
                    margin: 0 110px 0;
                }
            }
        }
    }
}

.has-small-font-size,
.has-normal-font-size,
.has-medium-font-size {
    line-height: 1.71 !important;
    letter-spacing: 0.4px !important;
}

.has-small-font-size {
    font-size: 13px !important;
}

.has-normal-font-size {
    font-size: 16px !important;
}

.has-medium-font-size {
    font-size: 20px !important;
}

.wp-block-image  {
    margin-bottom: 35px;

    img {
        height: 100%;
    }
}

.wp-block-pullquote {
    max-width: 640px;
    margin-inline: auto;
    margin-bottom: 10px;
    padding-bottom: 10px;

    &:first-of-type {
        margin-top: 25px;
    }

    blockquote {
        display: grid;
        align-items: start;
        grid-template-columns: 100px 1fr;

        & > p {
            font-family: $font-family-secondary;
            font-size: 26px;
            color: $middlestone;
            margin-right: 40px;
            text-align: right;
            line-height: normal;

            @include desktop() {
                margin-right: 50px;
            }
        }

        & > cite {
            max-width: 500px;
            line-height: 1.6;
            margin-bottom: 30px;
            font-size: 14px;
            letter-spacing: 0;
            color: $gray;

            @include desktop() {
                letter-spacing: 0.25px;
            }
        }
    }
}