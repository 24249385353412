.count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: $bodyFont;
    width: 39px;
    height: 39px;
    border: 1px solid $honey;
    border-radius: 50%;
}

.howitwork {
    padding-top: 0;
}

.wellcome-hero {
    margin-top: -60px;
    padding: 107px 0 28px;
    background-color: $main-bg;
    font-family: $bodyFont;

    @include desktop {
        padding: 150px 0 96px;
        margin-top: -80px;
    }

    &__title {
        @include rwd-down(1024) {
            font-size: 36px;
            line-height: 42px;
        }
    }

    &__grid {
        display: grid;

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 60px;
            align-items: center;
        }
    }

    &__content {
        text-align: center;

        @include rwd-down(1024) {
            margin-bottom: 54px;
        }

        @include desktop {
            max-width: 503px;
            text-align: left;
        }
    }

    &__desc {
        margin-top: 36px;
        line-height: 25px;

        @include rwd-down(1024) {
            font-size: 14px;
            line-height: 22px;
            margin-top: 24px;
        }
    }

    &__link {
        width: 100%;
        max-width: 258px;
        margin: 35px auto 0;

        @include desktop() {
            margin: 50px 0 0;
            max-width: 260px;
        }

    }
}

.steps {
    font-family: $bodyFont;
    line-height: 25px;
    padding: 48px 0 60px;

    @include desktop {
        padding: 38px 0 96px;
    }

    &__header {
        padding: 0 45px 60px;
        text-align: center;

        @include desktop {
            padding: 0 0 55px;
        }

        a:not(.btn-link) {
            @extend .btn-link;
            border-bottom: 0;
        }

    }

    &__menu {
        @include rwd-down(1024) {
            display: none;
        }

        &--open {
            display: block;
        }

        @include rwd-down(1024) {
            margin-top: -1px;
            text-align: left;
            border: 1px solid $dark-gray;
        }

        li {
            display: block;
            cursor: pointer;

            @include desktop {
                display: inline-block;
                margin: 0 28px;
            }

            a {
                text-transform: uppercase;

                @include rwd-down(1024) {
                    display: block;
                    text-decoration: none;
                    border-bottom: 0;
                    padding: 10px 10px !important;
                }
            }
        }

    }

    &__label {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 6px;
        font-size: 12px;
        line-height: 1.2;
        letter-spacing: 1.2px;
        color: $dark-gray;
        font-family: "MaisonNeue-Extended-Book", sans-serif;
        border-bottom: 1px solid $dark-gray;
        text-align: left;
        width: 100%;

        @include desktop {
            display: none;
        }

        span {
            @include rwd-down(1024) {
                text-transform: uppercase;
            }
        }

        button {
            position: absolute;
            right: 0;
            bottom: 6px;
            padding: 0;
            border: 0;
            background-color: transparent;
        }

        &-arrow {
            width: 10px;
        }
    }

    .swiper-scrollbar {
        height: 1px;
        background-color: $light-gray;

        &-drag {
            background-color: $dark-gray;
        }
    }
}

.step {
    display: grid;
    padding-bottom: 50px;

    @include desktop {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 70px;
        align-items: center;
        padding-bottom: 0;
    }

    &:not(:last-child) {
        margin-bottom: 16px;
    }



    &__image {
        img {
            display: block;
        }
    }

    &__content {
        margin-top: 32px;

        @include desktop {
            margin-top: 0;
            padding-left: 12px;
        }
    }

    &__title {
        margin-top: 14px;

        @include rwd-down(1024) {
            margin-top: 31px;
            font-size: 24px;
            line-height: 30px;
        }
    }

    &__desc {
        margin-top: 16px;
        line-height: 24px;

        @include rwd-down(1024) {
            margin-top: 14px;
            font-size: 14px;
            line-height: 22px;
        }
    }

    a {
        margin-top: 22px;
        display: inline-block;


        // @include desktop() {
        //     margin-top: 0;
        // }

        .search-registries {
            font-family: 'MaisonNeue-Extended-Book', sans-serif;
            font-size: 12px;
            line-height: normal;
            letter-spacing: 1.2px;
            color: $dark-gray;
            border-bottom: 1px solid $dark-gray;

            @include desktop() {
                font-size: 14px;
                letter-spacing: 1.4px;
            }

            &:hover {
                color: $honey;
                border-bottom: 1px solid $honey;
            }
        }
    }
}

.wellness {
    padding: 58px 0 64px;
    background-color: $cream;
    font-family: $bodyFont;

    @include desktop {
        padding: 92px 0 99px;
    }

    &__header {
        @include desktop {
            text-align: center;
        }

        h2 {
            @include rwd-down(1024) {
                font-size: 24px;
                line-height: 30px;
            }
        }

        .btn-link {
            display: inline-block;
            margin-top: 28px;
        }
    }

    &__content {
        margin-top: 48px;

        @include rwd(950) {
            margin-top: 79px;
            display: flex;
            justify-content: space-between;
        }
    }

    &__list {
        line-height: 33px;

        &-item {
            position: relative;
            padding-left: 16px;

            @include desktop {
                padding-left: 20px;
            }

            @include rwd-down(950) {
                font-size: 14px;
                line-height: 27px;
            }

            &::after {
                position: absolute;
                top: 15px;
                left: 0;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $dark-gray;
                content: '';
            }
        }
    }
}

.featured-post {
    padding: 60px 0 19px;
    background-color: $honey;
    font-family: $bodyFont;
    line-height: 25px;

    @include desktop {
        padding: 92px 0 96px;
    }

    &__grid {
        display: grid;

        @include desktop {
            grid-template-columns: 1fr minmax(auto, 530px);
            grid-gap: 60px;
            align-items: center;
            justify-content: space-between;
        }
    }

    .h1 {
        @include rwd-down(1024) {
            font-size: 36px;
            line-height: 42px;
        }
    }

    &__subtitle {
        margin-bottom: 10px;

        @include rwd-down(1024) {
            font-size: 14px;
        }
    }

    &__content {
        cursor: pointer;
        color: $dark-gray;
        @include rwd-down(1024) {
            margin-bottom: 60px;
        }
    }

    &__desc {
        max-width: 390px;
        margin-top: 28px;

        @include rwd-down(1024) {
            font-size: 14px;
            line-height: 22px;
            margin-top: 21px;
        }
    }

    &__link {
        display: inline-block;
        margin-top: 34px;

        &:hover {
            color: $cream;
            border-color: $cream;
        }
    }

    &__image {
        img {
            display: block;
        }
    }
}


.app.how-it-works {
    .container {
        max-width: 1280px;
        padding: 0 25px;
    }
}