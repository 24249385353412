.faq {

    &--howitwork {
        background-color: $light-cream;
    }

    &__inner {
        max-width: 800px;
        margin: 0 auto;
        padding: 40px 25px 118px;

        @include desktop {
            padding: 77px 25px 150px;
        }
    }

    &__title {
        margin-bottom: 46px;
        font-size: 36px;
        line-height: 42px;
        font-family: $headingFont;
        text-align: center;
        letter-spacing: .8px;

        @include tablet {
            margin-bottom: 78px;
            font-size: 52px;
            line-height: 61px;
        }
    }

    &__items {
        border-top: 1px solid $light-gray;
    }

    &-acc {
        border-bottom: 1px solid $light-gray;

        &__head {
            position: relative;
            padding: 23px 66px 18px 0;
            cursor: pointer;

            @include desktop {
                padding: 27px 66px 27px 0;
            }

            p {
                font-size: 12px;
                font-family: "MaisonNeue-Extended-Book";
                line-height: normal;
                letter-spacing: 2px;
                text-transform: uppercase;

                @include tablet {
                    font-size: 14px;
                    letter-spacing: 2.4px;
                }
            }
        }

        &__body {
            display: grid;
            grid-template-rows: 0fr;
            font-size: 12px;
            font-family: $bodyFont;
            line-height: 1.5;
            white-space: break-spaces;
            transition: .4s ease;

            @include tablet {
                font-size: 16px;
            }

            span {
                overflow: hidden;

                a {
                    color: $dark-gray;
                    border-bottom: 1px solid $dark-gray;
                    transition: .3s ease;

                    &:hover {
                        color: $honey;
                        border-color: $honey;
                    }
                }

                .usage-price {
                    & > div {
                        display: flex;
                        flex-direction: column;
                        margin: 15px 0;

                        div {
                            display: grid;
                            grid-template-columns: 35% 65%;
                            @include desktop() {
                                grid-template-columns: 20% 80%;
                            }
                        }
                    }
                }
            }
        }

        &__icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 13px;
            height: 13px;
            border: 0;
            background-color: transparent !important;
            padding: 0;

            &::after,
            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                content: '';
                width: 100%;
                height: 1px;
                background-color: $black;
                transform: translateY(-50%);
                transition: .2s;
            }

            &::after {
                left: 50%;
                transform: translate(-50%, -50%) rotate(90deg);
            }

            &.btn-stroke:hover {
                background-color: transparent !important;
            }
        }

        &--open {
            .faq-acc__body {
                grid-template-rows: 1fr;
                padding: 0 0 25px;
            }

            .faq-acc__icon::after {
                transform: translate(-50%, -50%) rotate(0);
            }
        }
    }

    &--howitwork {
        .faq__title {
            margin-bottom: 43px;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 0;
            text-transform: capitalize;

            @include desktop {
                margin-bottom: 46px;
                font-size: 30px;
                line-height: 38px;
            }
        }

        .faq__inner {
            padding-top: 58px;
            padding-bottom: 60px;

            @include desktop {
                padding-top: 95px;
                padding-bottom: 94px;
            }
        }

        .faq-acc__head p {
            letter-spacing: 1.4px !important;
        }

        .faq-acc__icon {
            right: 7px;
        }
    }
}