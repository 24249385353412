.contributors-wrapper {
    padding: 50px 25px;
    @include desktop(){
        padding: 50px 0;
    }

    h1 {
        font-family: 'OrpheusPro', sans-serif;
        font-size: 36px;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: $gray;
        margin-bottom: 40px;
        @include desktop() {
            font-size: 54px;
            line-height: 1.13;
        }
    }

    .contributors-header {
        position: relative;
        background-color: $main-bg;
        border-radius: 10px;
        padding: 22px 25px 22px 21px;
        @include desktop() {
            height: 96px;
            padding: 36px 29px;
        }

        .show-mob {
            display: flex;
            @include desktop() {
                display: none;
            }
        }
        button {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        p,
        button {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: normal;
            color: $gray;
            align-items: center;
            border: unset;
            background-color: transparent;
            text-transform: capitalize;
            padding: 0;
            cursor: pointer;
            @include desktop() {
                display: flex;
                font-size: 16px;
                line-height: 1.56;
            }

            span {
                width: 15px!important;
                margin-left: 5px!important;

                img {
                    width: 15px;
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }

        & > div {
            display: none;
            position: absolute;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            background: $main-bg;
            top: 50px;
            width: 100%;
            left: 0;
            padding: 20px;
            border-radius: 10px;
            z-index: 1;
            @include desktop() {
                align-items: center;
                display: flex;
                position: unset;
                width: 100%;
                height: auto;
                padding: 0;
                justify-content: space-between;
                flex-direction: row;
            }
            &.open {
                display: flex;
            }
            p {
                img {
                    display: none;
                    @include desktop() {
                        display: block;
                    }
                }
                &.filter {
                    img {
                        transform: rotate(180deg);
                    }
                }
            }

            & > div {
                @include desktop() {
                    display: flex;
                    width: 54%;
                    justify-content: space-between;
                    background-color: transparent;
                }
                p {
                    &:nth-child(2) {
                        @include desktop() {
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }

    .contributor {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: $border;
        padding: 30px 0;
        @include desktop() {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            align-items: center;
            padding: 39px 29px;
        }

        &:last-of-type {
            border-bottom: unset;
        }

        .btn-link {
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            margin-top: 15px;
            position: absolute;
            right: 0;
            top: 25px;
            @include desktop() {
                position: unset;
                margin-top: 0;
            }
        }

        h2 {
            font-family: 'OrpheusPro', sans-serif;
            font-size: 24px;
            line-height: 1.25;
            letter-spacing: normal;
            color: $gray;
            margin-bottom: 12px;
            @include desktop() {
                font-size: 30px;
                line-height: 1.27;
                margin-bottom: 0;
                width: 234px;
            }
            @include lg(){
                width: 300px;
            }
        }

        div:nth-child(2){
            @include desktop(){
                width: 211px;
            }
            @include lg(){
                width: 223px;
            }
        }

        p {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: normal;
            color: $gray;
            margin-bottom: 0;
            @include desktop() {
                font-size: 16px;
                line-height: 1.56;
            }

            &:nth-child(3){
                @include desktop(){
                    width: 255px;
                }
                @include lg(){
                    width: 306px;
                }
            }

            &:nth-child(4){
                @include desktop(){
                    width: 219px;
                    padding-left: 30px;
                }
                @include lg(){
                    width: 257px;
                }
            }

            span {
                &:nth-child(2) {
                    position: absolute;
                    right: 75px;
                    color: $dark-cream;
                    margin-right: 7px;
                }
            }
        }
    }

    .subtotal-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 18px 0 23px;
        @include desktop() {
            padding: 31px 29px 29px;
        }

        div {
            p {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                color: $gray;
                margin-bottom: 5px;
                @include desktop() {
                    font-size: 16px;
                    margin-bottom: 9px;
                }
            }

            &:nth-child(2) {
                p {
                    text-align: right;

                    &:nth-child(2) {
                        color: $dark-cream;
                    }
                }
            }
        }
    }

    .total {
        @extend .subtotal-wrapper;
        border-top: $border;

        p {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 14px;
            color: $gray;
            margin-bottom: 5px;
            @include desktop() {
                font-size: 16px;
                margin-bottom: 9px;
            }
        }
    }
}

.max-p {
    @include desktop() {
        width: 186px !important;
    }
}

.max-w100 {
    @include desktop() {
        width: 140px !important;
    }
}
