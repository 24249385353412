.podcast {
    &__hero {
        width: 100%;
        overflow: hidden;

        &-container {
            max-width: 90%;
            margin: 0 auto;
            position: relative;
            padding: 80px 0 100px;

            //@include desktop(max) {
            //    &::before, &::after {
            //        content: ' ';
            //        height: 100%;
            //        position: absolute;
            //        top: 0;
            //    }
            //
            //    &::before {
            //        border-left: 2px solid $honey-money;
            //        left: 35px;
            //    }
            //
            //    &::after {
            //        border-right: 2px solid $honey-money;
            //        right: 35px;
            //    }
            //}

            @include desktop() {
                max-width: 1150px;
                text-align: center;
                padding: 160px 0;
                border-left: 1px solid $honey-money;
                border-right: 1px solid $honey-money;
            }
        }

        &-moon {
            position: absolute;
            width: 60px;
            left: 25px;
            margin-left: 15px;
            margin-top: -25px;

            @include desktop() {
                width: unset;
                left: 0;
                margin-left: 55px;
                margin-top: -65px;
            }
        }

        &-header {
            @include desktop(max) {
                width: 100%;
                //max-width: 87%;
                margin: 0 auto;

                * {
                    width: 100% !important;
                }
            }
        }

        &-stars {
            position: absolute;
            right: -60px;
            bottom: 0;

            @include desktop(max) {
                width: 180px;

                * {
                    width: 100% !important;
                }
            }

            @include desktop() {
                right: -80px;
                bottom: 45px;
            }
        }
    }

    &__listen {
        border-top: 1px solid $honey-money;

        &-container {
            display: flex;

            @include desktop(max) {
                padding: 25px;
                flex-direction: column;
            }
        }

        &-left {
            margin-bottom: 17px;

            @include desktop() {
                padding-left: 0 !important;
                margin-bottom: 0;
            }
        }

        &-left, &-right {
            padding: 11px;

            * {
                width: 100% !important;
                object-fit: cover !important;
                object-position: top !important;
            }

            @include desktop() {
                width: 25%;
                padding: 65px;

                * {
                    height: 330px !important;
                }
            }
        }

        &-right {
            @include desktop() {
                padding-right: 0;
            }
        }

        &-center {
            color: $gray;
            text-align: center;
            margin-bottom: 40px;

            @include desktop() {
                width: 50%;
                border-left: 2px solid $honey-money;
                border-right: 2px solid $honey-money;
                min-height: 461px;
                padding: 65px;
                margin-bottom: 0;
            }

            h3 {
                font-family: $font-family-secondary;
                font-size: 26px;
                line-height: 1.37;
                letter-spacing: 0.95px;
                margin-bottom: 22px;

                @include desktop() {
                    font-size: 30px;
                }
            }

            p {
                font-size: 14px;
                line-height: 1.71;
                letter-spacing: normal;
            }

            .btn-solid {
                margin-top: 54px;
            }
        }

        &-available {
            width: 100%;
            height: auto;
            text-align: center;
            background-color: #d7c7ac;
            font-family: $font-family-tertiary;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 1.6px;
            color: $gray;
            text-transform: uppercase;
            padding: 10px 15px;

            @include desktop() {
                height: 45px;
                font-size: 16px;
                line-height: 48px;
                padding: 0;
            }

            a {
                margin: 0 5px;

                &:not(:hover) {
                    text-decoration: underline;
                }
            }
        }
    }

    &__hosts {
        padding: 30px 30px 70px;

        @include desktop() {
            padding: 93px 0;
        }

        @include between(md, lg) {
            padding-left: 25px;
            padding-right: 25px;
        }

        &-container {
            display: grid;
            margin: 0 auto;
            grid-gap: 30px;

            @include desktop() {
                max-width: 1150px;
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &-left {
            color: $gray;

            h4 {
                font-family: $font-family-secondary;
                font-size: 28px;
                letter-spacing: 0.5px;
                line-height: 1.37;
                margin-bottom: 30px;

                @include desktop() {
                    font-size: 40px;
                }
            }

            & > div {
                padding-right: 15px;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.71;

                &:not(:last-of-type) {
                    margin-bottom: 30px;
                }

                a {
                    @include desktop(max) {
                        border-radius: 20px;
                        border: solid 1px #909090;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 40px;
                        padding: 0 15px;
                        margin: 15px 10px 0 0;
                        letter-spacing: .35px;
                        color: #909090;
                        display: inline-block;
                        text-align: center;
                        margin-left: 7px;
                    }
                }
            }
        }

        &-right {
            @include desktop(max) {
                margin-top: 12px;
            }
        }
    }

    &__previews {
        overflow: hidden;
        background-color: $light-cream;
        padding: 45px 0 40px;
        margin-bottom: 140px;

        @include desktop() {
            padding: 85px 0 90px;
            margin-bottom: 190px;
        }

        &-container {
            max-width: 90%;
            margin: 0 auto;

            @include desktop() {
                max-width: 1150px;
            }

            h4 {
                font-family: $font-family-secondary;
                line-height: 1.37;
                letter-spacing: .95px;
                color: $gray;
                font-size: 38px;
                margin-bottom: 28px;

                @include desktop(max) {
                    font-size: 26px;
                    padding: 0 30px 25px;
                    margin-bottom: 20px;
                }

                @include tablet() {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        &-list {
            position: relative;
            height: 100%;

             .swiper-slide {
                height: auto !important;
            }

            .slider-button {
                width: 35px;
                height: 35px;
                padding: 0;
                border: none;
                position: absolute;
                top: 40%;
                transform: translateY(-50%);
                z-index: 1;

                &.swiper-button-disabled {
                    opacity: 0.5;
                }

                @include rwd-down(768) {
                    display: none;
                }

                * {
                    width: 100% !important;
                    height: 100% !important;
                }

                &.slide-preview-prev {
                    left: -35px;
                    transform: translateY(-50%) scaleX(-1);
                    background-color: transparent !important;
                    border: none !important;

                    @include desktop() {
                        left: -50px;
                    }
                }

                &.slide-preview-next {
                    right: -35px;
                    background-color: transparent !important;
                    border: none !important;

                    @include desktop() {
                        right: -50px;
                    }
                }
            }

            .podcast__preview {
                display: flex;
                flex-direction: column;
                height: 100%;

                & > span {
                    font-size: 14px;
                    font-weight: 400;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: .35px;
                    color: #909090;
                    min-height: 38px;
                    display: block;
                    margin-bottom: 5px;
                }

                &-image {
                    margin-top: auto;
                }

                &-content > h5 {
                    font-family: $font-family-primary;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: .45px;
                    color: $gray;
                    margin: 18px 0;
                    text-transform: none;
                    min-height: 75px;

                    @include desktop(max) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &__footer {
        padding: 40px 0 45px;
        border-top: 2px solid $honey-money;

        @include desktop() {
            padding: 98px 0 80px;
        }

        &-container {
            max-width: 90%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;

            @include desktop() {
                max-width: 1150px;
            }

            h2 {
                font-family: $font-family-secondary;
                font-size: 42px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: .88;
                letter-spacing: 1.05px;
                text-align: center;
                color: $gray;
                margin: 0 0 35px;

                @include desktop(max) {
                    font-size: 28px;
                    line-height: 1.4;
                }
            }

            a {
                margin: 0 auto;
                max-width: 263px;
                text-align: center;
            }
        }
    }
}