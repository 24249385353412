.inp-primary {
    select {
        width: 100%;
        border: unset;
        border-bottom: 1px solid #464646;
        font-family: "MaisonNeue-Book", sans-serif;
        font-size: 16px;
        line-height: 1.57;
        letter-spacing: normal;
        background-color: transparent;
        margin-bottom: 30px;
        -webkit-appearance: none;
        -moz-appearance: none;
        color: #000;
        &:focus{
            outline: none;
        }
    }
    .selectric-wrapper {
        margin-bottom: 30px;
        @include desktop() {
            margin-bottom: 0;
        }

        .selectric {
            margin-bottom: 35px;

            span.label {
                font-family: "MaisonNeue-Book", sans-serif;
                font-size: 14px;
                @include desktop() {
                    font-size: 16px;
                }
            }
        }

        .selectric-items {
            z-index: 1;
            top: 28px;
            box-shadow: unset;
            border: $border;

            .selectric-scroll {
                ul {
                    li {
                        font-family: "MaisonNeue-Book", sans-serif;
                        font-size: 14px;
                        background-color: $white;
                        text-align: left;
                        padding: 0 21px 5px;

                        @include desktop() {
                            font-size: 16px;
                        }

                        &:hover {
                            color: $honey;
                        }

                        &:first-child{
                            padding-top: 11px!important;
                        }
                    }
                }
            }
        }
    }

    .select-chevron {
        pointer-events: none;
        width: 15px !important;
    }
}
