.contact {
    padding-top: 0;

    &-header {
        padding: 100px 25px 45px;
        font-family: $bodyFont;
        line-height: 25px;
        background-color: $main-bg;
        text-align: center;

        @include desktop {
            padding: 156px 105px 96px;
        }

        h1 {
            color: $gray;
            @media screen and(max-width: 991.98px) {
                font-size: 36px;
                line-height: 42px;
            }
        }

        &__content {
            margin-top: 32px;

            @include desktop() {
                margin-top: 65px;
            }
        }

        &__column {
            padding: 27px 18px 31px;

            @include desktop {
                padding: 18px 18px 16px;
                border-right: 1px solid $light-gray;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $light-gray;

                @include desktop {
                    border-bottom: 0;
                }
            }

            &:first-child {
                @include desktop {
                    border-left: 1px solid $light-gray;
                }
            }

            a {
                color: $dark-gray;
                text-decoration: underline;
                text-underline-offset: 1px;
                font-size: 12px;
                letter-spacing: 1.2px;
                margin-top: 6px;
                display: inline-block;

                @include desktop() {
                    margin-top: 0;
                    font-size: 1rem;
                    letter-spacing: 0;
                }
            }

            p {
                span {
                    display: block;
                    text-transform: uppercase;
                    color: $dark-gray;
                    font-size: 12px;
                    line-height: normal;
                    font-family: "MaisonNeue-Extended-Book";
                    letter-spacing: 1.2px;

                    @include desktop {
                        font-size: 14px;
                        letter-spacing: 1.4px;
                        line-height: 25px;
                    }

                }
            }
        }
    }

    &-form {
        padding: 74px 25px 84px;
        font-family: $bodyFont;
        line-height: 25px;

        .contact-thank-you {
            text-align: center;
        }


        @include desktop {
            padding: 96px 0 70px;
        }

        &__container {
            max-width: 674px !important;
        }

        form {
            margin-top: 43px;

            @include tablet {
                margin-top: 45px;
            }

            .selectric {
                margin-bottom: 0;
            }
            .input-row{
                .input__col{
                    @include tablet(){
                        margin-bottom: 0;
                    }
                }
            }
        }

        p {
            color: $dark-gray;

            @media screen and(max-width: 991.98px) {
                font-size: 14px;
                line-height: 22px;
            }
        }


        .inp-primary {
            input {
                margin: 0;

                &:not([type='radio']):not([type='checkbox']) {
                    border-radius: 0;
                    -webkit-appearance: none;
                }
            }
        }


        .form-control--textarea {
            font-size: 14px;

            @include desktop() {
                font-size: 16px;
                line-height: 25px;
            }
        }
    }

    &-select {
        &__value-container {
            padding: 0 !important;
            height: 28px !important;
        }

        &__input {
            &::-webkit-input-placeholder {
                color: $dark-cream;
            }
        }

        &__input-container {
            height: 28px;
            margin: 0 !important;
        }

        &__indicator {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8' fill='none'%3E%3Cpath d='M13.36 1 7.18 7.18 1 1' stroke='%23464646' stroke-linecap='round'/%3E%3C/svg%3E");
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8' fill='none'%3E%3Cpath d='M13.36 1 7.18 7.18 1 1' stroke='%23464646' stroke-linecap='round'/%3E%3C/svg%3E");
            background-size: 14px 8px;
            background-repeat: no-repeat;
            background-position: center;
            width: 20px;
            height: 20px;

            svg {
                display: none;
            }
        }
    }
}

.input {
    &-row {
        margin-bottom: 46px;

        @include tablet {
            margin-bottom: 38px;
        }

        &--group {
            @include tablet {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 32px;
            }

            .input__col:first-child {
                @include rwd-down(950) {
                    margin-bottom: 46px;

                    .inp-primary {
                        input {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }

        &--textarea {
            margin-bottom: 52px;
            margin-top: 62px;

            @include desktop {
                margin-bottom: 53px;
                margin-top: 0;
            }

            textarea {
                display: block;
                height: 184px;
            }
        }

        &--submit {
            margin-bottom: 0;

            button {
                width: 100%;
                background-color: transparent;
            }
        }
    }
}

.form {
    &-control {
        font-family: $bodyFont;
        font-size: 14px;
        line-height: 25px;
        color: $dark-gray;
        padding-left: 0;
        border: 0;
        border-bottom: 1px solid $dark-gray;
        width: 100%;


        @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 25px;
        }

        &::-webkit-input-placeholder {
            color: $dark-cream;
        }

        &--textarea {
            padding: 18px 17px;
            border: 1px solid $dark-gray;
            border-radius: 10px;
            resize: none;

            @include desktop() {
                padding: 22px 24px;
            }
        }
    }
}

.feeds {
    padding: 78px 0 87px;
    background-color: $honey;
    text-align: center;
    overflow: hidden;

    @include desktop {
        padding: 91px 0 126px;
    }

    .h1 {
        @media screen and(max-width: 991.98px) {
            font-size: 36px;
            line-height: 42px;
        }

        a {
            color: $gray;
        }
    }

    .container {
        max-width: 1440px;
        //padding: 0 25px;
    }

    &__content {
        position: relative;
        margin-top: 40px;
        overflow: hidden;
        padding-left: 25px;
        margin-right: -25px;

        @include desktop {
            padding: 0 105px;
            margin-top: 56px;
            margin-right: 0;
        }

        .swiper {

            &-container {
                position: static;

            }

            &-button {

                &-next,
                &-prev {
                    display: none;
                }
            }

            &-custom-button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                &-prev {
                    left: 30px;
                }

                &-next {
                    right: 30px;
                }
            }
        }
    }

    .feed {
        padding: 8px;
        background-color: $white;

        & > span {
            width: 100%!important;
            display: block!important;
            & > span {
                img {
                    width: 100%!important;
                }
            }

        }
    }

    button.swiper-custom-button {
        padding: 0 !important;
        background-color: transparent !important;
        border: 0 !important;
        cursor: pointer;
        display: none;

        @include desktop {
            display: block;
        }
    }
}
