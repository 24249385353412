@font-face {
    font-family: "Laluxe";
    src: url("../fonts/LaLuxesScript.woff2") format("woff2"),
    url("../fonts/LaLuxesScript.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
}

@font-face {
    font-family: "OrpheusPro";
    src: url("../fonts/OrpheusPro.woff2") format("woff2"),
    url("../fonts/OrpheusPro.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
}

@font-face {
    font-family: "MaisonNeue-Book";
    src: url("../fonts/MaisonNeueWEB-Book.woff2") format("woff2"),
    url("../fonts/MaisonNeueWEB-Book.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
}

@font-face {
    font-family: "MaisonNeue-BookItalic";
    src: url("../fonts/MaisonNeue-BookItalic.woff2") format("woff2"),
    url("../fonts/MaisonNeue-BookItalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
}

@font-face {
    font-family: "MaisonNeue-Medium";
    src: url("../fonts/MaisonNeue-Medium.woff2") format("woff2"),
    url("../fonts/MaisonNeue-Medium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
}

@font-face {
    font-family: "MaisonNeue-Extended-Bold";
    src: url("../fonts/MaisonNeue-ExtendedBold.woff2") format("woff2"),
    url("../fonts/MaisonNeue-ExtendedBold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
}

@font-face {
    font-family: "MaisonNeue-Extended-Book";
    src: url("../fonts/MaisonNeueExtendedWEB-Book.woff2") format("woff2"),
    url("../fonts/MaisonNeueExtendedWEB-Book.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
}

@font-face {
    font-family: "MaisonNeue-Extended-Light";
    src: url("../fonts/MaisonNeue-ExtendedLight.woff2") format("woff2"),
    url("../fonts/MaisonNeue-ExtendedLight.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: block;
}
