$media-break-points: (
    xxs: 475,
    xs: 600,
    sm: 768,
    xm: 950,
    md: 1024,
    mdMax: 1023.8,
    lg: 1280,
    xl: 1440,
    xxl: 1500
);

@function get-break-point($size: sm) {
    $media-size: map-get($media-break-points, $size);
    @return $media-size + px;
}

// Colors
$honey: #ccba9c;
$honey-money: #ebe3d5;
$illusive-dream: #e1d5c1;
$mojave-desert: #c8b594;
$middlestone: #c7ae84;
$sea-monster: #688c80;
$orange-grove: #ffb18f;
$timeless-beauty: #b72c42;
$soar: #DEEEF1;

// Bright Shades
$white: #FFFFFF;
$main-bg: #f8f8f8;
$light-cream: #fafafa;
$cream: #faf1e7;
$dark-cream: #c2c2c2;
$light-gray: #dfddd9;
$popup-bg: rgba(249, 249, 249, 0.8);
$baby-grey: #babab9;
$magical-moonlight: #efeeec;

// Dark Colors
$black: #000000;
$gray: #323232;
$dark-gray: #464646;

// Other
$border: 1px solid $light-gray;
$loading-anim: 1.5s linear 01s infinite;
$font-family-primary: 'MaisonNeue-Book', sans-serif;
$font-family-secondary: 'OrpheusPro', sans-serif;
$font-family-tertiary: 'MaisonNeue-Extended-Book', sans-serif;
$font-handwriting: 'Laluxe', sans-serif;