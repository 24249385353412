.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
            margin-right: 30px;
        }
    }

    input {
        display: none;

        &:checked + label::before {
            background-color: $honey;
            border-color: $honey;
        }
    }

    label {
        font-family: 'MaisonNeue-Extended-Book', sans-serif;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 1.2px;
        color: $dark-gray;
        text-transform: uppercase;
        position: relative;
        padding-left: 25px;
        cursor: pointer;
        @include desktop() {
            font-size: 14px;
            letter-spacing: 1.4px;
        }

        &:before {
            content: '';
            border: 1px solid $dark-gray;
            width: 16px;
            height: 16px;
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            margin: auto;
        }
    }
}

.checkbox-wrapper {
    width: 18px;
    height: 18px;

    .custom-checkbox {
        position: relative;
        z-index: -1;
        opacity: 0;
        display: none;

        & + label {
            display: inline-flex;
            align-items: center;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            position: relative;

            &::before {
                content: '';
                display: inline-block;
                width: 18px;
                height: 18px;
                border: 1px solid $dark-gray;
            }
        }

        &:checked + label::before {
            background-color: $dark-gray;
            background-image: url('../images/Vector-checkmark.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}