.btn {
    &-loading {
        position: relative;
        color: transparent !important;

        &:before {
            background-image: url('/images/spinner.svg');
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 26px;
            height: 26px;
            -webkit-animation: loadingCircle 1.5s linear 01s infinite;
            animation: loadingCircle 1.5s linear 01s infinite;
        }
    }

    &-round {
        min-width: 110px;
        height: 40px;
        border-radius: 20px;
        border: solid 1px #909090;
        font-family: $font-family-primary;
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: .35px;
        color: #909090;
        display: inline-block;
        text-align: center;

        &:hover {
            color: $white;
            background-color: #909090;
        }
    }

    &-solid {
        font-family: $font-family-tertiary;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.9;
        letter-spacing: 1.6px;
        color: $gray;
        text-transform: uppercase;
        padding: 12px 22px 10px;
        border: solid 1px $gray;
        display: inline-block;

        &:hover {
            color: $white;
            border: solid 1px $dark-gray;
            background-color: $dark-gray;
        }
    }

    &-stroke {
        width: 178px;
        font-size: 12px;
        letter-spacing: 1.2px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        background-color: $white;
        border-radius: 10px;
        border: solid 1px $black;
        font-family: 'MaisonNeue-Extended-Book', sans-serif;
        line-height: normal;
        color: $dark-gray;
        text-transform: uppercase;
        cursor: pointer;
        @include desktop() {
            width: 219px;
            font-size: 14px;
            letter-spacing: 1.4px;
        }

        &:not(:disabled):hover:not(.btn-loading),
        &.hover {
            background-color: $black!important;
            color: $white!important;
        }
    }

    &-transparent {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        width: 150px;
        height: 50px;
        border: 1px solid $black;
        border-radius: 10px;
        font-family: 'MaisonNeue-Extended-Book', sans-serif;
        letter-spacing: 1.4px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
        color: $dark-gray;
    }

    &-none {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    &-href {
        @extend .btn-none;
        font-family: 'MaisonNeue-Book', sans-serif;
        text-decoration: underline;
        font-size: 12px;
        color: $dark-gray;
        text-align: left;
        padding: 0;
        margin-top: 7px;
    }

    &-fill {
        @extend .btn-stroke;
        background-color: $black!important;
        color: white;

        &:not(:disabled):hover,
        &.hover {
            background-color: $honey;
        }
    }

    &-gold {
        @extend .btn-none;
        font-size: 12px;
        letter-spacing: 1.2px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 186px;
        height: 50px;
        background-color: $honey;
        color: $dark-gray;
        border-radius: 10px;
        font-family: 'MaisonNeue-Extended-Book', sans-serif;
        line-height: normal;
        text-transform: uppercase;
        cursor: pointer;
        @include desktop() {
            width: 219px;
            font-size: 14px;
            letter-spacing: 1.4px;
        }
        &:hover{
            color: $white;
        }
    }

    &-link {
        font-family: 'MaisonNeue-Extended-Book', sans-serif;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 1.2px;
        text-align: left;
        color: $dark-gray;
        border: unset;
        border-bottom: 1px solid $dark-gray;
        background-color: transparent;
        padding: 0;
        @include desktop() {
            font-size: 14px;
            letter-spacing: 1.4px;
        }

        &:hover,
        &.hover {
            color: $honey;
            border-bottom: 1px solid $honey;
        }
    }

    &-regular {
        @extend .btn-none;
        font-family: 'MaisonNeue-Extended-Book', sans-serif;
        font-size: 14px;
        color: $dark-gray;
        letter-spacing: 1.4px;
    }
}

.fake-zendesk-button {
    z-index: 999;
}

button:not(.processing) {
    &:disabled, &.disabled {
        border: 1px solid $dark-cream !important;
        background-color: $dark-cream !important;
        color: $white !important;
        cursor: auto !important;
    }
}

@-webkit-keyframes loadingCircle {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loadingCircle {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
