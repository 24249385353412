.cart {
    background-color: $soar;
    padding: 20px 0 25px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 11;
    @extend .hide-scroll;
    @include desktop() {
        z-index: 4;
        top: 83px;
        height: calc(100vh - 80px);
        overflow: auto;
        padding: 18px 18px 18px 30px;
    }
    @include xl() {
        height: calc(100vh - 85px);
    }

    .cart-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        @include desktop(max) {
            position: relative;
            margin-bottom: 37px;
        }

        & > span {
            width: 27px !important;
            height: 25px !important;

            @include desktop(max) {
                margin-left: auto !important;
                right: 15px !important;
                top: 3px !important;
            }
        }

        img {
            cursor: pointer;
            width: 27px !important;
            height: 25px !important;
            -o-object-fit: contain;
            object-fit: contain;

            @include desktop(max) {
                position: absolute;
                right: 0 !important;
                top: 0 !important;
            }
        }

        h3 {
            font-family: $font-family-secondary;
            font-size: 30px;
            line-height: 1.27;
            letter-spacing: normal;
            color: $gray;

            @include desktop(max) {
                font-size: 24px;
                line-height: 1.25;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    p {
        font-family: 'MaisonNeue-book', sans-serif;
        font-size: 14px;
        @include desktop() {
            font-size: 16px;
        }
    }

    .cart_body {
        height: calc(100% - 80px);
        overflow: auto;
        @include desktop() {
            height: fit-content;
        }

        .cart-row-wrapper, .container-wrapper {
            padding-left: 25px;
            padding-right: 25px;
            @include desktop() {
                padding-left: unset;
                padding-right: unset;
            }
        }

        .container-wrapper {
            @include desktop() {
                min-height: 199px;
            }
        }

        .cart-row-wrapper {
            .cart_row_cell {
                @include desktop(max) {
                    &:first-child {
                        justify-self: start;
                    }
                    &:nth-child(2) {
                        display: none;
                    }
                }
            }
        }

        .cart_row {
            display: grid;
            margin-bottom: 2px;
            grid-template-columns: repeat(2, 1fr);
            @include desktop() {
                grid-template-columns: 41% 20% 39%;
                margin-bottom: unset;
                padding-bottom: 9px;
            }
            @include desktop(max) {
                justify-items: end;
            }

            .quantity {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                @include desktop() {
                    max-width: 76px;
                    left: -7px;
                    top: 6px;
                }
                @include desktop(max) {
                    margin-top: 21px;
                    left: -9px;
                }

                p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    color: $dark-gray;
                    line-height: 22px;
                    position: relative;
                    @include desktop() {
                        top: -2px;
                        font-size: 16px;
                        line-height: 25px;
                    }
                }

                button {
                    &:first-of-type {
                        @include desktop(max) {
                            margin-right: 10px;
                        }
                    }

                    &:last-of-type {
                        @include desktop(max){
                            margin-left: 8px;
                            margin-top: 1px;
                        }
                        & > img {
                            position: relative;
                            top: 2px;
                            right: -1px;
                            @include desktop(max) {
                                top: 1px;
                                right: -8px;
                            }
                        }
                    }
                }
            }

            & > div {
                display: flex;
                justify-content: space-between;
                @include desktop() {
                    justify-content: unset;
                }
            }

            .cart_row_cell {
                font-size: 14px;
                line-height: 1.57;
                letter-spacing: normal;
                text-align: left;
                color: $dark-gray;
                @include desktop() {
                    font-size: 16px;
                    line-height: 1.56;
                    padding-right: 25px;
                    padding-top: 8px;
                }
            }

            .btn-link {
                height: fit-content;
                width: fit-content;
                margin-top: 22px;
                @include desktop() {
                    margin-top: unset;
                    padding-top: 9px;
                }
            }
        }

        .cart-row-container {
            padding-top: 25px;
            border-top: 1px solid $gray;
            @include desktop() {
                padding-top: 20px;
            }
        }

        .cart-row-item-container {
            margin-bottom: 63px;
            @include desktop() {
                margin-bottom: 15px;
            }

            .cart_row {
                //justify-content: space-between;
                @include desktop(max) {
                    grid-template-areas: "a c" "b .";
                    .cart_row_cell {
                        &:first-child {
                            grid-area: a;
                            justify-self: start;
                        }

                        &:nth-child(3) {
                            grid-area: c;
                        }
                    }
                    .btn-link, .quantity {
                        grid-area: b;
                        justify-self: start;
                    }
                }
                //
                //.quantity {
                //    button:last-of-type {
                //        @include desktop() {
                //            position: relative;
                //            top: -2px;
                //        }
                //    }
                //}
            }
        }

        .remove {
            text-transform: uppercase;

            &:hover {
                border-bottom: 1px solid $dark-gray;
                color: $dark-gray;
            }
        }

        .checkbox-button-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            min-height: 215px;
            @include desktop() {
                display: block;
                min-height: unset;
            }

            .confirm-checkbox {
                position: unset;

                .checkbox-wrapper {
                    @include desktop(max) {
                        width: 18px;
                        height: 18px;
                    }

                    label {
                        right: 15px;

                        @include desktop(max) {
                            right: 19px;
                            top: -6px;
                        }
                    }
                }

                p {
                    font-size: 12px;
                    @include desktop() {
                        font-size: 14px;
                    }
                }
            }
        }

        .cart-actions-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column-reverse;
            @include desktop() {
                flex-direction: row;
                justify-content: space-between;
            }

            .btn-link {
                text-transform: uppercase;

                &:hover {
                    color: $dark-gray;
                    border-bottom: 1px solid $dark-gray;
                }
            }

            .cart-actions {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                grid-gap: 20px;
                margin-bottom: 27px;
                @include desktop() {
                    margin-bottom: 0;
                    justify-content: space-between;
                    padding-right: 11px;
                }

                .disabled:hover {
                    background-color: $dark-cream !important;
                }

                .keep-browsing {
                    text-transform: uppercase;
                    order: 2;
                    font-size: 14px;
                    letter-spacing: 1.4px;
                    @include desktop() {
                        order: 1;
                    }
                }

                .btn-stroke.btn-fill {
                    order: 1;
                    width: 324px;
                    @include desktop() {
                        order: 2;
                        width: 448px;
                    }

                    &:hover {
                        border: 1px solid $dark-gray;
                        color: $dark-gray;
                    }
                }
            }
        }

        .subtotal-container {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 23%;
            @include desktop() {
                position: unset;
                justify-content: flex-end;
            }

            p {
                font-family: 'MaisonNeue-book', sans-serif;
                font-size: 14px;
                line-height: 1.57;
                letter-spacing: normal;
                text-align: center;
                color: $dark-gray;
            }
        }

        .confirm-checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 22px;
            @include desktop() {
                justify-content: flex-end;
                margin-bottom: 21px;
                padding-right: 110px;
            }

            .checkbox-wrapper {
                width: unset;
                height: unset;

                label {
                    right: 13px;
                    @include desktop() {
                        right: 15px;
                    }
                }
            }

            p {
                font-size: 14px;
                line-height: 18px;
                color: $dark-gray;
                @include desktop() {
                    line-height: 20px;
                }

                a {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    color: $dark-gray;
                    text-decoration: underline;
                }
            }
        }
    }
}

.cart_hidden {
    display: none;
}

.cart_visible {
    display: block;
}

.cart-message-container {
    position: sticky;
    z-index: 4;
    top: 57px;
    width: 100%;
    @include desktop() {
        top: 83px;
    }

    .cart-message {
        display: flex;
        background-color: $soar;
        width: 100%;
        padding: 13px 25px;
        grid-gap: 9px;
        align-items: center;
        flex-direction: column;
        border: none;
        height: unset;
        min-height: 74px;
        border-radius: 0;
        text-transform: unset;
        @include desktop() {
            min-height: unset;
            height: 41px;
            grid-gap: 10px;
            padding: 9px 20px;
            justify-content: space-between;
            flex-direction: row;
        }

        &#cart-message {
            &:hover {
                background-color: $soar !important;
            }
        }

        p {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: center;
            color: $dark-gray;
            @include desktop() {
                font-size: 16px;
                line-height: 1.56;
            }
        }

        span:hover {
            color: $dark-gray;
            border-bottom: 1px solid $dark-gray;
        }
    }
}

.cart_message_hidden {
    display: none;
}

.cart_message_visible {
    display: flex;
}

.cart-form {
    background-color: $white;
    padding: 16px 17px 0 14px;
    margin: 0 9px 59px;
    @include desktop() {
        padding: 23px 26px 0;
        margin: 0 0 42px;
    }

    .gift-message-wrapper {
        width: 100%;
        border-bottom: 1px solid $light-gray;
        padding-bottom: 24px;
        @include desktop() {
            padding-bottom: 45px;
        }

        &.gift-message-closed {
            padding-bottom: 0;

            .gift-message-head {
                margin-bottom: 15px;
                @include desktop() {
                    margin-bottom: 20px;
                }

                button {
                    opacity: 1;
                    pointer-events: auto;
                    visibility: visible;
                }
            }

            .gift-message-body {
                display: none;
            }
        }

        .gift-message-head {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            @include desktop() {
                margin-bottom: 20px;
            }

            h3 {
                font-family: 'MaisonNeue-Extended-Book', sans-serif;
                font-size: 12px;
                letter-spacing: 1.2px;
                color: $dark-gray;
                @include desktop() {
                    letter-spacing: 1.4px;
                    font-size: 14px;
                }
            }

            button {
                position: relative;
                width: unset;
                opacity: 0;
                pointer-events: none;
                visibility: hidden;
                right: -8px;
                @include desktop() {
                    right: -3px;
                }

                & > img {
                    width: 32px;

                }
            }
        }

        .gift-message-body {
            & > h3 {
                font-family: "MaisonNeue-Book", sans-serif;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 26px;
                @include desktop() {
                    font-size: 16px;
                    line-height: 25px;
                    margin-bottom: 27px;
                }
            }

            textarea {
                resize: none;
                border: 1px solid $gray;
                border-radius: 10px;
                font-family: "MaisonNeue-Book", sans-serif;
                font-size: 16px;
                width: 100%;
                height: 159px;
                overflow: auto;
                padding: 15px;
                margin-bottom: 23px;
                @include desktop() {
                    max-width: 884px;
                    height: 147px;
                    margin-bottom: 32px;
                }
            }

            button {
                width: 150px;
                @include desktop(max) {
                    margin: 0 auto;
                }

                &:disabled, &.disabled {
                    border: 1px solid $light-gray !important;
                    background-color: transparent !important;
                    color: $dark-cream !important;
                }
            }
        }
    }

    .mailing-address-wrapper {
        @include desktop(max) {
            margin-bottom: 60px;
        }

        &.mailing-address-closed {
            margin-bottom: 0;

            .mailing-address-head {
                margin-bottom: 0;
                padding: 27px 0 24px;
                @include desktop(max) {
                    padding: 24px 0 21px;
                }
            }

            .mailing-container {
                display: none;
            }
        }

        .mailing-address-head {
            padding: 19px 0;
            margin-bottom: 6px;
            @include desktop() {
                padding: 22px 0;
                margin-bottom: 5px;
            }

            h3 {
                font-family: 'MaisonNeue-Extended-Book', sans-serif;
                font-size: 12px;
                letter-spacing: 1.2px;
                color: $dark-gray;
                @include desktop() {
                    letter-spacing: 1.4px;
                    font-size: 14px;
                }
            }
        }

        h3 {
            font-family: "MaisonNeue-Book", sans-serif;
            font-size: 14px;
            line-height: 20px;
            @include desktop() {
                font-size: 16px;
                line-height: 25px;
            }
        }

        .mailing-container {
            width: 100%;

            .field-wrapper {
                margin-top: 38px;
                @include desktop() {
                    margin-top: 48px;
                }

                .inputs {
                    display: grid;
                    @include desktop() {
                        grid-template-columns: repeat(3, 32%);
                        grid-gap: 24px;
                    }

                    .inp-primary input {
                        @include desktop(max) {
                            -webkit-border-radius:0;
                            border-radius:0;
                        }
                    }

                    &:first-child {
                        .inp-primary {
                            input {
                                margin-bottom: 25px;
                                @include desktop() {
                                    margin-bottom: 43px;
                                }
                            }
                        }
                    }

                    &:nth-child(2) {
                        .inp-primary {
                            input {
                                @include desktop(max) {
                                    margin-bottom: 28px;
                                }
                            }

                            .selectric-wrapper {
                                .selectric {
                                    @include desktop(max) {
                                        margin-bottom: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}