.terms-policy {
    padding: 18px 0 60px;

    @include desktop {
        padding: 76px 0 120px;
    }

    &__list {
        width: 100%;
        list-style-type: decimal;
        font-size: 14px;
        line-height: 22px;
        font-family: $bodyFont;
        color: $dark-gray;
        border-bottom: 1px solid $dark-gray;
        padding: 0 0 15px 30px;

        @include desktop() {
            font-size: 16px;
            line-height: 1.56;
        }

        &:not(.terms--list) > li {
            margin-bottom: 0 !important;
        }

        li {
            &:not(:last-child) {
                margin-bottom: 15px;
            }

            a:hover {
                color: $honey;
            }

            dl > dd {
                margin-bottom: 10px;
            }
        }

        &.terms--list {
            border-bottom: none;
            padding: 0 0 15px 19px;
            text-align: justify;

            li {
                margin-bottom: 25px;

                ul {
                    list-style-type: disc;
                    padding-left: 30px;
                }

                & > p:first-child {
                    margin-bottom: 10px;

                    & > strong:not(:first-of-type) {
                        margin-left: -18px;
                    }
                }

                &:last-of-type p:nth-child(3) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__container {
        padding: 20px 25px 0;
        text-align: justify;

        & > * {
            font-size: 14px;
            line-height: 22px;
            font-family: $bodyFont;
            color: $dark-gray;
            list-style-type: decimal;
            @include desktop() {
                font-size: 16px;
                line-height: 1.56;
            }
        }

        li a {
            text-decoration: underline;

            &:hover {
                color: $honey;
            }
        }

        & > li {
            margin-bottom: 25px;

            & > strong {
                display: inline-block;
                margin-bottom: 10px;
            }

            a:not(.anchor) {
                text-decoration: underline;
            }

            ul {
                list-style-type: disc;
                padding-left: 30px;
                margin: 10px 0 15px;

                & > li {
                    margin-bottom: 5px;
                }
            }
        }
    }

    &__inner {
        max-width: 750px;
        padding: 0 25px;
        margin: 0 auto;

        &.policy {
            padding: 0 18px;

            @include desktop() {
                padding: 0;
            }
        }

        & > p {
            font-size: 14px;
            line-height: 22px;
            font-family: $bodyFont;
            color: $dark-gray;
            margin-bottom: 22px;
            @include desktop() {
                margin-bottom: 26px;
                font-size: 16px;
                line-height: 1.56;
            }
        }
    }

    &__page-title {
        margin-bottom: 50px;
        font-size: 38px;
        line-height: normal;
        color: $gray;
        font-family: $headingFont;
        text-align: center;
        letter-spacing: 0.95px;

        @include tablet {
            font-size: 54px;
            line-height: 1.13;
            margin-bottom: 100px;
            letter-spacing: 0;
        }

        span {
            display: inline-block;
            position: relative;

            &::after {
                content: '';
                height: 4px;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: $gray;
            }
        }
    }

    &__content {
        font-size: 14px;
        line-height: 22px;
        font-family: $bodyFont;
        color: $dark-gray;
        text-align: justify;

        @include desktop {
            font-size: 16px;
            line-height: 1.56;
        }

        a {
            color: $dark-gray;

            &:hover {
                color: $honey;
            }
        }


        p:not(:last-child) {
            margin-bottom: 22px;

            @include desktop {
                margin-bottom: 26px;
            }

            span {
                text-decoration: underline;
            }
        }
    }
}

.mid-ul li {
    margin-bottom: 0!important;
}