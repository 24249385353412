.inp-primary {
    width: 100%;
    position: relative;
    z-index: 1;

    & > span {
        position: absolute!important;
        right: 0!important;
        top: 5px!important;
        width: 15px;
        height: 13px;
        cursor: pointer;
        transition: .2s all;
        pointer-events: none;

        &.select-chevron {
            z-index: -1;
        }

        & + div[data-lastpass-icon-root] {
            left: -20px !important;
        }
    }

    input, .selectric {
        width: 100%;
        border: unset;
        border-bottom: 1px solid $dark-gray;
        font-family: 'MaisonNeue-Book', sans-serif;
        font-size: 16px;
        line-height: 1.57;
        letter-spacing: normal;
        background-color: transparent;
        margin-bottom: 55px;

        @include desktop() {
            line-height: 1.56;
        }

        &:placeholder {
            color: $baby-grey;
        }
    }

    &:not(.fund__toggle) {
        input:focus,
        &.focus {
            & + label {
                top: -15px;
                font-size: 12px;
            }
        }
    }

    label[for*="password"] {
        + span {
            pointer-events: auto !important;
        }
    }

    & > label {
        font-family: 'MaisonNeue-Book', sans-serif;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: normal;
        color: $dark-cream;
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        transition: top 0.2s linear, font-size 0.2s linear;
        @include desktop() {
            font-size: 16px;
            line-height: 1.56;
        }

        &.focus {
            top: -15px;
            font-size: 10px !important;
            line-height: 1.9 !important;

            @include desktop() {
                font-size: 12px !important;
                line-height: 1.58 !important;
            }
        }
    }

    &.focus {
        z-index: 2;

        label {
            top: -15px;
            font-size: 12px !important;
        }
    }

    label.input-note {
        right: 0;
        top: 0;
        left: auto;
    }

    .selectric-wrapper {
        .selectric {
            .label {
                height: auto;
                min-height: 28px;
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                line-height: 1.57;
                letter-spacing: normal;
                margin-left: 0;
                display: flex;
                align-items: center;
            }

            .button {
                background-color: unset;
                min-height: 28px;
                min-width: 28px;
            }
        }

        &:hover {
            .selectric {
                border-bottom: 1px solid $dark-gray;
            }
        }

        &.selectric-open {
            z-index: 1;

            & ~ img {
                transform: rotate(180deg);
            }
        }
    }
}

.fund__toggle {
    min-height: 36px;

    &:first-child{
       @include desktop() {
           margin-right: 15px;
       }
    }
    &--container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $main-bg;
        max-width: 322px;
        width: 100%;
        margin: 0 auto 41px;
        border-radius: 100px;
        overflow: hidden;
        padding: 0 2px;
        @include desktop() {
            padding: 0 4px;
            margin: 0 auto 62px;
            min-height: 36px;
        }
    }

    input {
        margin: 0;
        width: unset;
        opacity: 0;

        &:checked + label {
            background-color: $honey;
        }

    }

    label {
        font-family: 'MaisonNeue-Extended-Book', sans-serif;
        font-size: 12px;
        color: $gray;
        letter-spacing: 1.2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        cursor: pointer;
        height: 100%;
        transition: background-color .3s;
        @include desktop() {
            font-size: 14px;
            min-width: 153px;
        }
        @include desktop(max){
            width: 100%;
        }
    }
}

.litepicker {
    .month-item {
        .month-item-header {
            .button-previous-month:hover,
            .button-next-month:hover {
                color: #c8b594;
                &> svg {
                    fill: #c8b594;
                }
            }

            & > div {
                display: flex;
                justify-content: space-between;
                select {
                    flex-basis: 45%;
                    border: none;
                    border-bottom: 1px solid #000;
                    outline: none;
                }
            }
        }

        .container__days {
            .day-item {
                &.is-start-date {
                    background-color: #c8b594;
                    &:hover {
                        color: #FFFFFF;
                    }
                }

                &:hover {
                    color: #c8b594;
                    box-shadow: inset 0 0 0 1px #c8b594;
                }
            }
        }
    }
}