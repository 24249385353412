.popup-content-wrapper {
    position: fixed;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
    @extend .hide-scroll;
    @include desktop() {
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .background {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 4;
        height: 100%;
        width: 100%;
        background-color: $popup-bg;
        opacity: 0.4;
    }

    .close {
        position: absolute;
        right: 10px;
        top: 10px;
        bottom: unset;
        left: unset;
        min-width: unset;
        min-height: unset;
        cursor: pointer;
        width: 30px;
        height: 30px;
        @include desktop() {
            right: 25px;
            top: 25px;
        }
    }

    .popup-container {
        position: fixed;
        z-index: 5;
        width: 325px;
        height: 619px;
        max-height: 100%;
        background-color: $white;
        overflow: auto;
        @extend .hide-scroll;
        @include desktop() {
            width: 894px;
            height: 608px;
        }

        & > button {
            position: absolute;
            right: 20px;
            top: 17px;
            padding: 0;
            border: none;
            background-color: transparent;

            img {
                width: 27px;
            }
        }

        &.cash-fee {
            height: 619px;
            @include desktop() {
                height: 583px;
            }

            .cash-fund {
                padding: 88px 18px 0;
                @include desktop() {
                    padding: 63px 18px 0;
                }

                & > h2 {
                    font-family: 'OrpheusPro', sans-serif;
                    font-size: 24px;
                    margin-bottom: 34px;
                    @include desktop() {
                        font-size: 30px;
                        margin-bottom: 28px;
                    }
                }

                .cash-breakdown {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 32px 0;
                    max-width: 426px;
                    width: 100%;
                    border-top: $border;
                    @include desktop() {
                        padding: 46px 0 42px;
                        flex-direction: row;
                        align-items: unset;
                        justify-content: space-between;
                    }

                    &:nth-child(2) {
                        border-top: none;
                    }

                    p {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        line-height: 25px;
                        font-size: 14px;
                        color: $dark-gray;
                        margin-bottom: 10px;
                        text-align: center;
                        @include desktop() {
                            text-align: unset;
                            font-size: 16px;
                            margin-bottom: unset;
                        }
                    }
                }
            }
        }

        .popup-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .popup-text {
                margin-bottom: 60px;
                @include desktop() {
                    margin-bottom: 70px;
                }

                p {
                    text-align: center;

                    &:first-child {
                        font-family: 'OrpheusPro', sans-serif;
                        font-size: 24px;
                        line-height: 30px;
                        margin-bottom: 10px;
                        color: $gray;
                        @include desktop() {
                            font-size: 30px;
                            line-height: 38px;
                            margin-bottom: 15px;
                        }
                    }

                    &:nth-child(2) {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 14px;
                        line-height: 22px;
                        color: $dark-gray;
                        @include desktop() {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }
            }

            & > button {
                margin-bottom: 20px;
                @include desktop() {
                    margin-bottom: 25px;
                }
            }
        }

        .main-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: 54px;

            @include desktop() {
                padding-top: 63px;
            }

            h2 {
                font-family: 'OrpheusPro', sans-serif;
                font-size: 24px;
                line-height: 30px;
                color: $dark-gray;
                margin-bottom: 16px;
                @include desktop() {
                    font-size: 30px;
                    line-height: 38px;
                    margin-bottom: 18px;
                }
            }

            p {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                line-height: 22px;
                color: $dark-gray;
                @include desktop() {
                    font-size: 16px;
                    line-height: 25px;
                }
            }

            & > p {
                margin-bottom: 20px;
                text-align: center;
                @include desktop() {
                    margin-bottom: 28px;
                }
            }

            .info-content {
                max-width: 287px;
                width: 100%;
                display: flex;
                flex-direction: column;
                @include desktop() {
                    max-width: 426px;
                }

                .info {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    padding: 20px 0;
                    @include desktop() {
                        justify-content: space-between;
                        flex-direction: row;
                        padding: 35px 0;
                    }

                    &:nth-child(2) {
                        border-top: $border;
                        border-bottom: $border;
                    }

                    p {
                        margin-bottom: 10px;
                        text-align: center;
                        @include desktop() {
                            margin-bottom: unset;
                            text-align: left;
                        }
                    }
                }
            }

            &.contribute-wrapper {
                h2 {
                    text-align: center;
                    margin-bottom: 27px;
                    @include desktop() {
                        margin-bottom: 39px;
                    }
                }

                .contribute-form {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    max-width: 287px;
                    width: 100%;
                    @include desktop() {
                        max-width: 426px;
                    }

                    p {
                        margin-bottom: 8px;
                        @include desktop() {
                            margin-bottom: 13px;
                        }
                    }

                    input {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        width: 100%;
                        font-size: 16px;
                        line-height: 22px;
                        padding: 10px 35px;
                        border: 1px solid $gray;
                        border-radius: 10px;
                        margin-bottom: 22px;
                        height: 50px;
                        color: $dark-gray;
                        -moz-appearance: textfield;
                        @include desktop() {
                            padding: 9px 33px;
                            margin-bottom: 25px;
                        }

                        &::-webkit-input-placeholder {
                            color: $dark-gray;
                        }

                        &::-moz-placeholder {
                            color: $dark-gray;
                        }

                        &:-ms-input-placeholder {
                            color: $dark-gray;
                        }

                        &::-ms-input-placeholder {
                            color: $dark-gray;
                        }

                        &::placeholder {
                            color: $dark-gray;
                        }

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }

                    .currency-img {
                        position: absolute;
                        top: 38px;
                        left: 17px;
                        @include desktop() {
                            top: 54px;
                        }
                    }

                    textarea {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 14px;
                        color: $dark-gray;
                        resize: none;
                        border: 1px solid $gray;
                        border-radius: 10px;
                        width: 100%;
                        height: 153px;
                        padding: 15px 14px;
                        margin-bottom: 32px;
                        @extend .hide-scroll;
                        @include desktop() {
                            font-size: 16px;
                            height: 147px;
                            padding: 13px 17px;
                            margin-bottom: 39px;
                        }

                        &::-webkit-input-placeholder {
                            color: $dark-cream;
                        }

                        &::-moz-placeholder {
                            color: $dark-cream;
                        }

                        &:-ms-input-placeholder {
                            color: $dark-cream;
                        }

                        &::-ms-input-placeholder {
                            color: $dark-cream;
                        }

                        &::placeholder {
                            color: $dark-cream;
                        }
                    }

                    span {
                        position: relative;

                        span {
                            font-family: 'MaisonNeue-Book', sans-serif;
                            color: $dark-cream;
                            font-size: 14px;
                            line-height: 1.56;
                            position: absolute;
                            top: 120px;
                            right: 17px;
                            @include desktop() {
                                font-size: 16px;
                                top: 112px;
                                right: 21px;
                            }
                        }
                    }

                    .btn-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    &.bank-removal {
        .background {
            opacity: 1;
        }

        .popup-container {
            height: 590px;
            width: 325px;
            @include desktop() {
                height: 608px;
                width: 920px;
            }

            .bank-wrapper {
                padding: 103px 45px 0;
                @include desktop() {
                    padding: 79px 0 68px;
                }

                .bank-img {
                    position: relative;
                    margin-bottom: 24px;
                    width: 103px;
                    height: 98px;

                    & > span {
                        &:first-child {
                            width: 103px;
                            height: 98px;
                        }

                        &:nth-child(2) {
                            position: absolute !important;
                            width: 42px !important;
                            left: 29px !important;
                            top: 25px !important;
                        }
                    }
                }

                & > p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    line-height: 22px;
                    color: $gray;
                    margin-bottom: 39px;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 26px;
                    }

                    small {
                        color: $dark-cream;
                    }
                }

                .bank-subtype {
                    text-transform: capitalize;
                }
            }
        }
    }

    &.fund-wrapper,
    &.contribution,
    &.cashfund,
    &.billing-form,
    &.archive-fund {
        padding: 25px;
        background-color: $popup-bg;
    }

    &.fund-wrapper {
        @include desktop(max) {
            margin-bottom: 0!important;
            padding: 0 !important;
        }

        .fund-content {
            background: $white;
            z-index: 3;
            padding: 43px 25px 30px;
            text-align: center;
            width: 100%;
            overflow: auto;
            
            @include desktop(max){
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 89%;
                height: 100%;
                max-height: 759px;
            }
            
            @include desktop() {
                width: 920px;
                min-height: 640px;
                max-height: 100%;
                padding: 34px 78px 48px;
                position: relative;
            }

            &-container {
                @include desktop(max) {
                    max-height: 600px;
                    overflow: auto;
                }
            }

            .message-counter {
                position: relative;

                & > label {
                    font-family: "MaisonNeue-Book", sans-serif;
                    font-size: 16px;
                    color: $dark-cream;
                    position: absolute;
                    top: -45px;
                    right: 13px;
                    @include desktop() {
                        right: 20px;
                        top: -85px;
                    }
                }
            }

            .inp-wrapper {
                @include desktop() {
                    display: flex;
                    .selectric-items {
                        width: 100% !important;
                        height: 310px !important;
                    }
                }

                .fund-inputs {
                    @include desktop() {
                        display: flex;
                        width: 100%;
                        margin-right: 0 !important;
                    }
                    @include desktop(max) {
                        display: flex;
                        flex-direction: column;
                    }

                }

                @include desktop(max) {
                    .inp-primary {
                        .selectric-wrapper {
                            margin-bottom: 0;

                            .selectric {
                                margin-bottom: 30px;
                            }
                        }
                    }
                }

                &.service-item {
                    .service-item-inputs {
                        width: 100%;
                        display: flex;
                        margin-right: 0;
                        @include desktop(max) {
                            flex-direction: column;
                        }

                        & > div:first-child {
                            @include desktop() {
                                width: 50%;
                                margin-right: 0;
                            }
                        }

                        .service-item-input {
                            @include desktop() {
                                width: 50%;
                                display: flex;
                                & > div {
                                    margin-right: 0;
                                    margin-left: 77px;
                                }
                            }
                            @include desktop(max) {
                                .quantity {
                                    button:last-child > span {
                                        margin-left: 15px !important;
                                    }
                                }
                            }

                            .quantity {
                                width: 100%;
                                position: relative;
                                @include desktop(max) {
                                    margin: 7px 0 25px;
                                    border-bottom: 1px solid $gray;
                                    .inp-primary {
                                        max-width: 82px;

                                        input {
                                            border-bottom: none;
                                            margin-bottom: 0;
                                        }
                                    }
                                }

                                input {
                                    text-align: center;
                                }

                                button {
                                    position: absolute;
                                    z-index: 2;
                                    display: block;

                                    &:first-of-type {
                                        left: 0;
                                        top: -3px;
                                        @include desktop(max) {
                                            margin-right: 10px;
                                            left: -7px;
                                            top: 2px;
                                        }

                                        img {
                                            @include desktop() {
                                                left: -8px;
                                                top: 4px;
                                            }
                                        }
                                    }

                                    &:last-of-type {
                                        top: 0;
                                        @include desktop() {
                                            right: 0;
                                        }
                                        @include desktop(max) {
                                            left: 44px;
                                        }

                                        & > img {
                                            top: 2px !important;
                                            right: -5px !important;
                                            @include desktop(max) {
                                                top: 2px !important;
                                                right: -19px !important;
                                            }
                                        }
                                    }

                                    & > img {
                                        width: unset !important;
                                        height: unset !important;
                                        position: relative !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .selectric-wrapper {
                    width: 100%;
                }

                input {
                    &:not([type='radio']):not([type='checkbox']) {
                        border-radius: 0;
                        -webkit-appearance: none;
                    }

                    &::placeholder {
                        color: $dark-cream;
                    }

                    margin-bottom: 20px;
                    @include desktop() {
                        margin-bottom: 38px;
                    }
                }

                //div:first-child {
                //    @include desktop() {
                //        margin-right: 70px;
                //    }
                //}
            }

            .custom--item {
                input {
                    margin-bottom: 38px;
                    @include desktop(max) {
                        margin-bottom: 20px;
                    }
                }
            }

            .btn-stroke {
                margin: 0 auto 27px;
                width: 100%;

                @include tablet() {
                    width: 295px;
                }

                &.disabled, &:disabled {
                    color: $dark-cream !important;
                    border: 1px solid $light-gray !important;
                    background-color: transparent !important;
                }
            }

            h3 {
                font-family: 'OrpheusPro', sans-serif;
                font-size: 24px;
                line-height: 1.27;
                letter-spacing: normal;
                color: $gray;
                margin-bottom: 38px;
                @include desktop() {
                    margin-bottom: 46px;
                    font-size: 30px;
                }

                img {
                    width: 20px;
                    height: 20px;
                    margin-left: 15px;
                }
            }

            .text-wrapper {

                //overflow: hidden;
                height: 227px;
                margin-top: 11px;
                margin-bottom: 26px;

                @include desktop() {
                    margin-top: unset;
                    height: unset;
                    padding: 4px;
                    margin-bottom: 51px;
                }

                &.serv-wrapper {
                    @include desktop(max) {
                        height: 227px;
                    }
                }
            }

            textarea {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 16px;
                line-height: 1.56;
                letter-spacing: normal;
                color: $gray;
                border-radius: 10px;
                border: 1px solid $gray;
                padding: 15px 18px;
                text-align: left;
                height: 100%;
                width: 100%;
                resize: none;
                @extend .body-scroll;
                @include desktop() {
                    height: 156px;
                    padding: 11px;
                }
            }

            img {
                -o-object-fit: contain;
                object-fit: contain;
            }

            .btn-stroke {
                margin-top: 0;
            }
        }
    }

    &.contributors {
        display: none;
        z-index: 15;
        background-color: $popup-bg;

        &.open {
            display: flex;
        }

        .contributor-popup {
            position: relative;
            background-color: $white;
            max-width: 325px;
            width: 100%;
            border-radius: 10px;
            padding: 65px 18px;
            overflow-y: auto;
            @include desktop() {
                max-width: 895px;
                border: $border;
                padding: 55px;
            }

            .contributor-popup-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                margin-bottom: 23px;
                @include desktop() {
                    flex-direction: row;
                }

                h2 {
                    font-family: 'OrpheusPro', sans-serif;
                    font-size: 24px;
                    line-height: 1.25;
                    letter-spacing: normal;
                    color: $gray;
                    @include desktop() {
                        font-size: 30px;
                        line-height: 1.27;
                    }
                }

                p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: $gray;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 1.56;
                    }

                    & > a {
                        text-decoration: underline;
                        color: $dark-gray;
                    }
                }


                & > span {
                    width: 25px;
                    height: 25px;
                    -o-object-fit: contain;
                    object-fit: contain;
                    position: absolute!important;
                    right: 20px!important;
                    top: 20px!important;
                    cursor: pointer;
                }
            }

            .content-wrapper {
                border: $border;
                border-radius: 10px;

                .contributor-popup-content {
                    display: grid;
                    @include desktop() {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    & > div {
                        border-bottom: $border;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 14px 10px;
                        @include desktop() {
                            border-right: $border;
                            min-height: 140px;
                            padding: 0 50px;
                        }

                        & > div {
                            @include desktop() {
                                height: 50px;
                            }
                        }

                        &:first-child {
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                        }

                        &:nth-child(2),
                        &:nth-child(4) {
                            border-right: unset;
                        }

                        &:nth-child(2) {
                            a {
                                img {
                                    width: 20px;
                                }
                            }
                        }

                        &:nth-child(3) {
                            a {
                                img {
                                    width: 16px;
                                }
                            }
                        }

                        &:nth-child(4) {
                            a {
                                img {
                                    width: 14px;
                                }
                            }
                        }
                    }

                    span,
                    p,
                    a {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 14px;
                        line-height: 1.57;
                        letter-spacing: normal;
                        color: $black;
                        display: block;
                        @include desktop() {
                            font-size: 16px;
                            line-height: 1.56;
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: underline;

                        img {
                            margin-right: 8px;
                        }
                    }

                    span {
                        color: $dark-cream;
                    }
                }
            }
        }
    }

    &.contribution {
        .contribution-content {
            position: relative;
            width: 100%;
            background-color: $white;
            padding: 70px 20px;
            overflow-y: auto;
            max-height: 100%;
            @include desktop() {
                padding: 90px 0;
                width: 809px;
            }

            form {
                display: flex;
                flex-direction: column;
                max-width: 426px;
                margin: 0 auto;

                h3 {
                    font-family: 'OrpheusPro', sans-serif;
                    font-size: 24px;
                    line-height: 1.25;
                    color: $gray;
                    margin-bottom: 30px;
                    text-align: center;
                    @include desktop() {
                        margin-bottom: 43px;
                        font-size: 30px;
                        line-height: 1.27;
                    }
                }

                input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
                    font-family: 'MaisonNeue-book', sans-serif;
                    font-size: 16px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    @include desktop() {
                        line-height: 1.56;
                    }
                }

                input::-moz-placeholder, textarea::-moz-placeholder {
                    font-family: 'MaisonNeue-book', sans-serif;
                    font-size: 16px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    @include desktop() {
                        line-height: 1.56;
                    }
                }

                input:-ms-input-placeholder, textarea:-ms-input-placeholder {
                    font-family: 'MaisonNeue-book', sans-serif;
                    font-size: 16px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    @include desktop() {
                        line-height: 1.56;
                    }
                }

                input::-ms-input-placeholder, textarea::-ms-input-placeholder {
                    font-family: 'MaisonNeue-book', sans-serif;
                    font-size: 16px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    @include desktop() {
                        line-height: 1.56;
                    }
                }

                input,
                input::placeholder,
                textarea,
                textarea::placeholder {
                    font-family: 'MaisonNeue-book', sans-serif;
                    font-size: 16px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    @include desktop() {
                        line-height: 1.56;
                    }
                }

                input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
                    color: $dark-cream;
                }

                input::-moz-placeholder, textarea::-moz-placeholder {
                    color: $dark-cream;
                }

                input:-ms-input-placeholder, textarea:-ms-input-placeholder {
                    color: $dark-cream;
                }

                input::-ms-input-placeholder, textarea::-ms-input-placeholder {
                    color: $dark-cream;
                }

                input::placeholder,
                textarea::placeholder {
                    color: $dark-cream;
                }

                input {
                    height: 50px;
                    flex-grow: 0;
                    margin: 0 0 25px;
                    padding: 9px 16px;
                    border-radius: 10px;
                    border: solid 1px $gray;
                    background-color: $white;
                    color: $dark-gray;
                }

                textarea {
                    height: 147px;
                    resize: none;
                    flex-grow: 0;
                    padding: 13px 18px 14px 17px;
                    border-radius: 10px;
                    border: solid 1px $gray;
                    margin-bottom: 39px;
                    color: $dark-gray;
                }

                label {
                    height: 25px;
                    font-family: 'MaisonNeue-book', sans-serif;
                    font-size: 14px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: $dark-gray;
                    text-align: left;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 1.56;
                        margin-bottom: 13px;
                    }
                }

                .minimum-amount, .message-counter {
                    position: relative;

                    label {
                        font-family: "MaisonNeue-Book", sans-serif;
                        color: $dark-cream;
                        position: absolute;
                        right: 21px;
                        font-size: 14px;
                        z-index: 1;
                        @include desktop() {
                            right: 24px;
                            font-size: 16px;
                        }
                    }
                }

                .minimum-amount {
                    label {
                        top: -58px;
                        @include desktop() {
                            top: -62px;
                        }
                    }
                }

                .message-counter {
                    label {
                        top: -75px;
                        @include desktop() {

                        }
                    }
                }

                .btn-stroke {
                    align-self: center;
                    width: 178px;
                }
            }
        }
    }

    &.cashfund {
        .popup-content {
            position: relative;
            z-index: 5;
            width: 325px;
            height: 619px;
            background-color: $white;
            max-height: 100%;
            overflow-y: auto;
            @include desktop() {
                width: 894px;
                height: 583px;
            }

            h3 {
                font-family: 'OrpheusPro', sans-serif;
                font-size: 24px;
                line-height: 1.25;
                letter-spacing: normal;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                color: $gray;
                text-align: center;
                margin-top: 88px;
                margin-bottom: 31px;
                @include desktop() {
                    margin-top: 63px;
                    margin-bottom: 28px;
                    font-size: 30px;
                    line-height: 1.27;
                }
            }

            .close {
                position: absolute;
                right: 10px !important;
                top: 10px !important;
                bottom: unset !important;
                left: unset !important;
                min-width: unset !important;
                min-height: unset !important;
                cursor: pointer;
                width: 30px !important;
                height: 30px !important;
                @include desktop() {
                    right: 25px !important;
                    top: 25px !important;
                }
            }

            .cash-breakdown {
                display: flex;
                flex-direction: column;
                text-align: center;
                padding: 32px 0;

                p:first-child {
                    margin-bottom: 10px;
                }

                p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    line-height: 1.57;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    color: $dark-gray;
                }

                @include desktop() {
                    margin: 0 234px;
                    padding: 0;
                    flex-direction: row;
                    text-align: left;
                    p:first-child {
                        flex: 1;
                        margin-bottom: 0;
                    }

                    p {
                        font-size: 16px;
                        line-height: 1.56;
                        padding: 46px 0;
                    }
                }
            }

            .cash-breakdown:last-child p:last-child {
                margin-top: 20px !important;
                @include desktop() {
                    margin-top: 0 !important;
                }
            }

            .divider {
                width: 80px;
                border: 1px solid $light-gray;
                margin: auto auto;
                @include desktop() {
                    margin: 0 234px;
                    width: auto;
                }
            }
        }
    }

    &.billing-form {

        &.account-info {
            background-color: $main-bg;
            padding: 0 25px;
            @include desktop() {
                padding: 0;
            }

            .billing-form-content {
                width: 100%;
                @include desktop() {
                    height: 100vh;
                    max-width: 1204px;
                    margin: auto;
                }
            }
        }

        &.connect-bank {
            .billing-form-content {
                height: unset;
            }
        }

        .billing-form-content {
            position: relative;
            background-color: $white;
            padding: 40px 25px;
            width: 100%;
            max-height: 100%;
            height: 100%;
            overflow-y: auto;
            @include desktop() {
                width: 800px;
                min-height: 209px;
            }

            .info-wrapper {
                height: 446px;
                overflow: auto;
                margin-bottom: 30px;
                @include desktop() {
                    height: unset;
                    margin-bottom: 0;
                    overflow: visible;
                }
            }

            .button-container {
                display: flex;
                flex-direction: column;
                max-width: 565px;
                margin: 0 auto;

                h3 {
                    font-family: 'OrpheusPro', sans-serif;
                    font-size: 24px;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    line-height: 1.25;
                    color: $gray;
                    margin-bottom: 30px;
                    text-align: center;
                    @include desktop() {
                        margin-bottom: 40px;
                        width: 390px;
                        font-size: 30px;
                        line-height: 1.27;
                    }
                }

                .inp-primary {
                    input {
                        margin-bottom: 35px;

                        &:not([type='radio']):not([type='checkbox']) {
                            border-radius: 0;
                            -webkit-appearance: none;
                        }
                    }

                    .selectric {
                        margin-bottom: 35px;
                    }
                }

                .btn-stroke {
                    align-self: center;
                }

                .close {
                    width: 27px;
                    position: absolute;
                    top: 10px;
                    right: 17px;
                    cursor: pointer;
                    @include desktop() {
                        top: 30px;
                        right: 37px;
                    }

                    img {
                        width: 27px;
                    }
                }

                .tos-accept {
                    margin-bottom: 35px;
                    display: flex;
                    align-items: center;

                    input {
                        margin: 0;
                        width: -webkit-max-content;
                        width: -moz-max-content;
                        width: max-content;
                        opacity: 0;

                        &:checked + label:before {
                            background-color: $honey;
                            border: 1px solid $honey;
                        }

                        &:focus {
                            & + label {
                                top: unset;
                                font-size: 14px;
                                @include desktop() {
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    label,
                    a {
                        padding-left: 13px;
                        position: relative;
                        white-space: nowrap;
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 14px;
                        line-height: 1.57;
                        letter-spacing: normal;
                        color: $dark-gray;
                        @include desktop() {
                            font-size: 16px;
                            line-height: 1.56;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            left: -13px;
                            top: 3px;
                            width: 15px;
                            height: 15px;
                            border: 1px solid $dark-gray;
                            cursor: pointer;
                        }
                    }

                    a {
                        padding-left: 5px;
                        text-decoration: underline;

                        &::before {
                            content: unset;
                        }
                    }
                }

                .dropdown-wrapper {
                    position: relative;

                    .inp-primary > div {
                        position: absolute !important;
                        top: 0;
                        right: 0;
                        z-index: 1;

                        img {
                            width: 15px !important;
                            min-width: unset !important;
                        }
                    }

                    &.active {
                        .dropdown {
                            display: block;
                        }

                        .inp-primary > div {
                            img {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                .dropdown {
                    position: absolute;
                    border: $border;
                    border-top: unset;
                    display: none;
                    z-index: 2;
                    left: 0;
                    top: 27px;
                    padding: 10px;
                    background-color: $white;
                    width: 100%;
                    height: 130px;
                    overflow: auto;
                    @include desktop() {
                        padding: 15px;
                        height: 150px;
                    }

                    p {
                        cursor: pointer;
                        margin-bottom: 5px;
                    }
                }

                .social-security-wrapper {
                    padding: 19px 27px 19px 21px;
                    background-color: $main-bg;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    border-radius: 10px;
                    @include desktop() {
                        padding: 20px 38px 20px 23px;
                    }

                    & > div {
                        display: flex;
                        margin-bottom: 11px;
                        align-items: center;

                        div {
                            & > span {
                                margin-right: 12px !important;
                                width: 17px;
                            }
                        }
                    }

                    p {
                        font-family: "MaisonNeue-Book", sans-serif !important;
                        font-size: 14px !important;
                        text-align: left !important;
                        margin-left: 0 !important;
                    }
                }
            }
        }
    }

    &.archive-fund {
        .archive-content {
            background-color: $white;
            z-index: 3;
            padding: 70px 0 25px;
            text-align: center;
            position: relative;
            width: 100%;
            min-height: 538px;
            overflow-y: auto;
            @include desktop() {
                min-height: 583px;
                width: 920px;
                padding: 74px 78px 48px;
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 55px;
                @include desktop() {
                    padding-top: 80px;
                    max-width: 480px;
                    margin: auto;
                }
            }

            h3 {
                font-family: "OrpheusPro", sans-serif;
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 35px;
                @include desktop() {
                    margin-bottom: 27px;
                    font-size: 30px;
                    line-height: 38px;
                }
            }

            p {
                font-family: "MaisonNeue-Book", sans-serif;
                font-size: 14px;
                line-height: 22px;
                padding: 0 25px;
                margin-bottom: 23px;
                @include desktop() {
                    padding: unset;
                    margin-bottom: 40px;
                    font-size: 16px;
                    line-height: 25px;
                }
            }

            .btn-stroke {
                margin-bottom: 25px;
                display: block !important;
            }
        }
    }
}

.account-popup-wrapper {
    background-color: $main-bg;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 0 25px;
    z-index: 5;
    @extend .hide-scroll;
    @include desktop() {
        padding: 0 120px;
        overflow: auto;
    }

    .account-popup {
        background-color: $white;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;

        &.center-popup {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .account-popup-header {
            //img {
            //    -o-object-fit: contain;
            //    object-fit: contain;
            //    position: absolute;
            //
            //    &.logo {
            //        width: 70px;
            //        left: 0;
            //        right: 0;
            //        margin: auto;
            //        top: 45px;
            //    }
            //
            //    &.close {
            //        width: 27px;
            //        top: 9px;
            //        right: 9px;
            //        cursor: pointer;
            //        @include desktop() {
            //            top: 30px;
            //            right: 40px;
            //        }
            //    }
            //}

            .logo {
                span {
                    position: absolute !important;
                    width: 70px !important;
                    left: 0 !important;
                    right: 0 !important;
                    margin: auto !important;
                    top: 45px !important;
                    height: 27px !important;
                }

                //img {
                //    width: 70px;
                //    left: 0;
                //    right: 0;
                //    margin: auto;
                //    top: 45px;
                //}
            }

            .close {
                span {
                    width: 27px !important;
                    height: 27px !important;
                    top: 9px !important;
                    right: 9px !important;
                    cursor: pointer;
                    position: absolute !important;
                    @include desktop() {
                        top: 30px !important;
                        right: 40px !important;
                    }
                }

                //img {
                //    width: 27px;
                //    top: 9px!important;
                //    right: 9px!important;
                //    cursor: pointer;
                //    @include desktop() {
                //        top: 30px!important;
                //        right: 40px!important;
                //    }
                //}
            }
        }

        .account-popup-content {
            padding-top: 190px;
            display: grid;
            justify-content: center;
            align-items: center;
            @include desktop() {
                display: flex;
                flex-direction: column;
                padding-top: 235px;
            }

            h1 {
                font-family: 'OrpheusPro', sans-serif;
                font-size: 24px;
                line-height: 1.25;
                letter-spacing: normal;
                color: $gray;
                text-align: center;
                margin-bottom: 34px;
                @include desktop() {
                    font-size: 30px;
                    line-height: 1.27;
                    margin-bottom: 60px;
                }
            }

            & > div {
                display: flex;
                margin-bottom: 60px;
                flex-direction: column;
                @include desktop() {
                    flex-direction: row;
                    margin-bottom: 80px;
                }

                @include desktop(max){
                    margin-right: auto;
                    margin-left: auto;
                    width: fit-content;
                }

                & > div {
                    text-align: center;

                    &:first-child {
                        border-bottom: $border;
                        padding: 0 0 30px;
                        @include desktop() {
                            border-bottom: unset;
                            border-right: $border;
                            padding: 0 50px 0 0;
                        }
                    }

                    &:last-child {
                        padding: 30px 0 0;
                        @include desktop() {
                            padding: 0 0 0 50px;
                        }
                    }

                    h2 {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 10px;
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 14px;
                        line-height: 1.57;
                        letter-spacing: normal;
                        color: $dark-gray;
                        @include desktop() {
                            font-size: 16px;
                            line-height: 1.56;
                        }
                    }

                    p {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 10px;
                        line-height: 1.9;
                        letter-spacing: normal;
                        text-align: center;
                        color: $dark-gray;
                        @include desktop() {
                            font-size: 12px;
                            line-height: 1.58;
                        }
                    }

                    span {
                        margin-right: 10px !important;
                    }

                    img {
                        height: 18px;
                        width: 18px;
                        -o-object-fit: contain;
                        object-fit: contain;
                        margin-right: 10px;
                    }
                }
            }

            .btn-stroke {
                margin-bottom: 20px;
            }

            span,
            span > a {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 10px;
                line-height: 1.9;
                letter-spacing: normal;
                text-align: center;
                color: $dark-gray;
                @include desktop() {
                    font-size: 12px;
                    line-height: 1.58;
                }
            }

            .bank-list-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                @include desktop() {
                    max-width: 483px;
                }

                .form-wrapper {
                    display: flex;
                    justify-content: center;
                    padding: 0 19px;
                    @include desktop() {
                        display: unset;
                        border-right: unset;
                        padding: 0;
                    }

                    form {
                        margin-bottom: 48px;
                    }
                }

                .bank-list {
                    padding: 0;
                    height: calc(100vh - 143px);
                    overflow: auto;

                    .bank {
                        background-color: transparent;
                        width: 100%;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border: none;
                        border-bottom: $border;
                        padding: 12px 4px;
                        @include desktop() {
                            padding: 17px 2px;
                        }

                        &:first-child {
                            border: none;
                            border-top: $border;
                            border-bottom: $border;
                        }

                        .bank-info {
                            display: flex;
                            align-items: center;

                            img {
                                width: 45px;
                                height: 47px;
                                margin-right: 14px;
                                -o-object-fit: contain;
                                object-fit: contain;
                            }

                            p {
                                font-family: 'MaisonNeue-Extended-Book', sans-serif;
                                font-size: 12px;
                                line-height: 17px;
                                color: $dark-gray;
                                text-transform: uppercase;
                                @include desktop() {
                                    font-size: 14px;
                                }
                            }
                        }

                        img {
                            width: 26px;
                            height: 26px;
                            -o-object-fit: contain;
                            object-fit: contain;
                            margin-right: unset;
                            @include desktop() {
                                width: 44px;
                                height: 44px;
                            }
                        }
                    }
                }
            }

            &.success {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0 19px;
                @include desktop() {
                    padding: 0;
                }

                h1 {
                    margin-bottom: 17px;
                }

                p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    line-height: 25px;
                    margin-bottom: 52px;
                    text-align: center;
                    @include desktop() {
                        font-size: 16px;
                        margin-bottom: 56px;
                    }
                }
            }

            &.bank-content {
                padding-top: 123px;
                @include desktop() {
                    padding-top: 143px;
                }

                h1 {
                    margin-bottom: 22px;
                    @include desktop() {
                        margin-bottom: 32px;
                    }
                }
            }

            &.bank-account-setup {
                padding-top: 0;

                & > img {
                    width: 235px;
                    margin-bottom: 37px;
                    @include desktop() {
                        margin-bottom: 59px;
                    }
                }

                .container-wrapper,
                .verify-container {
                    .text-container {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 51px;
                        @include desktop() {
                            margin-bottom: 54px;
                        }

                        p {
                            text-align: center;

                            &:first-child {
                                font-family: 'OrpheusPro', sans-serif;
                                font-size: 24px;
                                line-height: 30px;
                                margin-bottom: 10px;
                                @include desktop() {
                                    font-size: 30px;
                                    line-height: 38px;
                                    margin-bottom: 13px;
                                }
                            }

                            &:nth-child(2),
                            &:nth-child(3) {
                                font-family: 'MaisonNeue-Book', sans-serif;
                                font-size: 10px;
                                line-height: 19px;
                                color: $dark-gray;
                                @include desktop() {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    .credentials-form {
                        padding: 0 24px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @include desktop() {
                            max-width: 439px;
                            padding: 0;
                        }

                        .inp-primary {
                            input {
                                position: relative;
                                z-index: 5;
                            }

                            label {
                                z-index: 1;
                            }

                            &:nth-child(2) {
                                margin-bottom: 7px;
                                @include desktop() {
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }

                .verify-container {
                    width: 100%;
                    @include desktop() {
                        max-width: 483px;
                    }

                    &.code {
                        @include desktop() {
                            max-width: 439px;
                        }

                        .text-container {
                            margin-bottom: 70px;
                        }

                        .credentials-form {
                            padding: 0 27px;
                            @include desktop() {
                                padding: 0;
                            }
                        }

                        .inp-primary {
                            margin-bottom: 15px;
                            @include desktop() {
                                margin-bottom: 26px;
                            }
                        }
                    }

                    .text-container {
                        margin-bottom: 38px;
                        @include desktop() {
                            margin-bottom: 51px;
                        }
                    }

                    .credentials-form {
                        @include desktop() {
                            margin-top: 70px;
                        }

                        .btn-fill {
                            margin-bottom: 42px;
                            @include desktop() {
                                margin-bottom: 56px;
                            }
                        }
                    }

                    .verify-form {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 0 28px;
                        @include desktop() {
                            padding: 0;
                        }

                        .verify-option-container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            padding: 24px 0;
                            @include desktop() {
                                padding: 26px;
                                max-width: 483px;
                            }

                            &:nth-child(2) {
                                border-top: $border;
                                margin-bottom: 19px;
                                @include desktop() {
                                    margin-bottom: 29px;
                                }
                            }

                            & > div {
                                display: flex;
                                align-items: center;

                                .checkbox-wrapper {
                                    & > label {
                                        top: -35px;
                                    }
                                }

                                .verify-text {
                                    margin-left: 16px;
                                    @include desktop() {
                                        margin-left: 27px;
                                    }

                                    p {
                                        font-family: 'MaisonNeue-Book', sans-serif;
                                        font-size: 16px;
                                        line-height: 25px;
                                        text-align: left;

                                        &:first-child {
                                            color: $dark-gray;
                                            margin-bottom: 2px;
                                        }

                                        &:nth-child(2) {
                                            color: $dark-cream;
                                        }
                                    }
                                }
                            }

                            & > img {
                                width: 14px;
                                height: 21px;
                            }

                            .email-icon {
                                width: 21px;
                                height: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.setup-security {
        .account-popup {
            max-width: 1204px;
            margin: auto;
            position: relative;
            min-height: 720px;
            display: flex;
            justify-content: center;

            .account-popup-header {
                img {
                    -o-object-fit: contain;
                    object-fit: contain;
                    position: absolute;

                    &.logo {
                        max-width: 66px;
                        top: 40px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        @include desktop() {
                            top: 30px;
                        }

                    }

                    &.close {
                        cursor: pointer;
                        width: 27px;
                        top: 10px;
                        right: 10px;
                        @include desktop() {
                            top: 30px;
                            right: 30px;
                        }
                    }
                }
            }

            .account-popup-content {
                padding-top: 53px !important;
                @include desktop() {
                    padding-top: 50px !important;
                }

                @include desktop(max){
                    height: calc(100vh - 130px);
                    overflow: auto;

                    .content-logo, button {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                & > h1 {
                    margin-bottom: 20px;
                }

                & > p {
                    font-family: "MaisonNeue-Book", sans-serif;
                    font-size: 16px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    text-align: center;
                    color: #464646;
                    margin-bottom: 80px;

                    a {
                        text-decoration: underline;
                        text-decoration-color: #464646;
                    }

                    &.connect-text {
                        @include desktop(max) {
                            font-size: 14px;
                            padding: 0 28px;
                            line-height: 22px;
                            br {
                                display: none !important;
                            }
                        }
                    }
                }

                .content-logo {
                    display: flex;
                    align-items: center;
                    margin-bottom: 38px;
                    flex-direction: row;
                    min-height: 28px;

                    h1 {
                        margin-bottom: 41px;
                        @include desktop() {
                            margin-bottom: 38px;
                        }
                    }

                    span {

                        &:first-child {
                            margin-right: 13px !important;
                            @include desktop() {
                                margin-right: 18px !important;
                            }
                        }
                    }

                    svg, img {
                        &:first-child {
                            width: 43px;
                            margin-right: 13px;
                            @include desktop() {
                                width: 63px;
                                margin-right: 18px;
                            }
                        }

                        &:nth-child(2) {
                            width: 55px;
                            @include desktop() {
                                width: 74px;
                            }
                        }
                    }
                }

                div:nth-child(3) {
                    margin-bottom: 48px;
                    @include desktop() {
                        margin-bottom: 59px;
                    }
                }

                .btn-fill {
                    margin-top: 0;
                }
            }
        }
    }
}

input::-webkit-inner-spin-button {
    display: none !important;
}


.plaid-buttons {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
}
