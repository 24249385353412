.signup-wrapper {
    background-color: $cream;
    padding-top: 92px;
    @include desktop() {
        padding-top: 168px;
        min-height: 1055px;
    }

    &.popup {
        @include desktop() {
            padding-right: 17px;
        }
    }

    .inp-primary {
        input {
            &:not([type='radio']):not([type='checkbox']) {
                border-radius: 0;
                -webkit-appearance: none;
            }
        }
    }

    .dropdown-wrapper {
        position: relative;
        width: 100%;

        &.active {
            .dropdown {
                display: block;
            }

            .inp-primary {
                img {
                    transform: rotate(180deg);
                }
            }
        }

        .inp-primary {
            & > div {
                position: static !important;

                img {
                    inset: unset !important;
                    right: 0 !important;
                    top: 7px !important;
                    width: 15px !important;
                    height: 8px !important;
                    cursor: pointer !important;
                    max-width: unset !important;
                    max-height: unset !important;
                    min-width: unset !important;
                    min-height: unset !important;
                }
            }
        }

        p {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: normal;
            color: $dark-gray;
            cursor: pointer;
            margin-bottom: 10px;
            @include desktop() {
                font-size: 16px;
                line-height: 1.56;
            }

            &:hover {
                color: $honey;
            }
        }

        .dropdown {
            position: absolute;
            border: $border;
            border-top: unset;
            display: none;
            z-index: 2;
            left: 0;
            top: 27px;
            padding: 10px;
            width: calc(50% - 31px);
            background-color: $white;
            width: 100%;
            max-height: 190px;
            overflow-y: auto;
            @include desktop() {
                padding: 15px;
            }

            &::-webkit-scrollbar {
                height: 0;
                width: 0;

                &-track {
                    background-color: transparent;
                }

                &-thumb {
                    background-color: transparent;
                }
            }
        }
    }

    .due-date-input {
        display: flex;
        @media (min-width: 1024px) and (max-width: 1299.8px) {
            flex-direction: column;
            margin-bottom: 28px;
            input {
                margin-bottom: 0 !important;
            }
            .checkbox-button {
                padding-left: 5px;
            }
            .checkbox-container {
                justify-content: flex-start;
            }
        }

        .inp-primary {
            @include xl() {
                flex-basis: 50%;
            }
        }

        .checkbox-button {
            flex-basis: 50%;
            padding-top: 10px;
            padding-left: 10px;

            .checkbox-wrapper {
                margin-right: 10px;
            }
            p {
                white-space: nowrap;
                @media (min-width: 1024px) and (max-width: 1439.8px) {
                    letter-spacing: 1px;
                }
            }
        }
    }

    .popup-content-wrapper {
        .main-content {
            padding-top: 80px;
        }

        .btn-none {
            position: absolute;
            right: 10px;
            top: 10px;
            @include desktop() {
                right: 20px;
                top: 20px;
            }

            img {
                width: 27px !important;
                -o-object-fit: contain;
                object-fit: contain;
            }
        }

        .info-content {
            .info {
                border: unset !important;

                &:nth-child(2) {
                    position: relative;

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        height: 1px;
                        width: 90px;
                        background-color: $light-gray;
                        @include desktop() {
                            width: 100%;
                        }
                    }

                    &:before {
                        top: 0;
                    }

                    &:after {
                        bottom: 0;
                    }
                }
            }
        }
    }

    .signup-content {
        width: 100%;
        display: flex;
        padding: 0 25px;

        @include desktop() {
            justify-content: space-between;
            padding: 0 100px;
            max-width: 1440px;
            margin: auto;
        }

        .left-side {
            width: 100%;
            padding-top: 50px;
            padding-bottom: 80px;
            @include xm() {
                padding-top: 0;
                margin-right: 30px;
            }
            @include desktop() {
                width: 50%;
                padding-bottom: unset;
                max-width: 530px;
            }
            @include lg() {
                padding-top: 50px;
            }

            .text-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                @include desktop() {
                    display: unset;
                }

                h2 {
                    font-family: 'OrpheusPro', sans-serif;
                    font-size: 24px;
                    line-height: 30px;
                    text-align: left;
                    margin-bottom: 14px;
                    color: $dark-gray;
                    @include desktop() {
                        font-size: 30px;
                        line-height: 38px;
                        margin-bottom: 13px;
                        max-width: 99%;
                    }
                }

                p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    line-height: 22px;
                    color: $dark-gray;
                    margin-bottom: 25px;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 25px;
                    }

                    &:last-of-type {
                        margin-bottom: 50px;
                    }

                    a {
                        text-decoration: underline;
                    }
                }

                .img-wrapper {
                    width: 325px;
                    margin-bottom: 61px;
                }
            }
        }

        .form-wrapper {
            .form-container {
                border-bottom: $border;
                transition: height 0.3s ease;
                padding: 24px 0;
                @include desktop() {
                    padding: 28px 0;
                }

                &:first-of-type {
                    border-top: $border;
                }

                &:last-of-type {
                    border-bottom: unset;
                }

                &.collapse {
                    height: 70px;
                    overflow: hidden;
                    @include desktop(max){
                        height: 65px;
                    }
                }

                & > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                p {
                    font-family: 'MaisonNeue-Extended-Book', sans-serif;
                    font-size: 12px;
                    letter-spacing: 1.2px;
                    text-align: left;
                    color: $dark-gray;
                    @include desktop() {
                        font-size: 14px;
                        letter-spacing: 1.4px;
                    }
                }

                .btn-none {
                    img {
                        width: 15px;
                    }
                }

                .inner-form {
                    margin-top: 38px;
                    width: 100%;
                    @include desktop() {
                        margin-top: 55px;
                    }
                    &.how-you-hear-step {
                        .inner-container {
                            width: 100%;

                            .form-textarea {
                                position: relative;
                                margin-top: 35px;

                                textarea {
                                    font-size: 16px;
                                    background-color: $cream;
                                    height: 147px;
                                    padding: 13px 17px;

                                    @include desktop(max){
                                        height: 134px;
                                        padding: 17px 23px;
                                    }
                                }

                                .message-counter {
                                    font-family: 'MaisonNeue-Extended-Book', sans-serif;
                                    position: absolute;
                                    right: 20px;
                                    bottom: 15px;
                                    color: $dark-cream;
                                }
                            }
                        }
                    }

                    .form-inp-wrap {
                        flex-direction: column;
                        display: flex;

                        @include desktop() {
                            flex-direction: row;
                        }

                        .inp-primary {
                            &:nth-child(odd) {
                                @include desktop() {
                                    margin-right: 31px;
                                }
                            }

                            input {
                                margin-bottom: 30px;
                                @include desktop() {
                                    margin-bottom: 55px;
                                }

                                &:focus {
                                    & + label {
                                        top: -15px;
                                        font-size: 12px;
                                    }
                                }
                            }

                            label {
                                font-size: 14px;
                                @include lg() {
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    .form-inp-wrap {
                        flex-direction: column;
                        display: flex;

                        @include desktop() {
                            flex-direction: row;
                        }

                        .inp-primary {
                            &:nth-child(odd) {
                                @include desktop() {
                                    margin-right: 31px;
                                }
                            }

                            input {
                                margin-bottom: 30px;
                                @include desktop() {
                                    margin-bottom: 55px;
                                }

                                &:focus {
                                    & + label {
                                        top: -15px;
                                        font-size: 12px;
                                    }
                                }
                            }

                            label {
                                font-size: 14px;
                                @include lg() {
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    .select-wrapper,
                    .inner-container .form-inputs {
                        .dropdown-wrapper {
                            position: relative;
                            width: 100%;

                            &.active {
                                .dropdown {
                                    display: block;
                                }

                                .inp-primary {
                                    img {
                                        transform: rotate(180deg);
                                    }
                                }
                            }

                            .inp-primary {
                                & > div {
                                    position: static !important;

                                    img {
                                        inset: unset !important;
                                        right: 0 !important;
                                        top: 7px !important;
                                        width: 15px !important;
                                        height: 8px !important;
                                        cursor: pointer !important;
                                        max-width: unset !important;
                                        max-height: unset !important;
                                        min-width: unset !important;
                                        min-height: unset !important;
                                    }
                                }
                            }

                            p {
                                font-family: 'MaisonNeue-Book', sans-serif;
                                font-size: 14px;
                                line-height: 1.57;
                                letter-spacing: normal;
                                color: $dark-gray;
                                cursor: pointer;
                                margin-bottom: 10px;
                                @include desktop() {
                                    font-size: 16px;
                                    line-height: 1.56;
                                }

                                &:hover {
                                    color: $honey;
                                }
                            }

                            .dropdown {
                                position: absolute;
                                border: $border;
                                border-top: unset;
                                display: none;
                                z-index: 2;
                                left: 0;
                                top: 27px;
                                padding: 10px;
                                width: calc(50% - 31px);
                                background-color: $white;
                                width: 100%;
                                max-height: 190px;
                                overflow-y: auto;
                                @include desktop() {
                                    padding: 15px;
                                }

                                &::-webkit-scrollbar {
                                    height: 0;
                                    width: 0;

                                    &-track {
                                        background-color: transparent;
                                    }

                                    &-thumb {
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                    }

                    .inner-container {
                        display: flex;
                        flex-direction: column;

                        .form-inputs {
                            width: 100%;
                            position: relative;
                            display: grid;
                            z-index: 4;
                            @include desktop() {
                                grid-template-columns: repeat(2, 1fr);
                                grid-gap: 30px;
                            }

                            &:nth-child(2) {
                                z-index: 3;
                            }

                            &:nth-child(3) {
                                z-index: 2;
                            }

                            &:nth-child(4) {
                                z-index: 1;
                            }

                            & > div {
                                img {
                                    width: 15px;
                                }
                            }

                            .date-wrapper {
                                position: relative;
                            }

                            .react-datepicker__input-container {
                                width: auto;
                            }

                            .inp-primary {
                                input {
                                    margin-bottom: 30px;
                                    @include desktop() {
                                        margin-bottom: 55px;
                                    }

                                    &:focus {
                                        & + label {
                                            top: -15px;
                                            font-size: 12px;
                                        }
                                    }
                                }

                                label {
                                    font-size: 14px;
                                    @include lg() {
                                        font-size: 16px;
                                    }
                                }
                            }

                            .date-wrapper {
                                .date-input {
                                    position: absolute;
                                    top: 0;
                                    width: 100%;
                                    z-index: 2;
                                    background-color: transparent;
                                    border: none;
                                    font-family: 'MaisonNeue-Book', sans-serif;
                                    font-size: 14px;
                                    line-height: 22px;
                                    color: $dark-cream;
                                    @include desktop() {
                                        font-size: 16px;
                                        line-height: 25px;
                                    }

                                    &::-webkit-inner-spin-button,
                                    &::-webkit-calendar-picker-indicator {
                                        opacity: 0;
                                    }

                                    div:nth-child(2) {
                                        div:nth-child(2) {
                                            top: -36px !important;
                                            @include desktop() {
                                                top: -58px !important;
                                            }
                                        }
                                    }
                                }

                                & > img {
                                    position: absolute !important;
                                    top: 7px;
                                    right: 5px;
                                    z-index: 1;
                                }
                            }

                            &.select-container {
                                height: 155px;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                @include desktop() {
                                    height: 190px;
                                    position: relative;
                                    top: 1px;
                                }
                            }
                        }

                        .fees-select {
                            margin-bottom: 55px;
                        }

                        .inp-primary {
                            .selectric-wrapper {
                                .selectric {
                                    margin-bottom: 0;
                                }
                            }
                            &.focus{
                                z-index: 1!important;
                            }
                        }
                    }

                    .select-wrapper {
                        position: relative;
                        z-index: 3;

                        .chevron-wrapper {
                            position: absolute !important;
                            right: 0;
                            z-index: 1;
                            padding-top: 5px;
                        }

                        select {
                            width: 100%;
                            border: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            border-bottom: 1px solid $dark-gray;
                            background-color: transparent;
                            font-family: 'MaisonNeue-Book', sans-serif;
                            font-size: 14px;
                            outline: none;
                            padding-bottom: 3px;
                            position: relative;
                            z-index: 1;

                            @include desktop() {
                                font-size: 16px;
                            }

                            &:required:invalid {
                                color: $dark-cream;
                            }

                            option {
                                color: $dark-gray;
                            }
                        }

                        &.outer {
                            margin-bottom: 0;

                            input {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .button-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @include desktop() {
                            align-items: flex-start;
                        }

                        .checkbox-button {
                            display: flex;
                            padding-left: 5px;
                            margin: 20px 0 23px;

                            .checkbox-container {
                                display: flex;
                                align-items: center;

                                & > div {
                                    margin-right: 10px;
                                }

                                .checkbox-wrapper {
                                    width: 16px;
                                    height: 16px;
                                    align-items: normal;

                                    .custom-checkbox {
                                        position: absolute;

                                        & + label::before {
                                            @include desktop() {
                                                width: 14px;
                                                height: 14px;
                                            }
                                        }
                                    }
                                }

                                & > p {
                                    font-family: 'MaisonNeue-Book', sans-serif;
                                    font-size: 12px;
                                    line-height: 19px;
                                    color: $dark-gray;
                                    letter-spacing: normal;

                                    a {
                                        color: $dark-gray;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }

                    .continue-btn {
                        background-color: transparent;
                        height: 50px;
                        cursor: pointer;
                        padding: 17px 22px;

                        &:hover:not(:disabled) {
                            background-color: $dark-gray;
                        }
                    }
                }
            }
        }

        .right-side {
            @include desktop() {
                width: 50%;
            }

            .img-wrapper {
                @include lg() {
                    width: 599px;
                }
            }
        }
    }
}
