.first-timer-banner {
    padding: 50px 45px 50px 25px;
    max-width: 1203px;
    width: 100%;
    position: relative;
    @include desktop() {
        margin: 0 auto 32px;
        padding: 44px 42px;
    }
    span {
        position: absolute!important;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        -o-object-fit: cover;
        object-fit: cover;
    }

    div {
        height: 100%;
        width: 100%;
        & > span {
            position: absolute!important;
            width: 100%!important;
            display: block!important;
            & > span {
                img {
                    width: 100%!important;
                }
            }

        }
    }

    p {
        font-family: 'OrpheusPro', sans-serif;
        font-size: 24px;
        line-height: 1.25;
        letter-spacing: normal;
        color: $gray;
        position: relative;
        z-index: 1;
        @include desktop() {
            font-size: 30px;
            line-height: 1.27;
        }
    }
}
