//.registry-buttons {
//    display: none;
//}

.user-registry-preview {
    .registery-wrapper {
        margin: 0 auto 50px;
    }

    .details-wrapper {
        .registered-funds-wrapper {
            border: unset;
            border-radius: 10px;
            position: relative;
            background-color: $white;
            top: -55px;
            padding-top: 83px;
            margin: 0 18px;
            @include desktop() {
                margin: 0 33px 0 31px;
                top: -110px;
                padding-top: 123px;
            }

            @media (min-width: 1024px) and (max-width: 1439.8px) {
                margin: 0 7px;
            }
            .registered-funds-content{
                @include desktop(max){
                    padding: 0;
                }
            }

            .details {
                padding-bottom: 30px;
                border-radius: 10px;
                @include desktop() {
                    padding-bottom: 64px;
                    margin-bottom: 25px;
                }

                & > div {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    justify-content: center;
                    margin-bottom: 20px;
                    @include desktop(max) {
                        margin-bottom: 15px;
                    }

                    & > div {
                        padding: 15px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        @include desktop() {
                            padding: 15px 35px;
                            flex-direction: row;
                        }

                        &:first-child {
                            position: relative;
                            justify-content: flex-end;

                            &::after {
                                content: '';
                                position: absolute;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                width: 1px;
                                height: 45px;
                                background-color: $magical-moonlight;
                            }
                        }

                        p:nth-child(1) {
                            @include desktop() {
                                margin-right: 7px;
                            }
                        }

                        span {
                            display: flex;
                            align-items: center;

                            p {
                                margin-left: 9px;

                                &:first-letter {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }

                    img {
                        width: 18px;
                        height: 18px;
                        -o-object-fit: contain;
                        object-fit: contain;
                    }
                }

                & > p {
                    max-width: 620px;
                    margin: auto;
                }

                p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    text-align: center;
                    color: $dark-gray;

                    @include desktop() {
                        font-size: 16px;
                        line-height: 1.56;
                    }
                }
            }
        }
    }
}

.details-wrapper {
    .registry-url {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $main-bg;
        border-radius: 10px;
        padding: 17.5px 27px;
        margin: 25px auto;
        @include desktop() {
            padding: 23px 0;
            margin: 38px 32px 34px;
        }

        & > p {
            color: $dark-cream !important;
            @include desktop(max){
                margin-bottom: 5px;
            }
        }

        div {
            display: flex;
            align-items: center;
            @include desktop(max) {
                width: 100%;
                flex-direction: column;
            }

            .btn-link {
                border-bottom: unset;
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    color: $honey;
                }
            }

            .url {
                justify-content: center;
                align-items: center;
                @include desktop(max) {
                    display: grid;
                    grid-template-columns: 20px 1fr;
                }
                @include desktop() {
                    display: flex;
                    margin-right: 20px;
                }

                span {
                    overflow-wrap: break-word;
                    word-break: break-all;
                    @include desktop() {
                        text-align: center;
                    }
                }
            }
        }

        a,
        p,
        button {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: normal;
            color: $dark-gray;
            cursor: default;
            @include desktop() {
                font-size: 16px;
                line-height: 1.56;
            }

            span {
                font-weight: bold;
            }
        }


        span:first-child {
            margin-right: 8px!important;
            width: 11px!important;
            height: 18px!important;
            -o-object-fit: contain;
            object-fit: contain;
            padding-bottom: 4px!important;

            img {
                margin-right: 8px;
                width: 11px;
                -o-object-fit: contain;
                object-fit: contain;
                padding-bottom: 4px;
            }
        }
        img {
            margin-right: 8px;
            width: 11px;
            -o-object-fit: contain;
            object-fit: contain;
            padding-bottom: 4px;
        }
    }

    .registry-details {
        .creamy-bg {
            background-position: center;
            position: relative;
            background-color: $cream;
            //padding: 23px;
            min-height: 241px;
            height: 100%;

            .show-desk {
                height: 352px;
                & > span {
                    height: 100% !important;
                    & > img {
                        height: 100% !important;
                    }
                }
            }

            .show-mob {
                height: 241px;
                & > span {
                    width: 100% !important;
                    height: 100% !important;
                    & > img {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }
            }

            h1 {
                position: absolute;
                left: 50%;
                top: 33px;
                transform: translateX(-50%);
                width: 100%;

                @include desktop() {
                    top: 50px;
                }
            }

            .btn-none {
                position: absolute;
                left: 23px;
                top: 23px;
                @include desktop() {
                    left: 34px;
                    top: 38px;
                }
            }
            @include desktop() {
                min-height: 352px;
                //padding: 38px 34px;
            }

            .btn-link {
                text-transform: uppercase;
            }

            .edit-bio {
                display: flex;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                justify-content: center;
                align-items: center;
                background-color: $white;
                z-index: -1;
                opacity: 0;
                visibility: hidden;
                transition: background-color 0.2s linear, opacity 0.2s linear, visibility 0.2s linear, z-index 0.2s linear;
                @extend .hide-scroll;
                @include desktop() {
                    overflow: auto;
                }

                &.open {
                    z-index: 5;
                    opacity: 1;
                    visibility: visible;
                    background-color: $popup-bg;
                }

                .close-popup {
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    width: 27px;
                    height: 27px;
                    cursor: pointer;
                    @include desktop() {
                        right: 20px;
                        top: 20px;
                    }
                }

                .about-wrapper {
                    position: relative;
                    background-color: $white;
                    max-width: 85%;
                    width: 100%;
                    padding: 0 18px 25px;
                    height: calc(100% - 80px);
                    overflow: auto;

                    @include tablet() {
                        max-width: 65%;
                    }

                    @include desktop() {
                        height: 706px;
                        max-height: 756px;
                        max-width: 1047px;
                        padding: 70px 72px 65px;
                    }

                    .about-title {
                        font-family: 'OrpheusPro', sans-serif;
                        font-size: 24px;
                        line-height: 1.25;
                        letter-spacing: normal;
                        text-align: center;
                        color: $gray;
                        margin: 35px 0;
                        @include desktop() {
                            font-size: 30px;
                            line-height: 1.27;
                        }
                    }

                    .header-banner {
                        width: 100%;
                        max-height: 183px;
                        overflow: hidden;
                        margin-top: 57px;
                        position: relative;
                        @include desktop() {
                            margin-top: 0;
                            max-height: 220px;
                            margin-bottom: 32px;
                        }

                        .header-container {
                            .header-color {
                                background-color: $cream;
                                background-size: cover;
                                border-radius: 10px;
                                height: 183px;
                                @include desktop() {
                                    height: 220px;
                                }
                            }

                            img {
                                border-radius: 10px;
                                height: 183px;
                                object-fit: cover;
                                -o-object-fit: cover;
                                @include desktop() {
                                    height: 220px;
                                }
                            }
                        }

                        .button-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            & > button {
                                position: relative;
                                font-size: 12px;
                                width: 178px;
                                top: -120px;
                                transition: unset;
                                @include desktop() {
                                    font-size: 14px;
                                    width: 231px;
                                    top: -135px;
                                }

                                &:hover {
                                    background-color: $black;
                                    color: $white;
                                }
                            }
                        }
                    }

                    .header-gallery-wrapper {
                        height: calc(100vh - 365px);
                        margin-top: 18px;
                        overflow: auto;
                        @include desktop() {
                            height: 348px;
                        }

                        &::-webkit-scrollbar {
                            height: 0;
                            width: 9px;

                            &-track {
                                background-color: $main-bg;
                                border-radius: 100px;
                            }

                            &-thumb {
                                background-color: $dark-gray;
                                border-radius: 100px;
                            }
                        }

                        .gallery-container {
                            display: grid;
                            grid-gap: 18px;
                            grid-template-columns: repeat(2, 48%);
                            margin-right: 22px;
                            @include desktop() {
                                grid-template-columns: repeat(4, 183px);
                                grid-gap: unset;
                                grid-row-gap: 33px;
                                grid-column-gap: 37px;
                                margin-right: 38px;
                            }

                            .img, img {
                                width: 100%;
                                height: 72px;
                                background-color: $cream;
                                border-radius: 10px;
                                overflow: hidden;
                                object-fit: cover;
                                -o-object-fit: cover;
                                cursor: pointer;
                                border: 1px solid transparent;
                                @include desktop() {
                                    width: 183px;
                                    height: 118px;
                                }
                            }

                            .img-container {
                                cursor: pointer;
                                position: relative;
                                display: block;
                                user-select: none;
                                -ms-user-select: none;
                                -moz-user-select: none;
                                -webkit-user-select: none;

                                input {
                                    position: absolute;
                                    opacity: 0;
                                    cursor: pointer;

                                    &:checked {
                                        & ~ .img, img {
                                            border: 1px solid $dark-gray;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .about {
                        width: 100%;
                        padding: 0 0 26px;
                        position: relative;
                        top: -55px;
                        @include desktop() {
                            padding: 0;
                            top: unset;
                            position: unset;
                            margin-top: 20px;
                        }

                        .img-side {
                            max-width: 289px;
                            margin: 16px auto 32px;
                            @include desktop() {
                                max-width: unset;
                                margin: 21px auto 0;
                            }

                            .photos {
                                margin-bottom: 0;
                                @include desktop() {
                                    margin-bottom: 37px;
                                }

                                & > div {
                                    .swiper-container {
                                        .swiper-button {
                                            &-prev {
                                                left: 50px;
                                                @include desktop() {
                                                    left: -9px;
                                                }
                                            }

                                            &-next {
                                                right: 50px;
                                                @include desktop() {
                                                    right: -9px;
                                                }
                                            }
                                        }

                                        .swiper-wrapper {
                                            .swiper-slide {
                                                img {
                                                    width: 124px;
                                                    height: 124px;
                                                    @include desktop() {
                                                        width: 184px;
                                                        height: 184px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .prof-pic {
                                    width: 124px;
                                    height: 124px;
                                    @include desktop() {
                                        width: 184px;
                                        height: 184px;
                                    }
                                }
                            }
                        }

                        .text-side {
                            @include desktop() {
                                width: 567px;
                            }

                            .inp-primary {
                                input {
                                    margin-bottom: 32px;
                                    @include desktop() {
                                        margin-bottom: 40px;
                                    }

                                    &:not([type='radio']):not([type='checkbox']) {
                                        border-radius: 0;
                                        -webkit-appearance: none;
                                    }
                                }
                            }

                            textarea {
                                width: 100%;
                                height: 134px;
                                padding: 15px;
                                @include desktop() {
                                    padding: 23px 25px;
                                    height: 161px;
                                }
                            }
                        }
                    }

                    .registry-buttons {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-top: 25px;
                        @include desktop() {
                            margin-top: 45px;
                        }

                        button {
                            text-transform: uppercase;

                            &:first-child {
                                margin-bottom: 20px;
                                @include desktop() {
                                    margin-bottom: 30px;
                                }
                            }
                        }
                    }

                    .view-reg {
                        display: none;
                    }

                    h1 {
                        font-family: 'OrpheusPro', sans-serif;
                        font-size: 24px;
                        line-height: 1.25;
                        letter-spacing: normal;
                        text-align: center;
                        color: $gray;
                        margin: 45px 0;
                        @include desktop() {
                            font-size: 30px;
                            line-height: 1.27;
                            margin: 15px 0 65px;
                        }
                    }
                }
            }

            .btn-none {
                display: flex;
                align-items: center;

                & > span:not(.btn-link) {
                    margin-right: 6px!important;
                    width: 14px;
                    @include desktop() {
                        margin-right: 10px!important;
                        width: 16px;
                    }
                }
            }

            h1 {
                font-family: 'OrpheusPro', sans-serif;
                font-size: 36px;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: center;
                color: $gray;
                margin-top: 10px;
                text-transform: capitalize;
                @include desktop() {
                    font-size: 54px;
                    line-height: 1.13;
                    margin-top: 12px;
                }
            }

            .profile-pic {
                width: 124px;
                height: 124px;
                position: absolute;
                bottom: -25px;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 1;
                @include desktop() {
                    width: 184px;
                    height: 184px;
                    border-radius: 100%;
                    bottom: 15px;
                }

                img {
                    border-radius: 100%;
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
            }
        }

        .details {
            padding-bottom: 60px;
            border-bottom: $border;
            margin-bottom: 30px;
            @include desktop() {
                padding-bottom: 80px;
                margin-bottom: 25px;
            }

            & > div {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-content: center;
                margin-bottom: 20px;

                & > div {
                    padding: 15px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    @include desktop() {
                        padding: 15px 35px;
                        flex-direction: row;
                    }

                    &:first-child {
                        position: relative;
                        justify-content: flex-end;

                        &::after {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            width: 1px;
                            height: 45px;
                            background-color: $magical-moonlight;
                        }
                    }

                    p:nth-child(1) {
                        @include desktop() {
                            margin-right: 7px;
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;

                        p {
                            margin-left: 9px;

                            &:first-letter {
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                img {
                    width: 18px;
                    height: 18px;
                    -o-object-fit: contain;
                    object-fit: contain;
                }
            }

            & > p {
                max-width: 620px;
                margin: auto;
            }

            p {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                line-height: 1.57;
                letter-spacing: normal;
                text-align: center;
                color: $dark-gray;

                @include desktop() {
                    font-size: 16px;
                    line-height: 1.56;
                }
            }
        }
    }

    .registry-details-wrapper {
        .registry-container {
            border-radius: 10px;
            margin: 0 18px;
            position: relative;
            background-color: $white;
            top: -55px;
            padding-top: 83px;
            @include desktop() {
                margin: 0 33px 0 31px;
                top: -115px;
                padding-top: 123px;
            }

            .details {
                margin-bottom: 49px;
                border-radius: 10px;
                @include desktop() {
                    margin-bottom: -5px;
                }

                & > div {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    justify-content: center;
                    margin-bottom: 20px;
                    @include desktop() {
                        margin-bottom: 15px;
                    }

                    & > div {
                        padding: 15px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        @include desktop() {
                            padding: 15px 35px;
                            flex-direction: row;
                        }

                        &:first-child {
                            position: relative;
                            justify-content: flex-end;

                            &::after {
                                content: '';
                                position: absolute;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                width: 1px;
                                height: 45px;
                                background-color: $magical-moonlight;
                            }
                        }

                        p:nth-child(1) {
                            @include desktop() {
                                margin-right: 7px;
                            }
                        }

                        span {
                            display: flex;
                            align-items: center;

                            p {
                                margin-left: 9px;

                                &:first-letter {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }

                    img {
                        width: 18px;
                        height: 18px;
                        -o-object-fit: contain;
                        object-fit: contain;
                    }
                }

                & > p {
                    max-width: 620px;
                    margin: auto;
                }

                p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    text-align: center;
                    color: $dark-gray;

                    @include desktop() {
                        font-size: 16px;
                        line-height: 1.56;
                    }
                }
            }
        }
    }

    .edit-details {
        padding: 0 25px 0;
        margin: -30px 0 0;
        @include desktop() {
            padding: 0 0 90px;
            max-width: 1200px;
            margin: 0 auto;
        }

        .inp-primary {
            span {
                z-index: -1!important;
            }
        }

        .due-date-input {
            display: flex;

            .inp-primary {
                flex-basis: 50%;
            }

            .checkbox-button {
                flex-basis: 50%;
                padding-top: 5px;
                padding-left: 10px;

                .checkbox-wrapper {
                    margin-right: 10px;
                }

                p {
                    margin: 0;
                }
            }
        }

        h2 {
            font-family: 'MaisonNeue-Extended-Book', sans-serif;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 1.4px;
            color: $dark-gray;
            text-transform: uppercase;
            padding-top: 32px;
            margin-bottom: 58px;
            border-top: $border;
            @include desktop() {
                padding-top: 25px;
                margin-bottom: 85px;
                padding-left: 15px;
            }
            @include lg(){
                padding-left: unset;
            }
        }

        form {
            margin-bottom: 40px;
            @include desktop() {
                margin-bottom: 90px;
                max-width: 940px;
                margin: auto;
            }

            & > div {
                display: grid;
                @include desktop() {
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 50px 63px;
                }

                .inp-primary {
                    z-index: unset;

                    .react-datepicker-popper {
                        top: -35px !important;
                        @include desktop() {
                            top: -58px !important;
                        }
                    }

                    input {
                        margin-bottom: 35px;

                        &:not([type='radio']):not([type='checkbox']) {
                            border-radius: 0;
                            -webkit-appearance: none;
                        }

                        @include desktop() {
                            margin-bottom: 55px;
                        }
                    }

                    .selectric-wrapper {
                        position: relative !important;

                        .selectric {
                            margin-bottom: 35px;
                        }
                    }
                }

                .dropdown-wrapper {
                    position: relative;

                    &.active {
                        .dropdown {
                            display: block;
                        }

                        .inp-primary {
                            img {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                input[type='date']::-webkit-calendar-picker-indicator {
                    background-image: unset !important;
                    z-index: 2;
                    cursor: pointer;
                }

                .inp-primary {
                    & > div {
                        position: static !important;

                        img {
                            inset: unset !important;
                            right: 0 !important;
                            top: 7px !important;
                            width: 15px !important;
                            height: 8px !important;
                            cursor: pointer !important;
                            max-width: unset !important;
                            max-height: unset !important;
                            min-width: unset !important;
                            min-height: unset !important;
                        }
                    }
                }

                .dropdown {
                    position: absolute;
                    border: $border;
                    border-top: unset;
                    display: none;
                    z-index: 2;
                    left: 0;
                    top: 27px;
                    padding: 10px;
                    width: calc(50% - 31px);
                    background-color: $white;
                    width: 100%;
                    max-height: 190px;
                    overflow-y: auto;
                    @include desktop() {
                        padding: 15px;
                    }
                }

                p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: $dark-gray;
                    cursor: pointer;
                    margin-bottom: 10px;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 1.56;
                    }

                    &:hover {
                        color: $honey;
                    }
                }
            }

            .btn-stroke {
                margin: auto;
            }
        }
    }
}