.thanks-payment-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: $cream;
    height: calc(100vh - 60px);
    margin-top: -2px;
    @include desktop() {
        height: 100vh;
    }

    .thanks-payment-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 35px;
        @include desktop() {
            padding: 0;
        }
    }

    h1 {
        font-family: 'OrpheusPro', sans-serif;
        font-size: 36px;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: $dark-gray;
        margin-bottom: 12px;
        @include desktop() {
            font-size: 54px;
            line-height: 1.13;
        }
    }

    p {
        font-family: 'MaisonNeue-Book', sans-serif;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: $dark-gray;
        margin-bottom: 66px;
        @include desktop() {
            font-size: 16px;
            line-height: 1.56;
        }
    }

    .btn-stroke.btn-fill {
        margin-bottom: 25px;
    }

    .btn-fill {
        width: 259px;
    }

    .btn-link {
        text-transform: uppercase;
    }
}
