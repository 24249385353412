.loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 94px;
    height: 94px;
}

.loader {
    border: 5px solid $illusive-dream;
    border-left: 5px solid $mojave-desert;
    -webkit-animation: load 1.3s infinite linear;
    animation: load 1.3s infinite linear;
}

@-webkit-keyframes load {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
