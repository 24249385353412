.services-wrapper {
    display: grid;
    position: relative;
    @include desktop() {
        grid-template-columns: repeat(2, 1fr);
        height: 817px;
    }

    .added-registry {
        p {
            font-size: 14px;
            line-height: 1.57;
            @include desktop() {
                font-size: 16px;
                line-height: 1.56;
            }

            &:first-letter {
                text-transform: uppercase;
            }
        }
    }

    .left-side {
        position: relative;

        span {
            position: static!important;
            height: 352px!important;
            @include desktop() {
                height: 817px!important;
            }
            img {
                height: 352px!important;
                -o-object-fit: cover;
                object-fit: cover;
                @include desktop() {
                    height: 817px!important;
                }
            }
        }

        & > div {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;

            h1 {
                font-family: 'OrpheusPro', sans-serif;
                font-size: 36px;
                line-height: 42px;
                letter-spacing: normal;
                color: $dark-gray;
                margin-bottom: 8px;
                text-align: center;
                @include desktop() {
                    font-size: 54px;
                    line-height: 61px;
                    margin-bottom: 18px;
                    text-align: center;
                    padding: 0 25px;
                }
            }

            p {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                line-height: 1.57;
                letter-spacing: normal;
                text-align: center;
                color: $dark-gray;
                @include desktop() {
                    font-size: 16px;
                    line-height: 1.56;
                }
            }
        }
    }

    .right-side {
        position: relative;

        @include mobile() {
            padding-top: 59.5px;

            .header {
                position: absolute;
                top: 0;
            }
        }

        @include desktop() {
            height: 817px;
            overflow: auto;

            padding-top: unset;

            .header {
                position: unset;
                top: unset;
            }

            &::-webkit-scrollbar {
                width: 0;
                height: 0;

                &-track {
                    background-color: transparent;
                }

                &-thumb {
                    background-color: transparent;
                }
            }
        }

        .header {
            background-color: $white;
            width: 100%;
            z-index: 1;
            @include desktop() {
                border-bottom: 1px solid $light-gray;
                position: sticky;
                top: 10px;
            }
            @include lg(){
                top: 0;
            }

            &.sticky {
                position: fixed;
                top: 58px;
                @include desktop() {
                    position: fixed;
                    top: 0;
                }

                @include desktop(max){
                    border-bottom: 1px solid $light-gray;
                }
            }

            ul {
                display: flex;
                overflow: auto;
                padding: 20px 17px;
                max-width: 370px;
                @include xxs() {
                    max-width: unset;
                }
                @include desktop() {
                    padding: 32px;
                }

                &::-webkit-scrollbar {
                    height: 4px;
                    width: 0;

                    &-track {
                        background-color: $main-bg;
                        border-radius: 100px;
                    }

                    &-thumb {
                        background-color: $dark-gray;
                        border-radius: 100px;
                    }
                }

                li {
                    font-family: 'MaisonNeue-Extended-Book', sans-serif;
                    font-size: 12px;
                    line-height: normal;
                    letter-spacing: 1.2px;
                    color: $dark-gray;
                    position: relative;
                    margin-right: 35px;
                    text-transform: uppercase;
                    padding-bottom: 2.5px;
                    cursor: pointer;
                    @include desktop() {
                        font-size: 14px;
                        letter-spacing: 1.4px;
                        margin-right: 45px;
                    }

                    &:hover {
                        border-bottom: 1px solid $dark-gray;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }

                    span {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 10px;
                        line-height: 1.2;
                        letter-spacing: normal;
                        color: $dark-cream;
                        position: absolute;
                        right: -17px;
                        top: -5px;
                        @include desktop() {
                            font-size: 12px;
                            line-height: 1.58;
                        }
                    }
                }
            }
        }

        .content {

            .dropdowns-container {
                position: relative;

                .dropdown-wrapper {
                    &:first-child {
                        @include desktop() {
                            .dropdown-header {
                                border-top: none;
                            }
                        }
                    }
                    &.open {
                        .dropdown-content {
                            display: block;
                            .btn-stroke {
                                width: auto;
                                min-width: 219px;
                                padding-left: 16px;
                                padding-right: 16px;
                            }
                        }

                        .arrows {
                            img {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    &:not(.open) {
                        .dropdown-header {
                            padding: 28px 25px;
                            @include desktop() {
                                padding: 50px 31px 50px 41px;
                            }
                        }
                    }
                }

                .dropdown-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 28px 25px;
                    border-top: $border;
                    cursor: pointer;
                    position: relative;
                    @include desktop() {
                        display: grid;
                        grid-template-columns: 90% 10%;
                        padding: 50px 31px 32px 41px;
                    }
                    @include lg() {
                        grid-template-columns: 90% 10%;
                    }


                    h2 {
                        font-family: 'OrpheusPro', sans-serif;
                        font-size: 24px;
                        line-height: 1.25;
                        letter-spacing: normal;
                        color: $gray;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                        @include desktop() {
                            flex-direction: row;
                            align-items: center;
                            font-size: 30px;
                            line-height: 1.27;
                        }
                    }

                    p.added {
                        font-family: "MaisonNeue-Book", sans-serif;
                        font-size: 14px;
                        color: $dark-gray;
                        display: flex;
                        align-items: center;
                        margin-top: 11px;
                        @include desktop() {
                            font-size: 16px;
                            margin-top: 0;
                        }

                        img {
                            width: 13px;
                            margin-right: 5px;
                            @include desktop() {
                                width: 17px;
                            }
                        }
                    }

                    .arrows {
                        position: relative;
                        @include desktop(max){
                            width: 29px;
                        }

                        span {
                            width: 29px!important;
                            height: 29px!important;
                            -o-object-fit: contain;
                            object-fit: contain;
                            position: absolute!important;
                            right: 0!important;
                            top: 0!important;
                            bottom: 0!important;
                            margin: auto!important;

                            img {
                                width: 29px;
                                height: 29px;
                                -o-object-fit: contain;
                                object-fit: contain;
                                position: absolute;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                            }
                        }

                        img {
                            width: 29px;
                            height: 29px;
                            -o-object-fit: contain;
                            object-fit: contain;
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }

                .dropdown-content {
                    padding: 0 23px 27px 27px;
                    display: none;
                    @include desktop() {
                        padding: 0 39px 50px;
                    }

                    span {
                        display: inline-block;
                        font-family: 'MaisonNeue-Extended-Book', sans-serif;
                        font-size: 14px;
                        line-height: normal;
                        letter-spacing: 1.4px;
                        color: $gray;
                        background-color: $cream;
                        padding: 10px 16px;
                        border-radius: 100px;
                        margin: 0 10px 18px 0;
                    }

                    p {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 16px;
                        line-height: 1.56;
                        letter-spacing: normal;
                        color: $gray;
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }
}
