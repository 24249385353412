.blog {
    &-container {
        padding: 0 25px;

        @include desktop() {
            max-width: 90%;
            margin-inline: auto;
        }
    }

    &__hero {
        width: 100%;
        margin-top: -72px;
        position: relative;
        margin-bottom: 15px;

        @include desktop() {
            margin-top: -83px;
            margin-bottom: 70px;
        }

        &::before {
            content: '';
            width: 100%;
            height: 480px;
            display: inline-block;
            z-index: -1;
            position: absolute;
            background-color: $cream;
        }

        &-container {
            padding-top: 110px;

            @include desktop() {
                padding-top: 175px;
            }
        }

        &-content {
            display: grid;
            grid-gap: 30px;

            @include desktop() {
                grid-template-columns: repeat(2, 1fr);
            }

            h1 {
                font-family: $font-family-secondary;
                font-size: 26px;
                letter-spacing: 0.65px;
                color: $gray;
                line-height: 1.42;

                @include desktop() {
                    width: 85%;
                }
            }

            .inp-primary {
                @include desktop() {
                    max-width: 250px;
                    margin-right: auto;
                }

                .selectric-wrapper .selectric {
                    font-family: $font-family-tertiary;

                    .label {
                        font-size: 16px;
                        text-transform: uppercase;
                        font-family: $font-family-tertiary;
                    }
                }
            }
        }
    }

    &__posts {
        padding-bottom: 40px;

        @include desktop() {
            padding-bottom: 65px;
        }

        &-grid {
            display: grid;
            grid-gap: 45px 30px;

            @include tablet() {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &-pagination {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 75px;
            position: relative;
            
            .paginate-left,
            .paginate-right {
                border: none;
                background-color: transparent;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 35px;
                height: 35px;
            }

            .paginate-left {
                left: 0;
                transform: translateY(-50%) scaleX(-1);
            }

            .paginate-right {
                right: 0;
            }

            .blog--pagination {
                font-family: $font-family-primary;
                font-size: 14px;
                letter-spacing: 0.8px;
                line-height: 1.45;
                background-color: transparent;
                border: none;
                color: #999999;
                border-bottom: 1px solid transparent;
                padding: 0 2px;
                margin: 0 6px;

                &:hover {
                    color: $gray;
                }

                &[disabled] {
                    color: $gray !important;
                    border: none !important;
                    background-color: transparent !important;
                }

                &.active {
                    color: $gray;
                    border-bottom: 1px solid $gray;
                }
            }
        }
    }

    &-post {
        &-card {
            display: flex;
            flex-direction: column;

            & > a {
                position: relative;
                margin-bottom: 15px;

                @include desktop() {
                    height: 500px;
                }

                &:hover > p {
                    opacity: 1;
                }

                & > p {
                    font-family: $font-family-tertiary;
                    background-color: rgba(247,233,212,0.7);
                    font-size: 22px;
                    letter-spacing: 2.2px;
                    text-align: center;
                    color: $white;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    inset: 0;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0;
                    transition: opacity .6s;
                }

                span, img {
                    width: 100% !important;
                    height: 100% !important;
                }

                img {
                    object-fit: cover;
                    object-position: center top;
                }
            }
        }

        &-info {
            & > span {
                font-family: $font-family-primary;
                font-size: 12px;
                min-height: 33px;
                color: #909090;
                display: inline-block;
            }

            & > div {
                font-family: $font-family-primary;
                font-size: 14px;
                line-height: 1.71;
                color: $gray;
                margin-bottom: 25px;

                @include desktop() {
                    min-height: 90px;
                }
            }
        }
    }
}

em {
    font-style: italic;
}

.round-btn {
    border: 1px solid #909090;
    border-radius: 25px;
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: normal;
    line-height: 2.5;
    letter-spacing: 0.35px;
    text-align: center;
    color: #909090;
    padding: 2px 19px 0;
    min-width: 77px;
    display: inline-block;

    &:hover {
        border: 1px solid #323232;
        color: #323232;
    }
}