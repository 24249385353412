.about-wrapper {
    .close-popup {
        display: none;
    }

    .about {
        text-align: center;
        width: 100%;
        @include desktop() {
            width: 740px;
            display: flex;
            justify-content: space-between;
        }

        p {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 10px;
            line-height: 1.2;
            letter-spacing: normal;
            color: $dark-gray;
            @include desktop() {
                font-size: 12px;
                line-height: 1.58;
            }
        }

        .img-side {
            margin-bottom: 32px;
            @include desktop() {
                margin-bottom: 0;
            }

            .photos {
                position: relative;
                margin-bottom: 15px;
                @include desktop() {
                    width: 220px;
                    height: 153px;
                }

                & > div {
                    position: relative;
                    @include desktop() {
                        left: unset;
                    }

                    .swiper-container {
                        .swiper-button-next,
                        .swiper-button-prev {
                            background-image: url(../images/carousel-next.svg);
                            background-repeat: no-repeat;
                            background-position: center;
                            right: 60px;

                            &::after {
                                display: none;
                            }

                            @include desktop() {
                                right: 0;
                            }
                        }

                        .swiper-button-prev {
                            transform: scaleX(-1);
                            left: 60px;
                            @include desktop() {
                                left: 0;
                            }
                        }

                        .swiper-wrapper {
                            max-width: 220px;

                            .swiper-slide {
                                span {
                                    border-radius: 50%;
                                    width: 100px!important;
                                    height: 100px!important;
                                    @include desktop() {
                                        width: 150px!important;
                                        height: 150px!important;
                                    }
                                    img {
                                        border-radius: 50%;
                                        width: 100px;
                                        height: 100px;
                                        @include desktop() {
                                            width: 150px;
                                            height: 150px;
                                        }
                                    }
                                }
                                img {
                                    border-radius: 50%;
                                    width: 100px;
                                    height: 100px;
                                    @include desktop() {
                                        width: 150px;
                                        height: 150px;
                                    }
                                }
                            }
                        }
                    }
                }

                .prof-pic {
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                    margin: auto;
                    @include desktop() {
                        width: 150px;
                        height: 150px;
                    }

                    img {
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                        -o-object-fit: cover;
                        object-fit: cover;
                    }
                }

                .remove {
                    cursor: pointer;
                    position: absolute;
                    width: 27px;
                    height: 27px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    top: -13px;
                    z-index: 1;
                }
            }
        }

        .text-side {
            .inp-primary {
                input {
                    font-family: "MaisonNeue-Extended-Book", sans-serif;
                    font-size: 16px;
                    @include desktop() {
                        font-size: 14px;
                    }
                }
            }

            .registry-buttons {
                @include desktop() {
                    flex-direction: row !important;
                    justify-content: space-between!important;
                    margin-top: 31px!important;
                }

                button:first-child {
                    @include desktop() {
                        margin-bottom: 0!important;
                    }
                }
            }
        }

        .change-photo {
            position: relative;
            margin-bottom: 10px;

            input {
                position: absolute;
                visibility: hidden;
                left: 0;
                opacity: 0;
            }

            label {
                font-family: 'MaisonNeue-Extended-Book', sans-serif;
                cursor: pointer;
                font-size: 12px;
                line-height: normal;
                letter-spacing: 1.2px;
                color: $dark-gray;
                border-bottom: 1px solid $dark-gray;
                @include desktop() {
                    font-size: 14px;
                    letter-spacing: 1.4px;
                }
            }
        }

        .text-side {
            .inp-primary {
                input {
                    margin-bottom: 40px;
                    text-transform: capitalize;
                    @include desktop() {
                        margin-bottom: 32px;
                    }
                }
            }

            textarea {
                resize: none;
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 16px;
                line-height: 1.57;
                padding: 23px 25px;
                width: 325px;
                height: 146px;
                border: 1px solid $dark-gray;
                border-radius: 10px;
                color: $dark-gray;
                @include desktop() {
                    width: 479px;
                    height: 210px;
                    line-height: 1.56;
                    padding: 24px 23px;
                }

                &::-webkit-input-placeholder {
                    color: $dark-cream;
                    @include desktop() {
                        padding-right: 90px;
                    }
                }

                &::-moz-placeholder {
                    color: $dark-cream;
                    @include desktop() {
                        padding-right: 90px;
                    }
                }

                &:-ms-input-placeholder {
                    color: $dark-cream;
                    @include desktop() {
                        padding-right: 90px;
                    }
                }

                &::-ms-input-placeholder {
                    color: $dark-cream;
                    @include desktop() {
                        padding-right: 90px;
                    }
                }

                &::placeholder {
                    color: $dark-cream;
                    @include desktop() {
                        padding-right: 90px;
                    }
                }
            }
        }
    }
}
