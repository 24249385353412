.find-registry-wrapper {
    .user-registry-preview {
        .cart-message-container {
            margin-bottom: 64px;
            top: 63px;

            @include desktop() {
                margin-bottom: 3px;
                top: 82px;
            }
        }

        .cart {
            @include desktop() {
                top: 82px;
            }
        }

        .registery-wrapper {
            position: relative;
            top: -2px;
            @include desktop() {
                top: unset;
                margin: 82px auto 50px;
            }
        }
    }
}

.registry-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $cream;
    padding-top: 138px;

    @include desktop() {
        height: 100vh;
        flex-direction: row;
        padding-top: unset;
    }

    .left-side {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 64px;
        @include desktop() {
            width: 50%;
            padding-bottom: unset;
        }
    }

    .right-side {
        @include desktop() {
            width: 50%;
        }

        .big-img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
        }

        .big-img > span {
            width: 100% !important;
            height: 100% !important;
            display: block !important;

            & > span {
                img {
                    width: 100% !important;
                    object-fit: cover;
                }
            }

        }

        & > div {
            @include desktop() {
                height: 100vh;
            }

            img {
                -o-object-fit: cover;
                object-fit: cover;
            }
        }
    }
}

.search-container {
    & > p {
        font-family: 'OrpheusPro', sans-serif;
        font-size: 30px;
        line-height: 38px;
        color: $dark-gray;
        margin-bottom: 39px;
        text-align: center;
        @include desktop() {
            text-align: unset;
        }
    }

    .search-wrapper {
        position: relative;
        max-width: 323px;
        width: 100%;
        @include desktop() {
            max-width: 483px;
        }

        & > span {
            position: absolute !important;
            width: 14px;
            left: 18px;
            top: 17px;
        }

        input {
            width: 100%;
            height: 50px;
            border: 1px solid $gray;
            border-radius: 10px;
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 16px;
            color: $dark-gray;
            padding: 18px 34px 18px 40px;
            @include desktop() {
                padding: 18px 107px 18px 45px;
            }
        }

        & > p {
            font-family: 'MaisonNeue-Book', sans-serif;
            font-size: 12px;
            color: $dark-gray;
            margin-top: 10px;
            margin-left: 8px;
        }

        .close-btn {
            position: absolute !important;
            top: 18px;
            right: 17px;

            & > div {
                width: 8px;
            }
        }
    }
}

.results-wrapper {
    height: 100%;

    .up-side {
        height: 30%;
        background-color: $cream;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 118px 25px 67px;
        @include desktop() {
            padding: 175px 0 96px;
        }
    }

    .down-side {
        height: 70%;
        overflow: auto;
        background-color: $white;
        @include desktop() {
            padding: 57px 118px 0;
        }

        .search-results {
            width: 100%;

            .title {
                background-color: $main-bg;
                padding: 29px 24px;
                height: 96px;
                display: flex;
                @include desktop() {
                    padding: 34px 29px;
                    border-radius: 16px;
                    display: grid;
                    grid-template-columns: repeat(2, 250px);
                    grid-gap: 294px;
                }

                p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    line-height: 22px;
                    color: $gray;
                    margin: auto 0;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 25px;
                        margin: unset;
                    }
                }
            }

            .results {
                a {
                    color: $gray;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: $border;
                    padding: 20px 25px;
                    @include desktop() {
                        padding: 23px 14px 23px 30px;
                    }

                    & > div {
                        display: flex;
                        align-items: center;

                        .user {
                            display: flex;

                            @include desktop() {
                                margin-right: 25px;
                            }

                            & > span {
                                @include desktop(max) {
                                    margin-right: 23px !important;
                                }
                            }

                            img {
                                width: 50px;
                                height: 50px;
                                border-radius: 100%;
                                object-fit: cover;
                                -o-object-fit: cover;
                            }

                            & > div {
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                margin-right: 23px !important;

                                @include desktop() {
                                    margin-right: 25px !important;
                                }
                            }
                        }

                        .user-info {
                            display: flex;
                            flex-direction: column;
                            @include desktop() {
                                display: grid;
                                grid-template-columns: repeat(2, 250px);
                                grid-gap: 134px;
                            }

                            p {
                                &:first-child {
                                    font-family: 'OrpheusPro', sans-serif;
                                    color: $gray;
                                    font-size: 24px;
                                    line-height: 30px;

                                    @include desktop() {
                                        font-size: 30px;
                                        line-height: 38px;
                                        margin: auto;
                                        width: 420px;
                                    }
                                }

                                &:nth-child(2) {
                                    font-family: 'MaisonNeue-Book', sans-serif;
                                    font-size: 14px;
                                    line-height: 22px;

                                    @include desktop() {
                                        font-size: 16px;
                                        line-height: 25px;
                                        margin: auto;
                                    }
                                }
                            }
                        }
                    }

                    button {
                        & > div {
                            width: 43px !important;
                            @include desktop() {
                                width: 44px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.registry-preview {
    padding: 70px 25px 0;
    @include desktop() {
        padding: 155px 0 0;
    }

    .registry-details {
        .creamy-bg {
            background-position: center;
            min-height: 228px;
            border-radius: 10px;
            @include desktop() {
                min-height: 257px;
            }

            h1 {
                @include desktop() {
                    margin-top: 12px;
                }
            }


            .profile-pic {
                width: 124px;
                height: 124px;
                @include desktop() {
                    width: 150px;
                    height: 150px;
                }
            }
        }

        .details {
            border-bottom: unset;
            padding-bottom: unset;
            margin-bottom: 42px;
            @include desktop() {
                margin-bottom: 66px;
            }
        }

        .contribute-wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-bottom: 17px;
            @include desktop() {
                padding-bottom: 87px;
            }

            .contribute {
                width: 100%;
                height: 184px;
                padding: 14px 16px;
                margin-bottom: 34px;
                background-color: $main-bg;
                border-radius: 10px;
                display: flex;
                align-items: center;
                flex-direction: column;
                @include desktop() {
                    justify-content: space-between;
                    flex-direction: row;
                    height: 96px;
                    padding: 23px 20px;
                    margin-bottom: 18px;
                }

                & > p {
                    font-family: 'OrpheusPro', sans-serif;
                    font-size: 24px;
                    line-height: 30px;
                    color: $gray;
                    margin-bottom: 14px;
                    @include desktop() {
                        font-size: 30px;
                        line-height: 38px;
                        margin-bottom: 0;
                    }
                }

                & > div {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    align-items: center;
                    @include desktop() {
                        grid-gap: 125px;
                        flex-direction: row;
                    }

                    & > div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 16px;
                        @include desktop() {
                            margin-bottom: unset;
                        }

                        p {
                            font-family: 'MaisonNeue-Book', sans-serif;
                            font-size: 14px;
                            line-height: 22px;
                            color: $dark-gray;
                            padding: 10px;
                            @include desktop() {
                                font-size: 16px;
                                line-height: 25px;
                                padding: 10px 51px;
                            }

                            &:nth-child(2) {
                                border-right: $border;
                                border-left: $border;
                            }
                        }
                    }

                    button {
                        width: 178px;
                        @include desktop() {
                            width: 186px;
                        }
                    }
                }
            }
        }
    }
}

.thanks-message-wrapper {
    width: 100%;
    height: 100vh;
    background-color: $cream;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .message {
        p {
            text-align: center;

            &:first-child {
                font-family: 'OrpheusPro', sans-serif;
                font-size: 36px;
                line-height: 42px;
                color: $dark-gray;
                margin-bottom: 14px;
                @include desktop() {
                    font-size: 54px;
                    line-height: 61px;
                    margin-bottom: 12px;
                }
            }

            &:nth-child(2) {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                line-height: 22px;
                color: $dark-gray;
                margin-bottom: 68px;
                @include desktop() {
                    font-size: 16px;
                    line-height: 25px;
                    margin-bottom: 66px;
                }
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            &:first-child {
                width: 259px;
                margin-bottom: 24px;
                @include desktop() {
                    margin-bottom: 46px;
                }
            }
        }
    }
}
