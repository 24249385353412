.home-wrapper {
    .hp-first {
        background-color: $main-bg;
        border-bottom: $border;
        position: relative;
        overflow-x: hidden;
        @include desktop() {
            position: static;
            padding: 70px 0 64px;
            border-bottom: unset;
        }

        .container {
            background-color: $white;
            display: flex;
            flex-direction: column;
            padding: 32px 25px 27px;
            position: relative;
            z-index: 1;
            @include desktop() {
                flex-direction: row;
                padding: 32px;
            }

            .big-box {
                background-color: $main-bg;
                border-radius: 10px;
                margin-bottom: 32px;
                @include desktop() {
                    width: 50%;
                    margin-bottom: 0;
                    margin-right: 32px;
                }

                img {
                    height: 168px;
                    -o-object-fit: cover;
                    object-fit: cover;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    @include desktop() {
                        height: auto;
                    }
                }

                & > div {
                    padding: 20px 13px 25px;
                    @include desktop() {
                        padding: 42px 33px 0;
                    }
                }

                p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: $gray;
                    margin-bottom: 12px;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 1.56;
                        margin-bottom: 20px;
                        text-transform: capitalize;
                    }
                }
            }

            .small-boxes {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 20px;
                overflow-x: auto;
                overflow-y: hidden;

                @include desktop() {
                    width: 50%;
                    overflow: unset;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 30px 32px;
                }

                &::-webkit-scrollbar {
                    width: 0;
                    height: 0;

                    &-track {
                        background-color: transparent;
                    }

                    &-thumb {
                        background-color: transparent;
                    }
                }

                & > a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 10px;
                    background-color: $main-bg;
                    height: 128px;
                    padding: 0 25px;
                    min-width: 244px;
                    transition: .4s ease;

                    @include xm() {
                        min-width: unset;
                    }

                    @include lg() {
                        min-width: 244px;
                    }

                    &:hover {
                        background-color: $cream;
                    }

                    & > div {
                        display: flex;
                        align-items: center;

                        & > span {
                            width: 20px;
                            height: 20px;
                            margin-right: 12px!important;
                        }
                    }

                    p {
                        font-family: 'MaisonNeue-Book', sans-serif;
                        font-size: 14px;
                        line-height: 1.57;
                        letter-spacing: normal;
                        color: $dark-gray;
                        @include desktop() {
                            font-size: 16px;
                            line-height: 1.56;
                        }
                    }

                    img {
                        -o-object-fit: contain;
                        object-fit: contain;
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }

    .hp-second {
        padding: 29px 0 27px;
        border-bottom: unset;
        position: relative;
        @include desktop() {
            padding: 102px 0 70px;
            border-bottom: $border;
        }

        .header {
            padding: 0 25px;
            margin-bottom: 37px;
            @include desktop() {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 18px;
                padding: 0;
            }

            h2 {
                font-family: 'MaisonNeue-Book', sans-serif;
                font-size: 14px;
                line-height: 1.57;
                letter-spacing: normal;
                color: $gray;
                margin-bottom: 13px;
                @include desktop() {
                    font-size: 16px;
                    line-height: 1.56;
                    margin-bottom: 0;
                }
            }
        }

        .services {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 6px;
            overflow: auto;
            padding: 0 25px;
            @include desktop() {
                grid-gap: 5px;
                overflow: unset;
                padding: 0;
            }

            &::-webkit-scrollbar {
                width: 0;
                height: 0;

                &-track {
                    background-color: transparent;
                }

                &-thumb {
                    background-color: transparent;
                }
            }

            & > a {
                width: 244px;
                @include xm() {
                    width: 100%;
                }
                @include lg() {
                    width: 298px;
                }

                &:hover {
                    p {
                        color: $honey;
                    }
                }

                img {
                    width: 100%;
                    height: 244px;
                    -o-object-fit: cover;
                    object-fit: cover;
                    margin-bottom: 10px;
                    @include lg() {
                        height: 298px;
                        margin-bottom: 15px;
                    }
                }

                p {
                    font-family: 'OrpheusPro', sans-serif;
                    font-size: 24px;
                    line-height: 1.25;
                    letter-spacing: normal;
                    color: $gray;
                    margin-top: 10px;
                    @include desktop() {
                        font-size: 30px;
                        line-height: 1.27;
                        margin-top: 9px;
                    }
                }
            }
        }
    }

    .hp-third {
        position: relative;
        z-index: 1;
        @include desktop() {
            padding: 93px 0 83px;
        }

        .container {
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: repeat(2, 1fr);
            @include desktop() {
                grid-template-rows: unset;
                grid-template-columns: repeat(2, 1fr);
            }

            img {
                -o-object-fit: cover;
                object-fit: cover;
                min-height: 380px;
                @include desktop() {
                    min-height: unset;
                }
            }

            .btn-link {
                &:hover {
                    color: $cream;
                    border-bottom: 1px solid $cream;
                }
            }

            & > div {
                background-color: $honey;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 25px;
                min-height: 380px;
                @include desktop() {
                    padding: 0 67px;
                    min-height: unset;
                }

                h2 {
                    font-family: 'OrpheusPro', sans-serif;
                    font-size: 36px;
                    line-height: 1.17;
                    letter-spacing: normal;
                    color: $dark-gray;
                    margin-bottom: 24px;
                    @include desktop() {
                        font-size: 54px;
                        line-height: 1.13;
                        margin-bottom: 35px;
                    }
                }

                p {
                    font-family: 'MaisonNeue-Book', sans-serif;
                    font-size: 14px;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: $dark-gray;
                    margin-bottom: 25px;
                    @include desktop() {
                        font-size: 16px;
                        line-height: 1.56;
                    }
                }

                a {
                    width: -webkit-max-content;
                    width: -moz-max-content;
                    width: max-content;
                }
            }
        }
    }

    .hp-fourth {
        background-color: $main-bg;
        padding: 48px 0 60px;
        margin-bottom: 65px;
        @include desktop() {
            min-height: 1050px;
            padding: 85px 0 65px;
            margin-bottom: 0;
        }

        h2 {
            font-family: 'OrpheusPro', sans-serif;
            text-align: center;
            font-size: 36px;
            line-height: 1.17;
            letter-spacing: normal;
            color: $gray;
            margin-bottom: 35px;
            @include desktop() {
                font-size: 30px;
                line-height: 1.27;
                margin-bottom: 65px;
            }
        }

        .trending-funds {
            max-width: 244px;
            margin: auto;
            @include desktop() {
                max-width: 740px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 5px;
            }

            article {
                margin-bottom: 50px;
                @include desktop() {
                    margin-bottom: 0;
                }

                &:last-of-type {
                    margin-bottom: 0;
                    @include desktop() {
                        padding-top: 128px;
                    }
                }

                & > div {
                    &:first-of-type {
                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 100%;
                            z-index: 1;
                            background-image: linear-gradient(171deg, $dark-gray 0%, rgba(70, 70, 70, 0) 40%);
                            mix-blend-mode: multiply;
                        }

                        .square {
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            width: 170px;
                            height: 170px;
                            border: 2px solid white;
                            @include desktop() {
                                width: 180px;
                                height: 180px;
                            }

                            p {
                                font-family: 'OrpheusPro', sans-serif;
                                font-size: 30px;
                                line-height: 1.27;
                                letter-spacing: normal;
                                color: $white;
                                padding: 25px;
                            }
                        }
                    }

                    .funds-topics {
                        border-bottom: $border;
                        @include desktop() {
                            max-width: 280px;
                            margin: auto;
                        }

                        &:last-child {
                            border: unset;
                        }

                        .topics-dropdown {
                            display: grid;
                            grid-template-rows: 0fr;
                            transition: .5s ease;

                            & > div {
                                overflow: hidden;
                            }
                        }

                        &.open {
                            .topics-dropdown {
                                grid-template-rows: 1fr;
                                padding-bottom: 20px;
                            }

                            h3 > span {
                                transform: rotate(180deg);
                            }
                        }

                        h3 {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 16px 0;
                            cursor: pointer;
                            font-family: 'MaisonNeue-Extended-Book', sans-serif;
                            font-size: 12px;
                            line-height: normal;
                            letter-spacing: 1.2px;
                            color: $gray;
                            text-transform: uppercase;
                            @include desktop() {
                                padding: 25px 0;
                                font-size: 14px;
                                letter-spacing: 1.4px;
                            }

                            &:hover {
                                color: $honey;
                            }

                            &:last-child {
                                border-bottom: unset;
                            }

                            & > span {
                                transition: .4s ease;
                            }

                            img {
                                height: 12px;
                                width: 12px;
                                -o-object-fit: contain;
                                object-fit: contain;
                                transition: transform .2s ease;
                            }
                        }

                        .topics-dropdown {
                            p {
                                color: $dark-gray;
                            }

                            p,
                            button {
                                font-family: 'MaisonNeue-Book', sans-serif;
                                font-size: 14px;
                                line-height: 1.57;
                                letter-spacing: normal;
                                @include desktop() {
                                    font-size: 16px;
                                    line-height: 1.56;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .hp-fifth {
        .container {
            & > div {
                background-color: $cream;
                @include desktop() {
                    padding: 0 50px 0 67px;
                }
            }

            .btn-link {
                &:hover {
                    color: $honey;
                    border-bottom: 1px solid $honey;
                }
            }
        }
    }
}
