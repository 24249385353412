.interest__form {
    color: $gray;

    &-container {
        padding: 40px 25px 85px;

        @include desktop() {
            max-width: 673px;
            margin: 0 auto;
            padding: 93px 0;
        }
    }

    &-head {
        margin-bottom: 35px;

        @include desktop() {
            margin-bottom: 47px;
        }

    }

    &-body {
        & > form .cog-form__container {
            color: $dark-gray;

            * {
                color: inherit !important;

                &:focus,
                &:focus-within {
                    box-shadow: unset !important;
                }
            }

            .cog-header {
                h1 {
                    font-family: $font-family-secondary !important;
                    font-weight: 400 !important;
                    font-size: 36px !important;
                    line-height: 42px !important;
                    text-align: center !important;
                    margin-bottom: 59px !important;
                    color: $dark-gray !important;

                    @include desktop() {
                        font-size: 54px !important;
                        line-height: 61px !important;
                        margin-bottom: 88px !important;
                    }
                }

                &__description {
                    font-family: $font-family-primary !important;
                    font-size: 16px !important;
                    letter-spacing: 0.3px !important;
                    line-height: 25px !important;

                    @include desktop() {
                        line-height: 23px !important;
                    }
                }
            }

            .cog-body {
                .cog-field--60,
                .cog-field--61,
                .cog-field--62 {
                    .cog-section__inner h2 {
                        display: none;
                    }

                    .cog-content > p {
                        font-family: $font-family-primary !important;
                        font-size: 16px !important;
                        letter-spacing: 0.3px !important;
                        margin-bottom: 19px !important;

                        @include desktop(max) {
                            font-size: 14px !important;
                            margin-bottom: 21px !important;
                        }
                    }
                }

                .cog-field--62 {
                    margin: 0 !important;

                    @include desktop(max) {
                        margin-bottom: 19px !important;
                    }

                    .cog-section__inner {
                        padding: 0 !important;

                        .cog-field .cog-label {
                            display: none;
                        }

                        .cog-row:not(:last-child) {
                            @include desktop(max) {
                                margin-bottom: 29px !important;
                            }
                        }
                    }
                }

                .cog-field--60 {
                    @include desktop(max) {
                        margin-bottom: 21px !important;
                    }

                    @include desktop() {
                        margin-top: 22px !important;
                    }

                    .cog-content > p {
                        margin-bottom: 0 !important;
                    }

                    .cog-section__inner {
                        display: grid !important;
                        grid-template-columns: repeat(2, 1fr);
                        padding-top: 48px !important;

                        @include desktop(max) {
                            grid-gap: 1px 49px !important;
                        }

                        @include desktop() {
                            grid-column-gap: 100px !important;
                            padding-top: 47px !important;
                            grid-template-columns: repeat(3, 1fr);
                        }

                        .cog-row:first-of-type {
                            position: absolute;
                            top: 7px;
                            
                            @include desktop() {
                                top: 4px;
                            }
                        }
                    }

                    .cog-row {
                        margin-bottom: 10px !important;

                        @include desktop() {
                            column-gap: 104px !important;
                        }

                        @include desktop(max) {
                            margin-bottom: 11px !important;
                            grid-gap: 12px 47px !important;
                        }
                    }

                    .cog-field {
                        margin-top: 0 !important;
                        margin-bottom: 0 !important;

                        @include desktop(max) {
                            width: 100% !important;
                        }
                    }

                    .cog-section__inner {
                        padding-bottom: 0 !important;
                    }
                }

                .cog-field--61 {
                    h2.cog-section__heading + .cog-row {
                        margin-bottom: 0 !important;

                        .cog-col {
                            margin-top: 0 !important;
                            margin-bottom: 0 !important;
                        }
                    }

                    .cog-field {
                        margin-top: 0 !important;
                    }

                    .cog-field .cog-content > p {
                        @include desktop(max) {
                            margin-bottom: 17px !important;
                        }
                    }

                    #cog-88-label {
                        font-family: $font-family-primary !important;
                        font-size: 16px !important;
                        font-weight: 400 !important;
                        padding-top: 0 !important;
                    }

                    .cog-row {
                        margin-bottom: 4px !important;

                        .cog-label {
                            font-family: $font-family-primary !important;
                            font-weight: 400 !important;
                            font-size: 14px !important;
                            color: $dark-gray !important;

                            @include desktop() {
                                font-size: 16px !important;
                            }
                        }

                        @include desktop(max) {
                            &:not(:first-of-type) {
                                display: grid !important;
                                grid-template-columns: repeat(2, 1fr);

                                .cog-field {
                                    width: 100% !important;
                                }
                            }
                        }
                    }

                    .cog-text--multiplelines {
                        display: flex !important;
                        align-items: flex-end !important;

                        @include desktop(max) {
                            position: absolute !important;
                            bottom: -64px !important;
                        }

                        .cog-input {
                            margin-left: 7px !important;
                            width: 100% !important;
                        }
                    }
                }

                .cog-confirmation-message .cog-confirmation__message {
                    font-family: $font-family-primary !important;
                    font-size: 16px !important;
                    text-align: center !important;
                    color: $dark-gray !important;
                }

                .cog-row {
                    .cog-field.is-error {
                        background-color: transparent !important;

                        &::after {
                            outline: unset !important;
                            background-color: transparent !important;
                        }

                        .cog-error-message {
                            background-color: #ffb18f!important;
                            width: 100%;
                            padding: 5px;
                            margin: 5px auto 1px;
                            font-family: "MaisonNeue-Book", sans-serif !important;
                            font-size: 16px !important;
                            line-height: 1.56;
                            letter-spacing: normal;
                            text-align: center;
                            color: #464646 !important;

                            @include desktop(max) {
                                font-size: 14px !important;
                            }
                        }
                    }
                }
            }

            .cog-input {
                padding: 0 !important;
                margin: 0 !important;

                .el-input {
                    position: relative !important;

                    input {
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        width: 100% !important;
                        border: unset !important;
                        border-bottom: 1px solid #464646 !important;
                        font-family: 'MaisonNeue-Book', sans-serif !important;
                        font-size: 16px !important;
                        line-height: 1.57 !important;
                        letter-spacing: normal !important;
                        background-color: transparent !important;

                        @include desktop(max) {
                            padding-top: 0 !important;
                            padding-bottom: 6px !important;
                        }

                        &:focus {
                            box-shadow: unset !important;
                        }
                    }
                }

                .el-checkbox {
                    margin-bottom: 0 !important;

                    &__inner {
                        border: 1px solid $dark-gray !important;
                        width: 18px !important;
                        height: 18px !important;
                        box-shadow: unset !important;
                        transform: unset !important;
                        margin: 0 !important;

                        &::after {
                            display: none !important;
                        }
                    }

                    &__input {
                        transform: unset !important;
                    }

                    &__label {
                        color: $dark-gray !important;
                        padding-left: 28px !important;
                        letter-spacing: 0.6px !important;

                        @include desktop() {
                            font-size: 16px !important;
                        }
                    }

                    &.is-checked .el-checkbox__inner {
                        background-color: $dark-gray !important;
                        background-image: url('../images/Vector-checkmark.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }

                .el-textarea textarea {
                    overflow: hidden !important;
                    -webkit-appearance: none !important;
                    appearance: none !important;
                    font-family: $font-family-primary !important;
                    font-size: 16px !important;
                    padding-top: 0 !important;
                    padding-left: 2px !important;
                    padding-bottom: 0 !important;
                    height: 19px !important;
                    min-height: unset !important;
                    resize: none !important;
                    border: none !important;
                    border-bottom: 1px solid $black !important;
                }
            }

            .cog-page__navigation button {
                width: 100% !important;
                font-size: 12px !important;
                letter-spacing: 1.2px !important;
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;
                height: 50px !important;
                background-color: $white !important;
                border-radius: 10px !important;
                border: solid 1px $black !important;
                font-family: 'MaisonNeue-Extended-Book', sans-serif !important;
                line-height: normal !important;
                color: $dark-gray !important;
                text-transform: uppercase !important;
                cursor: pointer !important;

                @include desktop(max) {
                    margin-top: 70px !important;
                }

                @include desktop() {
                    font-size: 14px !important;
                    letter-spacing: 1.4px !important;
                }

                &:not(:disabled):hover:not(.btn-loading),
                &.hover {
                    background-color: $black !important;
                    color: $white !important;
                }
            }

            .cog-abuse.cog-wrapper,
            .cog-branding.cog-branding--minimal {
                display: none !important;
            }
        }

        & > .inp-primary input {
            margin-bottom: 46px;

            @include desktop() {
                margin-bottom: 38px;
            }
        }

        & > button {
            width: 100%;

            @include desktop(max) {
                margin-top: 117px;
            }
        }
    }

    &-options {
        color: $dark-gray;
        margin: 7px 0 61px;

        @include desktop() {
            margin: 4px 0 61px;
        }

        .checkbox-wrapper {
            width: unset;
            height: unset;

            label {
                @include desktop(max) {
                    font-size: 14px;
                    line-height: 22px;
                    display: grid;
                    grid-template-columns: 18px 1fr;
                    grid-gap: 10px;
                    align-items: start;
                }

                &::before {
                    margin-right: 10px;
                }
            }
        }

        & > div {
            &:not(:last-child) {
                margin-bottom: 50px;

                @include desktop() {
                    margin-bottom: 42px;
                }
            }

            & > p {
                letter-spacing: 0.3px;
                margin-bottom: 19px;

                @include desktop(max) {
                    font-size: 14px;
                    margin-bottom: 21px;
                }
            }

            .options {
                display: grid;

                @include desktop(max) {
                    position: relative;
                    grid-gap: 12px 51px;
                }

                @media (max-width: 767.8px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include between(sm, mdMax) {
                    grid-template-columns: repeat(3, 1fr);
                }

                &__other {
                    display: flex;

                    @include desktop(max) {
                        position: absolute;
                        left: 0;
                        bottom: -67px;
                        width: 100%;
                    }

                    .inp-primary {
                        left: 6px;
                        top: -5px;
                    }

                    input {
                        margin-bottom: 0;
                        line-height: unset;
                        color: currentColor;
                    }
                }
            }

            @include desktop() {
                &:first-child .options {
                    grid-gap: 14px 120px;
                    grid-template-columns: repeat(3, 1fr);
                }

                &:last-child .options {
                    grid-row-gap: 14px;
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }
}