.fake-zendesk-button {
    position: fixed;
    right: 20px;
    bottom: 13px;
    background-color: #000000!important;
    color: #FFFFFF;
    fill: #FFFFFF;
    font-size: 1.07143rem;
    font-weight: 700;
    border: none;
    cursor: pointer;
    padding: 13px 22px;
    border-radius: 999rem;
    display: flex;
    align-items: center;

    .fake-zendesk-button-icon {
        padding-right: 8px;
        height: 19px;
    }
}
