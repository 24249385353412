.not-found {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $cream;
    padding: 25px;
    flex-direction: column;
    margin-top: -60px;
    @include desktop() {
        margin-top: -80px;
    }
    h1 {
        font-family: 'OrpheusPro', sans-serif;
        font-size: 36px;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: $dark-gray;
        margin-bottom: 50px;
        @include desktop() {
            font-size: 54px;
            line-height: 1.13;
            margin-bottom: 30px;
        }
    }
}
